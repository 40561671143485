import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BackOfficeDrawer from 'components/drawers/BackOfficeDrawer';
import BackOfficeDeviceMapDeviceDetails from 'components/features/backOfficeDeviceMap/BackOfficeDeviceMapDeviceDetails';
import { appendValuesToQueryString } from 'redux/modules/routerUtils/actions';
import { selectRouterQueryStringSelectedDevice } from 'redux/modules/routerUtils/selectors';

function BackOfficeDeviceMapDrawer(): JSX.Element {
  const dispatch = useDispatch();

  const deviceId = useSelector(selectRouterQueryStringSelectedDevice);
  const visible = !!deviceId;

  const handleClose = useCallback(() => {
    dispatch(
      appendValuesToQueryString({
        selectedDevice: undefined
      })
    );
  }, [dispatch]);

  if (!deviceId) return <></>;

  return (
    <BackOfficeDrawer
      visible={visible}
      backOfficeSection="Device Information"
      actions={[]}
      handleClose={handleClose}
      noFooter={true}
    >
      <BackOfficeDeviceMapDeviceDetails deviceId={deviceId} />
    </BackOfficeDrawer>
  );
}

export default memo(BackOfficeDeviceMapDrawer);
