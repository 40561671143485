import {
  Alert,
  Checkbox,
  Form,
  Input,
  Row,
  Space,
  Tag,
  Tooltip,
  Typography
} from 'antd';
import classNames from 'classnames';
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AnchorTel from 'components/atoms/AnchorTel';
import DefaultButton from 'components/atoms/DefaultButton';
import DefaultFormItem from 'components/composites/DefaultFormItem';
import FieldValue from 'components/forms/FieldValue';
import FormItemCol from 'components/forms/FormItemCol';
import FormItemInputPhoneNumber from 'components/forms/FormItemInputPhoneNumber';
import WarningModal from 'components/modals/WarningModal';
import User from 'models/user';
import { useEnterpriseName } from 'redux/modules/enterprise/hooks';
import clearErrors from 'redux/modules/error/actions';
import { makeSelectFirstErrorMessage } from 'redux/modules/error/selectors';
import { makeSelectIsLoading } from 'redux/modules/loading/selectors';
import MyUserActionType from 'redux/modules/myUser/constants';
import { useIsSuperAdmin } from 'redux/modules/myUser/hooks';
import { deleteUser } from 'redux/modules/users/actions';
import UserActionType from 'redux/modules/users/constants';
import {
  useIsAnyPrimaryContact,
  useIsEnterpriseOwner
} from 'redux/modules/users/hooks';

import FormItemEmail from '../FormItemEmail';
import FormItemRole from '../FormItemRole';

import './styles.less';

const { Paragraph, Text } = Typography;

const actions = [
  UserActionType.ADD_USER_REQUEST,
  UserActionType.EDIT_USER_REQUEST,
  MyUserActionType.EDIT_MY_USER_REQUEST
];

const selectErrorMessage = makeSelectFirstErrorMessage(actions);
const selectIsLoading = makeSelectIsLoading(actions);

interface Props {
  namePrefix?: string;
  initialValues?: Partial<User>;
  isAdmin?: boolean;
  isCurrentUser?: boolean;
  isNewUser?: boolean;
  isNewEnterprise?: boolean;
  isFullSpanOnMobileHalfOnDesktop?: boolean;
}

function UserFormItems({
  initialValues,
  isAdmin,
  isCurrentUser,
  isNewUser,
  isNewEnterprise,
  isFullSpanOnMobileHalfOnDesktop
}: Props): JSX.Element | null {
  const dispatch = useDispatch();
  const errorMessage = useSelector(selectErrorMessage);
  const isLoading = useSelector(selectIsLoading);
  const enterpriseName = useEnterpriseName();
  const isSuperAdmin = useIsSuperAdmin();
  const userId = initialValues?.id;
  const isEnterpriseOwner = useIsEnterpriseOwner(userId);
  const isAnyPrimaryContact = useIsAnyPrimaryContact(userId);

  const [isRemoveUserModalVisible, setIsRemoveUserModalVisible] =
    useState(false);
  const hasRemoveUserPermission = isAdmin || isSuperAdmin;

  const showRemoveUserButton =
    !isCurrentUser && !isNewUser && hasRemoveUserPermission && initialValues;

  useEffect(() => {
    dispatch(clearErrors(actions));
  }, [dispatch]);

  // `removeUserErrorMsg` is also used as a flag - Meaning can we remove this user?
  const removeUserErrorMsg = (() => {
    if (isEnterpriseOwner) {
      return 'This user cannot be removed as they are the enterprise owner.';
    }
    if (isAnyPrimaryContact) {
      return 'This user is a site primary contact. You must update the site contact details to remove this user.';
    }
    return '';
  })();
  /**
   * readOnly is permission based.
   * We only have edit view for Profile Settings page.
   */
  const readOnly = !isAdmin && !isCurrentUser && !isNewUser;
  /**
   * Who can edit roles?
   *
   * |               |admin|non-admin|
   * |---------------|-----|---------|
   * |can edit self  |  ❌  |   ❌    |
   * |can edit others|  ✅  |   ❌    |
   *
   * This matrix (better to view in source code) applies no matter in Team
   * Member editing page or Profile Settings.
   */
  const allowEditRole = isAdmin && !isCurrentUser;

  return (
    <>
      <Row gutter={24}>
        <FormItemCol
          isFullSpanOnMobileHalfOnDesktop={isFullSpanOnMobileHalfOnDesktop}
        >
          <DefaultFormItem
            name="firstName"
            label="First Name"
            rules={[
              {
                required: true,
                message: 'Please enter a first name'
              },
              {
                pattern: /^[A-Za-z ,.'-]+$/,
                message: 'First Name should not contain numbers'
              }
            ]}
            readOnly={readOnly}
          >
            <Input placeholder="First Name" />
          </DefaultFormItem>
        </FormItemCol>

        <FormItemCol
          isFullSpanOnMobileHalfOnDesktop={isFullSpanOnMobileHalfOnDesktop}
        >
          <DefaultFormItem
            name="lastName"
            label="Last Name"
            rules={[
              {
                required: true,
                message: 'Please enter a last name'
              },
              {
                pattern: /^[A-Za-z ,.'-]+$/,
                message: 'Last Name should not contain numbers'
              }
            ]}
            readOnly={readOnly}
          >
            <Input placeholder="Last Name" />
          </DefaultFormItem>
        </FormItemCol>

        {!isNewUser && (
          <FormItemCol
            isFullSpanOnMobileHalfOnDesktop={isFullSpanOnMobileHalfOnDesktop}
          >
            <DefaultFormItem
              name="username"
              label="Username"
              rules={[
                {
                  required: true,
                  message: 'Please enter a username'
                },
                {
                  pattern: new RegExp(
                    '^[a-zA-Z0-9](_(?!(\\.|_))|\\.(?!(_|\\.))|[a-zA-Z0-9#]){4,28}[a-zA-Z0-9]$'
                  ),
                  message: 'Please enter a valid username'
                }
              ]}
              readOnly={readOnly}
            >
              <Input placeholder="Username" />
            </DefaultFormItem>
          </FormItemCol>
        )}
        {!isNewUser && (
          <FormItemCol
            isFullSpanOnMobileHalfOnDesktop={isFullSpanOnMobileHalfOnDesktop}
          >
            <FormItemEmail readOnly={readOnly} />
          </FormItemCol>
        )}
        {/* Mobile Number */}
        <FormItemCol
          isFullSpanOnMobileHalfOnDesktop={isFullSpanOnMobileHalfOnDesktop}
        >
          {readOnly ? (
            <Form.Item
              label={
                <Typography.Text strong>
                  Mobile Number (Optional)
                </Typography.Text>
              }
            >
              {initialValues?.mobileNumberFormatted ? (
                <AnchorTel phoneNumber={initialValues?.mobileNumberFormatted} />
              ) : (
                <Typography.Text type="secondary">
                  {initialValues?.fullName} hasn&apos;t provided a phone number
                  yet.
                </Typography.Text>
              )}
            </Form.Item>
          ) : (
            <FieldValue name="enableSmsNotifications">
              {(smsNotificationsEnabled) => (
                <FormItemInputPhoneNumber
                  name="mobileNumber"
                  label="Mobile Number"
                  rules={[
                    {
                      required: !!smsNotificationsEnabled,
                      message: 'Please enter a mobile number'
                    }
                  ]}
                  prefixName="mobileNumberCountryCode"
                  hasInitialValues={!!initialValues?.mobileNumber}
                />
              )}
            </FieldValue>
          )}
        </FormItemCol>
        <FormItemCol
          isFullSpanOnMobileHalfOnDesktop={isFullSpanOnMobileHalfOnDesktop}
        >
          {readOnly ? (
            <Form.Item
              label={<Typography.Text strong>Landline Number</Typography.Text>}
            >
              {initialValues?.landlineNumberFormatted ? (
                <AnchorTel
                  phoneNumber={initialValues?.landlineNumberFormatted}
                />
              ) : (
                <Typography.Text type="secondary">
                  {initialValues?.fullName} hasn&apos;t provided a phone number
                  yet.
                </Typography.Text>
              )}
            </Form.Item>
          ) : (
            <FormItemInputPhoneNumber
              name="landlineNumber"
              label="Landline Number"
              rules={[
                {
                  required: false,
                  message: 'Please enter a landline number'
                }
              ]}
              prefixName="landlineNumberCountryCode"
              hasInitialValues={!!initialValues?.landlineNumber}
            />
          )}
        </FormItemCol>
        {!isNewEnterprise && !isNewUser && (
          <FormItemCol
            isFullSpanOnMobileHalfOnDesktop={isFullSpanOnMobileHalfOnDesktop}
          >
            {allowEditRole ? (
              <FormItemRole />
            ) : (
              <Form.Item
                required
                label={<Typography.Text strong>Role</Typography.Text>}
              >
                <Tag>{initialValues?.role?.toUpperCase()}</Tag>
              </Form.Item>
            )}
          </FormItemCol>
        )}
        {!isNewUser && !initialValues && (
          <FormItemCol
            isFullSpanOnMobileHalfOnDesktop={isFullSpanOnMobileHalfOnDesktop}
          >
            <DefaultFormItem
              name="password"
              label={<Typography.Text strong>Password</Typography.Text>}
              rules={[
                {
                  required: true,
                  message: 'Please enter a password'
                },
                {
                  pattern: /^(.+){6,128}$/,
                  message: 'At least 6 characters in length'
                }
              ]}
            >
              <Input.Password placeholder="Password" />
            </DefaultFormItem>
          </FormItemCol>
        )}
      </Row>
      <Row>
        {!readOnly && (
          <FormItemCol
            isFullSpanOnMobileHalfOnDesktop={isFullSpanOnMobileHalfOnDesktop}
          >
            <Space direction="vertical">
              <Typography.Text strong>Notifications Preference</Typography.Text>
              <Typography.Text>Send alert notifications via</Typography.Text>
              <DefaultFormItem
                name="enableEmailNotifications"
                valuePropName="checked"
                noStyle
              >
                <Checkbox checked>Email</Checkbox>
              </DefaultFormItem>
              <DefaultFormItem
                name="enableSmsNotifications"
                valuePropName="checked"
              >
                <Checkbox checked>SMS</Checkbox>
              </DefaultFormItem>
            </Space>
          </FormItemCol>
        )}
      </Row>
      {errorMessage && (
        <DefaultFormItem>
          <Alert message={errorMessage} type="error" />
        </DefaultFormItem>
      )}
      <div
        className={classNames(
          'UserFormItems-footer',
          { 'UserFormItems-footer-one-button': !showRemoveUserButton },
          { 'UserFormItems-footer-two-buttons': showRemoveUserButton }
        )}
      >
        {showRemoveUserButton && (
          <Tooltip title={removeUserErrorMsg}>
            <DefaultButton
              danger
              loading={isLoading}
              disabled={!!removeUserErrorMsg}
              onClick={() => {
                setIsRemoveUserModalVisible(true);
              }}
            >
              REMOVE
            </DefaultButton>
          </Tooltip>
        )}
        {!readOnly && !isNewUser && (
          <DefaultButton
            type="primary"
            loading={isLoading}
            htmlType="submit"
          >
            SAVE
          </DefaultButton>
        )}
      </div>
      <WarningModal
        title={`Remove ${initialValues?.firstName} ${initialValues?.lastName}?`}
        visible={isRemoveUserModalVisible}
        onOk={() => {
          dispatch(deleteUser(userId as number));
          setIsRemoveUserModalVisible(false);
        }}
        onCancel={() => {
          setIsRemoveUserModalVisible(false);
        }}
        okText="Remove"
        cancelText="Cancel"
      >
        <Paragraph>
          They will no longer be able to access{' '}
          <Text strong>{enterpriseName}.</Text>
        </Paragraph>
      </WarningModal>
    </>
  );
}

export default memo(UserFormItems);
