import { Col, Typography } from 'antd';
import GoogleMapReact from 'google-map-react';
import React from 'react';
import { useMeasure } from 'react-use';

import { LatLong } from 'models/asset';
import { AssetTypeId } from 'models/assetType';
import Country from 'models/country';
import { latLngFitBounds } from 'utils/GeoMap/lat-long-fit-bounds';

import SimpleMapMarker from './SimpleMapMarker';

const { REACT_APP_GOOGLE_MAP_API_KEY } = process.env;

interface Props {
  location?: LatLong;
  assetTypeId?: AssetTypeId;
  country: Country;
}

/**
 * A simple map to be used in details drawer, where only one MapMarker marks the location of the monitor or device or
 * sample point that we are looking at
 * */
function DrawerSimpleMap({
  location,
  assetTypeId,
  country
}: Props): JSX.Element {
  const [ref, { width, height }] = useMeasure<HTMLDivElement>();

  const { center, zoom } = latLngFitBounds(
    location ? [location] : [],
    width,
    height,
    country
  );

  return (
    <Col ref={ref} style={{ height: '22rem', width: '100%' }}>
      {location ? (
        <GoogleMapReact
          bootstrapURLKeys={{ key: REACT_APP_GOOGLE_MAP_API_KEY }}
          options={() => ({
            mapTypeId: 'hybrid',
            scaleControl: true
          })}
          center={center}
          zoom={zoom}
        >
          <SimpleMapMarker
            lat={location[0]}
            lng={location[1]}
            assetTypeId={assetTypeId}
          />
        </GoogleMapReact>
      ) : (
        <Typography.Text>
          Monitor location is not available now.
        </Typography.Text>
      )}
    </Col>
  );
}

export default DrawerSimpleMap;
