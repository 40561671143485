import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';

import { loadBackOfficeEnterprises } from 'redux/modules/backOfficeEnterprises/actions';
import { selectBackOfficeEnterprisesRequestParameters } from 'redux/modules/routerUtils/selectors';

function BackOfficeEnterprisesRequest(): null {
  const backOfficeEnterprisesRequestParameters = useSelector(
    selectBackOfficeEnterprisesRequestParameters
  );

  const dispatch = useDispatch();

  useDeepCompareEffect(() => {
    dispatch(loadBackOfficeEnterprises());
  }, [dispatch, backOfficeEnterprisesRequestParameters]);

  return null;
}

export default memo(BackOfficeEnterprisesRequest);
