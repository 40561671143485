import keyBy from 'lodash/keyBy';
import omit from 'lodash/omit';

import {
  emptyFilterDate,
  getDateKey,
  getImageAvailableDates,
  parseCameraStatus
} from 'components/features/camera/helpers';
import { CameraStatus, RawCameraImageStatusCode } from 'models/camera';
import { SamplePointId } from 'models/samplePoint';
import AuthActionTypes from 'redux/modules/auth/constants';
import { ApplicationActions } from 'redux/types';

import ActionTypes from './constants';
import { CamerasState, DateKey } from './types';

export const initialState: CamerasState = {};

function camerasReducer(
  state: CamerasState = initialState,
  action: ApplicationActions
): CamerasState {
  switch (action.type) {
    case ActionTypes.LOAD_CAMERA_IMAGES_SUCCESS: {
      const {
        payload: { samplePointId, images, page, dateKey }
      } = action;

      const samplePointIdState = state[samplePointId];
      const newImages = keyBy(images.images, 'id');

      return {
        ...state,
        [samplePointId]: {
          ...samplePointIdState,
          images: {
            ...samplePointIdState?.images,
            ...newImages
          },
          pagination: {
            ...samplePointIdState?.pagination,
            [dateKey]: {
              count: images.count,
              pages: {
                ...samplePointIdState?.pagination?.[dateKey]?.pages,
                [page]: images.images.map(({ id }) => id)
              }
            }
          }
        }
      };
    }
    case ActionTypes.REVOKE_CAMERA_IMAGES_PAGE: {
      const {
        payload: { samplePointId, dateKey, page }
      } = action;

      const samplePointIdState = state[samplePointId];

      return {
        ...state,
        [samplePointId]: {
          ...samplePointIdState,
          pagination: {
            ...samplePointIdState?.pagination,
            [dateKey]: {
              ...samplePointIdState?.pagination?.[dateKey],
              pages: omit(
                samplePointIdState?.pagination?.[dateKey]?.pages,
                page
              )
            }
          }
        }
      };
    }
    case ActionTypes.LOAD_CAMERA_STATUS_SUCCESS: {
      const {
        payload: { samplePointId, status, statusCode }
      } = action;

      return {
        ...state,
        [samplePointId]: {
          ...state[samplePointId],
          status: {
            status: parseCameraStatus(status),
            statusCode
          }
        }
      };
    }
    case ActionTypes.LOAD_CAMERA_IMAGE_DATES_SUCCESS: {
      const {
        payload: { samplePointId, dates, timezoneCode }
      } = action;

      return {
        ...state,
        [samplePointId]: {
          ...state[samplePointId],
          dates: getImageAvailableDates(dates, timezoneCode)
        }
      };
    }
    case ActionTypes.TAKE_PHOTO_SUCCESS: {
      const {
        payload: {
          samplePointId,
          // actionRequestId,
          // controlPointId
          timezoneCode
        }
      } = action;

      const samplePointIdState = state[samplePointId];
      const photoTakenDate = Date.now();

      return {
        ...state,
        [samplePointId]: {
          ...samplePointIdState,
          status: {
            ...samplePointIdState.status,
            status: CameraStatus.PROCESSING,
            statusCode: RawCameraImageStatusCode.MS_POSTED_TO_GATEWAY
          },
          pagination: {
            ...samplePointIdState?.pagination,
            // Clear pages for:
            // - No date filter (undefined)
            // - Date filter of today
            [emptyFilterDate]:
              {} as CamerasState[SamplePointId]['pagination'][DateKey],
            [getDateKey(photoTakenDate, timezoneCode)]:
              {} as CamerasState[SamplePointId]['pagination'][DateKey]
          },
          dates: {
            ...samplePointIdState?.dates,
            ...getImageAvailableDates([{ createdAt: photoTakenDate }], timezoneCode)
          }
        }
      };
    }
    case ActionTypes.GET_CAMERA_SCHEDULES_SUCCESS: {
      const {
        payload: { samplePointId, responses }
      } = action;

      return {
        ...state,
        [samplePointId]: {
          ...state[samplePointId],
          schedules: keyBy(responses, 'id')
        }
      };
    }
    case ActionTypes.ADD_CAMERA_SCHEDULE_SUCCESS: {
      const {
        payload: { samplePointId, responses }
      } = action;

      return {
        ...state,
        [samplePointId]: {
          ...state[samplePointId],
          schedules: {
            ...state[samplePointId].schedules,
            ...responses.reduce(
              (acc, response) => ({
                ...acc,
                [response.id]: response
              }),
              {}
            )
          }
        }
      };
    }
    case ActionTypes.DELETE_CAMERA_SCHEDULE_SUCCESS: {
      const {
        payload: { samplePointId, scheduleIds }
      } = action;

      return {
        ...state,
        [samplePointId]: {
          ...state[samplePointId],
          schedules: omit(state[samplePointId].schedules, scheduleIds)
        }
      };
    }
    case AuthActionTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export default camerasReducer;
