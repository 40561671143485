import Enterprise, { Industry } from 'models/enterprise';

import { RANCHBOT_COUNTRIES_ABBR } from '../../constants';

const {
  REACT_APP_ENVIRONMENT_SUBDOMAIN,
  REACT_APP_URL_SCHEME,
  REACT_APP_URL_DEFAULT_SUBDOMAIN,
  REACT_APP_ENV
} = process.env;

const DOT_COM = '.com';
const DOT_COM_DOT_AU = '.com.au';

const FARMBOT_DOMAIN_NAME = 'farmbot';
const FARMBOT_TOP_LEVEL_DOMAIN = DOT_COM_DOT_AU;

const MINEBOT_DOMAIN_NAME = 'minebot';
const MINEBOT_TOP_LEVEL_DOMAIN = DOT_COM_DOT_AU;

const RANCHBOT_DOMAIN_NAME = 'ranch-bot';
const RANCHBOT_TOP_LEVEL_DOMAIN = DOT_COM;

export const whiteLabelDomains = [
  FARMBOT_DOMAIN_NAME,
  MINEBOT_DOMAIN_NAME,
  RANCHBOT_DOMAIN_NAME
];

/**
 * Construct the subdomain for an enterprise.
 * By default, it returns app.xxx.
 */
const constructEnterpriseDomain = (enterprise?: Partial<Enterprise>) => {
  let subdomainName = REACT_APP_URL_DEFAULT_SUBDOMAIN;
  const environmentSubdomainName = REACT_APP_ENVIRONMENT_SUBDOMAIN
    ? `.${REACT_APP_ENVIRONMENT_SUBDOMAIN}`
    : '';

  // Use the farmbot domain by default
  let domainName = FARMBOT_DOMAIN_NAME;
  let topLevelDomainName = FARMBOT_TOP_LEVEL_DOMAIN;

  // If an enterprise exists override the defaults
  if (enterprise) {
    const { subdomain, id, billingAddress, industry } = enterprise;
    const country = billingAddress?.country;

    subdomainName = subdomain || `${id}`;

    if (industry === Industry.MINING) {
      domainName = MINEBOT_DOMAIN_NAME;
      topLevelDomainName = MINEBOT_TOP_LEVEL_DOMAIN;
    }

    /**
     * If the enterprise industry is agriculture
     * And the country is in RANCHBOT_COUNTRIES
     * Then use the Ranchbot domain
     */
    if (
      country &&
      industry === Industry.AGRICULTURE &&
      RANCHBOT_COUNTRIES_ABBR.includes(country)
    ) {
      domainName = RANCHBOT_DOMAIN_NAME;
      topLevelDomainName = RANCHBOT_TOP_LEVEL_DOMAIN;
    }
  }
  // Override top level domain when developing locally
  if (REACT_APP_ENV === 'local') {
    topLevelDomainName = '.localhost:3000';
  }

  return `${REACT_APP_URL_SCHEME}${subdomainName}${environmentSubdomainName}.${domainName}${topLevelDomainName}`;
};

export default constructEnterpriseDomain;
