import { ApplicationActions } from 'redux/types';

import ActionTypes from './constants';
import { LayoutState } from './types';

export const initialState: LayoutState = {
  isNavigationVisible: true
};

function layoutReducer(
  state: LayoutState = initialState,
  action: ApplicationActions
): LayoutState {
  switch (action.type) {
    case ActionTypes.LAYOUT_SHOW_NAVIGATION: {
      return {
        ...state,
        isNavigationVisible: true
      };
    }
    case ActionTypes.LAYOUT_HIDE_NAVIGATION: {
      return {
        ...state,
        isNavigationVisible: false
      };
    }
    default:
      return state;
  }
};

export default layoutReducer;