import { DatePicker, Space, Tooltip, Typography } from 'antd';
import moment from 'moment-timezone';
import React, { memo } from 'react';
import { FiInfo } from 'react-icons/fi';
import { useDispatch } from 'react-redux';

import SamplePoint from 'models/samplePoint';
import { editSamplePoint } from 'redux/modules/samplePoints/actions';

import { STANDARD_PHOTO_SIZE_KB } from '../helpers';

interface Props {
  samplePoint?: SamplePoint;
  isBackOffice?: boolean;
}

function CameraDetails({ samplePoint, isBackOffice = false }: Props) {
  const dispatch = useDispatch();

  if (
    !samplePoint ||
    !samplePoint.config ||
    samplePoint.config.maxKb === undefined ||
    samplePoint.config.kbConsumed === undefined ||
    samplePoint.config.resetDate === undefined ||
    samplePoint.site?.timezoneCode === undefined
  ) {
    return null;
  }

  const {
    config: { maxKb, kbConsumed, resetDate },
    site: { timezoneCode: siteTimezoneCode }
  } = samplePoint;

  const updatePlanResetDate = (newDate: moment.Moment | null) =>
    newDate &&
    dispatch(
      editSamplePoint(samplePoint, {
        config: {
          ...samplePoint.config,
          resetDate: newDate.format('YYYY-MM-DD')
        }
      })
    );

  const date = resetDate ? moment.tz(resetDate, siteTimezoneCode) : null;

  return (
    <div>
      Standard photos left:{' '}
      {Math.floor((maxKb - kbConsumed) / STANDARD_PHOTO_SIZE_KB)}/
      {Math.floor(maxKb / STANDARD_PHOTO_SIZE_KB)}{' '}
      <Tooltip
        title={`Based on a Standard Quality photo size of ${STANDARD_PHOTO_SIZE_KB} KB.`}
      >
        <FiInfo size={18} />
      </Tooltip>
      <br />
      Data left: {maxKb - kbConsumed} KB
      <br />
      {isBackOffice ? (
        <Space direction="vertical" size="small" style={{ width: '100%' }}>
          <br />
          <Typography.Text strong>Plan Resets</Typography.Text>
          <DatePicker
            style={{ width: '100%' }}
            allowClear={false}
            value={date}
            onChange={updatePlanResetDate}
          />
        </Space>
      ) : (
        <>Plan resets: {date ? date.format('Do MMM YYYY') : ''}</>
      )}
    </div>
  );
}

export default memo(CameraDetails);
