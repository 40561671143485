import round from 'lodash/round';

const CM_TO_IN = 2.54;
const CM_TO_FT = 30.48;

export interface Unit {
  symbol: 'cm' | 'm' | 'ft' | 'in' | '%';
  prefix?: 'K' | 'M' | 'm';
}

export interface Quantity {
  value: number;
  unit: Unit;
}

export default function convertUnit(
  from: Quantity,
  to: Unit,
  precision = 2
): number {
  const {
    unit: { symbol: fromUnit, prefix: fromPrefix },
    value: fromValue
  } = from;
  const { symbol: toUnit } = to; // prefix: toPrefix
  // TODO: Static type checking for incompatible conversions.

  switch (fromUnit) {
    case 'cm':
      if (toUnit === 'm') {
        return round(fromValue / 100, precision);
      }
      if (toUnit === 'ft') {
        return round(fromValue / CM_TO_FT, precision);
      }
      if (toUnit === 'in') {
        return round(fromValue / CM_TO_IN, precision);
      }
      break;
    case 'in':
      if (toUnit === 'cm') {
        return round(fromValue * CM_TO_IN, precision);
      }
      break;
    case 'ft':
      if (toUnit === 'cm') {
        return round(fromValue * CM_TO_FT, precision);
      }
      break;
    case 'm':
      if (toUnit === 'cm') {
        return round(fromValue * 100, precision);
      }
      if (toUnit === 'in' && fromPrefix === 'm') {
        return round(fromValue / 10 / CM_TO_IN, precision);
      }
      break;
    default:
      return round(fromValue, precision);
  }
  return round(fromValue, precision);
}
