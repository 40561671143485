import { WhiteLabelTitle } from 'models/whiteLabel';
import getWhiteLabelConfig from 'utils/get-white-label-config';

export const RANCHBOT_CUSTOMER_SERVICE_CONTACT_NUMBER_FORMATTED =
  '(325) 208-4811';
export const FARMBOT_CUSTOMER_SERVICE_CONTACT_NUMBER_FORMATTED =
  '(02) 9901 4798';

export default function useGetCustomerServiceContacts() {
  const { title: whiteLabel } = getWhiteLabelConfig();

  switch (whiteLabel) {
    case WhiteLabelTitle.RANCHBOT:
      return {
        phoneNumber: '3252084811',
        phoneNumberText: RANCHBOT_CUSTOMER_SERVICE_CONTACT_NUMBER_FORMATTED,
        email: 'service@ranch-bot.com'
      };
    case WhiteLabelTitle.FARMBOT:
    default:
      return {
        phoneNumber: '0299014798',
        phoneNumberText: FARMBOT_CUSTOMER_SERVICE_CONTACT_NUMBER_FORMATTED,
        email: 'service@farmbot.com.au'
      };
  }
}
