import intersection from 'lodash/intersection';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import { createSelector } from 'reselect';

import { SamplePointId } from 'models/samplePoint';
import Trigger, { TriggerId } from 'models/trigger';
import { ApplicationRootState } from 'redux/types';
import makeTriggerSpecialKey from 'utils/make-trigger-special-key';

import { selectSamplePoints } from '../samplePoints/selectors';

const selectTriggersState = (state: ApplicationRootState) => state.triggers;

const selectTriggersData = createSelector(
  selectTriggersState,
  (triggersState) => triggersState.data
);

const selectPendingIsEnabledChanges = createSelector(
  selectTriggersState,
  (triggersState) => triggersState.pendingIsEnabledChanges
);

const selectTriggers = createSelector(
  [selectTriggersData],
  (triggersData) => triggersData
);

const selectTriggersAsArray = createSelector(selectTriggers, (triggers) =>
  Object.values(triggers)
);

const selectEnabledTriggersAsArray = createSelector(
  selectTriggersAsArray,
  (triggersAsArray) => triggersAsArray.filter((trigger) => trigger.isEnabled)
);

const selectEnabledTriggersKeyedBySpecialKey = createSelector(
  [selectEnabledTriggersAsArray],
  (enabledTriggersAsArray) => {
    const result = keyBy(enabledTriggersAsArray, (trigger) => {
      const { eventLevel, valueType, samplePointId, conditionType } = trigger;
      return makeTriggerSpecialKey(
        samplePointId,
        eventLevel,
        valueType,
        conditionType
      );
    });
    return result;
  }
);

const selectLocaleEnabledTriggersKeyedBySpecialKey = createSelector(
  [
    selectEnabledTriggersKeyedBySpecialKey,
    selectPendingIsEnabledChanges,
    selectSamplePoints
  ],
  (enabledTriggersKeyedBySpecialKey, pendingChanges, samplePoints) => {
    const result = mapValues(
      enabledTriggersKeyedBySpecialKey,
      (trigger: Trigger) => {
        const { value, duration = 60 } = trigger;
        const samplePoint = samplePoints[trigger.samplePointId.toString()];
        if (!samplePoint) {
          return undefined;
        }
        return {
          ...trigger,
          duration,
          // Don't do any value conversion here. We need trigger values to be in
          // SI when used in getTriggerValueForGraph().
          // Check out src/utils/convert-trigger-values/index.ts for more details.
          value: value || 0
        };
      }
    );

    return {
      ...result,
      ...pendingChanges
    } as Record<
      string,
      | Pick<
        Trigger,
        | 'duration'
        | 'message'
        | 'triggerType'
        | 'useForecast'
        | 'value'
        | 'valueType'
      >
      | undefined
    >;
  }
);

const makeSelectTriggersBySamplePointId = (samplePointId?: SamplePointId) =>
  createSelector(selectTriggersAsArray, (triggers) =>
    triggers.filter((t) => t.samplePointId === samplePointId)
  );

const makeSelectEnabledTriggerBySpecialKey = (specialKey: string) =>
  createSelector(
    selectLocaleEnabledTriggersKeyedBySpecialKey,
    (enabledTriggersKeyedBySpecialKey) =>
      specialKey in enabledTriggersKeyedBySpecialKey
        ? enabledTriggersKeyedBySpecialKey[specialKey]
        : undefined
  );

// Try to find a trigger by going through all provided specialKeys.
//
// For example, we need the dam critical high trigger when opening an advanced
// dam trigger drawer. As the value type (unit) of the dam high trigger can
// be RL, level, or volume, we have to figure out which one exists. Once a
// trigger matched the special key, we return it immediately, as one dam sample
// point can only have one dam critical high trigger.
const makeSelectEnabledTriggerBySpecialKeys = (specialKeys: string[]) =>
  createSelector(
    selectLocaleEnabledTriggersKeyedBySpecialKey,
    (enabledTriggersKeyedBySpecialKey) => {
      const matchedSpecialKeys = intersection(
        specialKeys,
        Object.keys(enabledTriggersKeyedBySpecialKey)
      );
      if (matchedSpecialKeys.length > 0) {
        return enabledTriggersKeyedBySpecialKey[matchedSpecialKeys[0]];
      }
      return undefined;
    }
  );

const makeSelectTriggerById = (triggerId: TriggerId) =>
  createSelector(selectTriggers, (triggers) => triggers[triggerId]);

const makeSelectTriggerExists = (triggerId: TriggerId) =>
  createSelector(selectTriggers, (triggers) => !!triggers[triggerId]);

export {
  selectTriggersData,
  selectTriggersState,
  selectPendingIsEnabledChanges,
  selectTriggers,
  selectTriggersAsArray,
  selectEnabledTriggersAsArray,
  selectEnabledTriggersKeyedBySpecialKey,
  selectLocaleEnabledTriggersKeyedBySpecialKey,
  makeSelectTriggersBySamplePointId,
  makeSelectEnabledTriggerBySpecialKey,
  makeSelectEnabledTriggerBySpecialKeys,
  makeSelectTriggerById,
  makeSelectTriggerExists
};
