import { action } from 'typesafe-actions';

import { EnterpriseId } from 'models/enterprise';

import ActionTypes from './constants';

export const downloadDevicesAndSamplePointsCSV = (
  enterpriseId: EnterpriseId,
  enterpriseName: string
) =>
  action(ActionTypes.LOAD_CSV_DEVICES_WITH_SAMPLEPOINTS_LIST_REQUEST, {
    enterpriseId,
    enterpriseName
  });

export const downloadDevicesAndSamplePointsCSVSuccess = () =>
  action(ActionTypes.LOAD_CSV_DEVICES_WITH_SAMPLEPOINTS_LIST_SUCCESS);

export const downloadDevicesAndSamplePointsCSVFailure = () =>
  action(ActionTypes.LOAD_CSV_DEVICES_WITH_SAMPLEPOINTS_LIST_FAILURE);
