import AuthActionTypes from 'redux/modules/auth/constants';
import { ApplicationActions } from 'redux/types';

import ActionTypes from './constants';
import { StatesState } from './types';

export const initialState: StatesState = {};

function authReducer(
  state: StatesState = initialState,
  action: ApplicationActions
): StatesState {
  switch (action.type) {
    case ActionTypes.SET_STATES:
      return action.payload;
    case ActionTypes.SET_STATE: {
      const { payload } = action;
      const { id } = payload;
      return {
        ...state,
        [id]: payload
      };
    }
    case AuthActionTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export default authReducer;
