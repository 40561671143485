/**
 * Primarily made for redux reducers, just to make updating existing values more
 * readable and to avoid too many spreads / object assigns in the switch tree.
 *
 * This also does not follow lodash's merge behaviour wherein the merge
 * coalesces into the truthy value rather than the newer value if the new value
 * is undefined.
 * https://github.com/lodash/lodash/issues/5242
 */
export const upsertSafeChildProperties = (
  obj: object | undefined,
  keys: (string | number)[],
  value: any
) => {
  if (keys.length === 0) return obj;

  if (keys.length === 1) {
    const key = keys[0];
    return {
      ...obj,
      [key]: {
        ...obj?.[key],
        ...value
      }
    };
  }

  const [key, ...restKeys] = keys;
  return {
    ...obj,
    [key]: {
      ...obj?.[key],
      ...upsertSafeChildProperties(obj?.[key], restKeys, value)
    }
  };
};
