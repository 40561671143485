import { Select, Typography, message as antdMessage } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import isEqual from 'lodash/isEqual';
import React, { useCallback, useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { FiNavigation } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import DefaultButton from 'components/atoms/DefaultButton';
import DefaultCard from 'components/atoms/DefaultCard';
import ErrorBoundaryFallback from 'components/atoms/ErrorBoundaryFallback';
import HelmetWrapper from 'components/atoms/HelmetWrapper';
import PageHeaderPusher from 'components/atoms/PageHeaderPusher';
import DefaultForm from 'components/composites/DefaultForm';
import DefaultFormItem from 'components/composites/DefaultFormItem';
import DefaultPageHeader from 'components/composites/DefaultPageHeader';
import FarmbotAgriWebbIntegrationTitle from 'components/features/integrations/FarmbotAgriWebbIntegrationTitle';
import SensorIcon from 'components/icons/SensorIcon';
import Enterprise from 'models/enterprise';
import { UserRole } from 'models/membership';
import { activateAccount } from 'redux/modules/agriWebb/actions';
import AgriWebbActionType from 'redux/modules/agriWebb/constants';
import { makeSelectIsLoading } from 'redux/modules/loading/selectors';
import { loadMyUser } from 'redux/modules/myUser/actions';
import { makeSelectMyUserMembershipsEnterprisesByRoles } from 'redux/modules/myUser/selectors';
import getWhiteLabelConfig from 'utils/get-white-label-config';

import PermissionRequest from '../PermissionRequest';

import './styles.less';

const actions = [AgriWebbActionType.ACTIVATE_ACCOUNT_REQUEST];
const selectIsActivating = makeSelectIsLoading(actions);

const { title: whiteLabel } = getWhiteLabelConfig();

export default function InstallCallbackAgriWebb() {
  const [form] = useForm();
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(document.location.search);
  const authCode = searchParams.get('code') as string;
  const isActivating = useSelector(selectIsActivating);
  const history = useHistory();
  const myUserMembershipsEnterprises = useSelector(
    makeSelectMyUserMembershipsEnterprisesByRoles([
      UserRole.ADMIN,
      UserRole.SUPERVISOR
    ]),
    isEqual
  );

  const enterpriseOptions = useMemo(
    () =>
      myUserMembershipsEnterprises.map(({ name, id }) => ({
        label: name,
        value: id
      })),
    [myUserMembershipsEnterprises]
  );

  const goToSiteMappingPage = useCallback(
    (enterpriseId) => {
      dispatch(loadMyUser()); // To refresh the integration status
      history.push(
        `/connections/agriwebb/site-mapping?enterpriseId=${enterpriseId}`
      );
    },
    [dispatch, history]
  );

  const submit = useCallback(() => {
    form
      .validateFields()
      .then(({ enterpriseId }) => {
        const enterprise: Enterprise | undefined =
          myUserMembershipsEnterprises.find((e) => e.id === enterpriseId);
        if (!enterprise || enterprise.length === 0) {
          throw new Error('No enterprise selected');
        }
        dispatch(
          activateAccount(
            {
              enterpriseId: enterprise.id,
              agriWebbCode: authCode,
              whiteLabel
            },
            () => {
              goToSiteMappingPage(enterpriseId);
            },
            () => antdMessage.error('Failed to activate the enterprise')
          )
        );
      })
      .catch(() => { });
  }, [
    form,
    myUserMembershipsEnterprises,
    dispatch,
    authCode,
    goToSiteMappingPage
  ]);

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <HelmetWrapper section="Connections" />
      <DefaultPageHeader title="Connections" />
      <PageHeaderPusher>
        <>
          {!myUserMembershipsEnterprises.length && <PermissionRequest />}
          {myUserMembershipsEnterprises.length > 0 && (
            <div className="InstallCallback">
              <FarmbotAgriWebbIntegrationTitle>
                You&apos;ll be connecting the following data with AgriWebb.
              </FarmbotAgriWebbIntegrationTitle>
              <DefaultCard className="InstallCallback-card">
                <SensorIcon
                  style={{
                    position: 'relative',
                    width: 24,
                    top: 5,
                    marginRight: 10
                  }}
                />
                <Typography.Text strong>Sensor Data</Typography.Text>
                <Typography.Paragraph>
                  Sensor data includes: recorded sensor data, sensor name and
                  sensor configuration.
                </Typography.Paragraph>
              </DefaultCard>
              <DefaultCard className="InstallCallback-card">
                <FiNavigation
                  style={{
                    position: 'relative',
                    width: 24,
                    top: 2,
                    marginRight: 10
                  }}
                />
                <Typography.Text strong>Location</Typography.Text>
                <Typography.Paragraph>
                  Location includes: Sensor location, Enterprise and Site
                  location and time zones.
                </Typography.Paragraph>
              </DefaultCard>
              <Typography.Paragraph className="InstallCallback-text">
                <Typography.Text strong>Select enterprise</Typography.Text>
              </Typography.Paragraph>
              <Typography.Paragraph className="InstallCallback-text">
                Which enterprise would you like to grant AgriWebb-OAuth access
                to?
              </Typography.Paragraph>
              <DefaultCard className="InstallCallback-card">
                <DefaultForm
                  form={form}
                  layout="vertical"
                  name="selectEnterprise"
                  autoComplete="off"
                  initialValues={{
                    enterpriseId: enterpriseOptions?.[0]?.value
                  }}
                >
                  <DefaultFormItem name="enterpriseId" label="" required>
                    <Select
                      filterOption={false}
                      options={enterpriseOptions}
                      placeholder="Enterprise"
                    />
                  </DefaultFormItem>
                  <div className="InstallCallback-footer">
                    <DefaultButton
                      className="InstallCallback-footer-button"
                      onClick={submit}
                      loading={isActivating}
                    >
                      Allow
                    </DefaultButton>
                  </div>
                </DefaultForm>
              </DefaultCard>
            </div>
          )}
        </>
      </PageHeaderPusher>
    </ErrorBoundary>
  );
}
