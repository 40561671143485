import { Dropdown, Input, Menu } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import DefaultFormItem, {
  DefaultFormItemProps
} from 'components/composites/DefaultFormItem';
import FieldValue from 'components/forms/FieldValue';
import Enterprise from 'models/enterprise';
import { SearchTypes } from 'redux/modules/search/types';
import useSearch from 'utils/use-search';

import './styles.less';

interface Props extends DefaultFormItemProps {
  searchType: SearchTypes;
  labelProperty: string;
  onItemSelect: (value: Enterprise) => void;
  placeholder: string;
  initialValue?: string;
  disabled?: boolean;
}

/**
 * The search functionality of the Antd component <Select /> is buggy and
 * doesn't work as expected. This component recreates the intended functionality
 * of Select.
 */
function DefaultBackendSearchSelect({
  searchType,
  labelProperty,
  onItemSelect,
  placeholder,
  initialValue = '',
  disabled,
  // FormItem's name
  name,
  ...rest
}: Props) {
  const [searchTerm, setSearchTerm] = useState<string>();

  const [label, setLabel] = useState(initialValue);

  const [innerValue, setInnerValue] = useState<unknown>();

  const [cleared, setCleared] = useState(false);

  const [focused, setFocused] = useState(false);

  const searchResult = useSearch(searchType, searchTerm);

  useEffect(() => {
    if (!label && !cleared) {
      setLabel(initialValue);
    }
  }, [label, initialValue, cleared]);

  const onClickHandler = useCallback(
    ({ label: l, value }: { label: string; value: number | string }) =>
      () => {
        setLabel(l);
        setSearchTerm(undefined);
        const selected = searchResult?.find((s) => s.id === +value);
        if (selected) {
          onItemSelect(selected);
        }
      },
    [onItemSelect, searchResult]
  );

  const menu = useMemo(
    () => (
      <Menu className="DefaultBackendSearchSelect-dropdown-menu">
        {(searchResult ?? []).map(
          (result: { id: number;[key: string]: unknown }) => (
            <Menu.Item
              key={result.id}
              onClick={onClickHandler({
                label: result[labelProperty] as string,
                value: result.id
              })}
            >
              {(result as { [key: string]: string })[labelProperty]}
            </Menu.Item>
          )
        )}
      </Menu>
    ),
    [searchResult, onClickHandler, labelProperty]
  );

  return (
    <>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <DefaultFormItem name={name} {...rest}>
        <Dropdown overlay={menu} trigger={['click']} disabled={disabled}>
          <Input
            placeholder={placeholder}
            value={focused ? searchTerm : label}
            onFocus={() => {
              setSearchTerm(label);
              setFocused(true);
            }}
            onBlur={() => setFocused(false)}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Dropdown>
      </DefaultFormItem>
      <FieldValue name={name}>
        {(value) => {
          if (value !== innerValue) {
            if (!!innerValue && !value) {
              setCleared(true);
              setLabel('');
            }
            setInnerValue(value);
          }
          return null;
        }}
      </FieldValue>
    </>
  );
}

export default DefaultBackendSearchSelect;
