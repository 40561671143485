import { Typography } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';

import { useBackOfficeDeviceCameraSamplePoint } from 'redux/modules/backOfficeDevice/hooks';
import { selectRouterQueryStringSelectedDevice } from 'redux/modules/routerUtils/selectors';

import CameraDataUsage from '../CameraDataUsage';
import CameraDetails from '../CameraDetails';

export default function BackOfficeDeviceCameraForm() {
  const deviceId = useSelector(selectRouterQueryStringSelectedDevice);
  const cameraSamplePoint = useBackOfficeDeviceCameraSamplePoint(deviceId);

  return (
    <>
      {cameraSamplePoint && <Typography.Text strong style={{ fontSize: 16 }}>Camera</Typography.Text>}
      <CameraDetails isBackOffice samplePoint={cameraSamplePoint} />
      <br />
      <CameraDataUsage isBackOffice samplePoint={cameraSamplePoint} />
      <br />
      <br />
    </>
  );
}
