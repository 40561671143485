import { message as antdMessage } from 'antd';
import axios from 'axios';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { DeviceStatus } from 'models/device.enum';
import SamplePoint from 'models/samplePoint';
import { patchRequest } from 'utils/redux-saga-requests';

import { editDevice, editDeviceFailure, editDeviceSuccess, updateDevice } from './actions';
import ActionTypes from './constants';
import { setSamplePoint } from '../samplePoints/actions';
import { makeSelectSamplePointsByDevice } from '../samplePoints/selectors';

// ==============================
// SAGAS
// ==============================
export function* requestEditDevice(action: ReturnType<typeof editDevice>) {
  const {
    payload: { deviceId, values }
  } = action;

  let successMessage = 'Device details updated';
  let failureMessage = 'Failed to update device details';

  if (values.status === DeviceStatus.TURNED_OFF) {
    successMessage = 'Device muted';
    failureMessage = 'Failed to mute device alerts';
  }

  try {
    const { data } = yield call(patchRequest, `device/${deviceId}`, {
      id: deviceId,
      ...values
    });

    const deviceSamplePoints: SamplePoint[] = yield select(
      makeSelectSamplePointsByDevice(data.id)
    );
    const samplePointUpdateActions = deviceSamplePoints.map((sp) =>
      put(
        setSamplePoint({
          ...sp,
          siteId: data.siteId
        })
      )
    );

    yield all([
      put(updateDevice(data)),
      ...samplePointUpdateActions,
      put(editDeviceSuccess())
    ]);

    antdMessage.success(successMessage);
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;

    antdMessage.error(failureMessage);

    yield put(editDeviceFailure());
  }
}

// ==============================
// REGISTRATION
// ==============================
export function* watchEditDeviceRequest() {
  yield takeLatest(ActionTypes.EDIT_DEVICE_REQUEST, requestEditDevice);
}

// ==============================
// EXPORT
// ==============================
export default function* devicesSaga() {
  yield all([fork(watchEditDeviceRequest)]);
}
