import { Col, Row, Select } from 'antd';
import { FormInstance } from 'antd/lib/form';
import isEqual from 'lodash/isEqual';
import React, { memo, useEffect, useMemo, useState } from 'react';

import DefaultForm from 'components/composites/DefaultForm';
import DefaultFormItem from 'components/composites/DefaultFormItem';
import User from 'models/user';
import { FILTER_NULL } from 'redux/modules/routerUtils/selectors';
import { SearchTypes } from 'redux/modules/search/types';
import useSearch from 'utils/use-search';

const ENTERPRISE_ID = 'memberships.enterpriseId';

interface Props {
  form?: FormInstance;
  initialValues?: Partial<User> & { [ENTERPRISE_ID]?: string[] };
  onFinish?: (values: Record<string, unknown>) => void;
}

function BackOfficeUserFilterForm({
  form,
  initialValues,
  onFinish
}: Props): JSX.Element {
  const [enterpriseSearchTerm, setEnterpriseSearchTerm] = useState('');
  const enterpriseSearchResults = useSearch(
    SearchTypes.UserEnterpriseMembershipSearch,
    enterpriseSearchTerm
  );

  const enterpriseOptions = useMemo(
    () => [
      ...(enterpriseSearchTerm.length
        ? []
        : [{ label: 'No Enterprise', value: FILTER_NULL }]),
      ...(enterpriseSearchResults || []).map(({ name, id }) => ({
        label: name,
        value: id.toString()
      }))
    ],
    [enterpriseSearchTerm, enterpriseSearchResults]
  );

  // Initialise the enterprise option for the enterprise id in URL
  useEffect(() => {
    if (
      initialValues?.[ENTERPRISE_ID]?.length &&
      !enterpriseSearchResults?.length
    ) {
      setEnterpriseSearchTerm((initialValues[ENTERPRISE_ID] as string[])[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DefaultForm
      form={form}
      onFinish={onFinish}
      layout="vertical"
      name="backOfficeUser"
      autoComplete="off"
      initialValues={initialValues}
      requiredMark="optional"
      closeDrawerOnSubmit
    >
      <Row gutter={24}>
        <Col span={24}>
          <DefaultFormItem name="memberships.enterpriseId">
            <Select
              filterOption={false}
              options={enterpriseOptions}
              onFocus={() => setEnterpriseSearchTerm('')}
              onBlur={() => setEnterpriseSearchTerm('')}
              onSearch={setEnterpriseSearchTerm}
              placeholder="Enterprise"
              showSearch
            />
          </DefaultFormItem>
          <DefaultFormItem name="isSuperAdmin">
            <Select
              // Query param parsing logic expects filter value to be an array.
              mode="multiple"
              placeholder="Is Super Admin"
              options={[
                // Type error when using boolean values.
                { label: 'True', value: 1 },
                { label: 'False', value: 0 }
              ]}
            />
          </DefaultFormItem>
          <DefaultFormItem name="enableSmsNotifications">
            <Select
              // Query param parsing logic expects filter value to be an array.
              mode="multiple"
              placeholder="SMS notifications enabled"
              options={[
                // Type error when using boolean values.
                { label: 'True', value: 1 },
                { label: 'False', value: 0 }
              ]}
            />
          </DefaultFormItem>
          <DefaultFormItem name="enableEmailNotifications">
            <Select
              // Query param parsing logic expects filter value to be an array.
              mode="multiple"
              placeholder="Email notifications enabled"
              options={[
                // Type error when using boolean values.
                { label: 'True', value: 1 },
                { label: 'False', value: 0 }
              ]}
            />
          </DefaultFormItem>
        </Col>
      </Row>
    </DefaultForm>
  );
}

export default memo(BackOfficeUserFilterForm, isEqual);
