import { Drawer, Typography } from 'antd';
import { DrawerProps } from 'antd/lib/drawer';
import classNames from 'classnames';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { FiX } from 'react-icons/fi';

import DefaultModal from 'components/atoms/DefaultModal';
import { WhiteLabelTitle } from 'models/whiteLabel';
import getWhiteLabelConfig from 'utils/get-white-label-config';

import './styles.less';

export interface DrawerContextValue {
  setShouldPromptBeforeClose?: React.Dispatch<React.SetStateAction<boolean>>;
  setOnDiscardChanges?: (fn: () => void) => void;
  onClose?: (ignoreShouldPrompt?: boolean) => void;
}

export const DrawerContext = React.createContext<DrawerContextValue>({
  setShouldPromptBeforeClose: undefined,
  setOnDiscardChanges: undefined,
  onClose: undefined
});

const { title: whiteLabelTitle } = getWhiteLabelConfig();

export interface Props
  extends Pick<
    DrawerProps,
    | 'placement'
    | 'visible' // TODO: Remove this prop and use 'open' instead
    | 'open'
    | 'width'
    | 'title'
    | 'mask'
    | 'maskClosable'
    | 'footer'
    | 'closeIcon'
    | 'closable'
    | 'bodyStyle'
    | 'className'
  > {
  onClose: () => void;
  children: React.ReactNode;
  disableAnimation?: boolean;
}

/**
 * A wrapper around the antd Drawer with our custom config applied.
 */
function DefaultDrawer({
  placement,
  onClose,
  visible,
  open,
  width,
  title,
  mask,
  maskClosable,
  footer,
  closeIcon = <FiX size="20" style={{ verticalAlign: 'middle' }} />,
  closable,
  bodyStyle,
  className,
  children,
  disableAnimation
}: Props): JSX.Element {
  const [modalVisible, setModalVisible] = useState(false);

  const [shouldPromptBeforeClose, setShouldPromptBeforeClose] = useState(false);

  const [onDiscardChanges, setOnDiscardChanges] = useState<() => void>();

  const handleOnClose = useCallback(
    (ignoreShouldPrompt?: boolean) =>
      shouldPromptBeforeClose && !ignoreShouldPrompt
        ? setModalVisible(true)
        : onClose?.(),
    [shouldPromptBeforeClose, onClose]
  );

  const handleDiscard = useCallback(() => {
    onClose?.();
    onDiscardChanges?.();
    setShouldPromptBeforeClose(false);
    setModalVisible(false);
  }, [onClose, onDiscardChanges]);

  const handleCancel = useCallback(() => setModalVisible(false), []);

  const contextValue = useMemo(() => ({
    setShouldPromptBeforeClose,
    // 'setState' functions behave differently when passed functions (react
    // assumes the return value of the function passed is the new value of
    // 'state', instead of the function itself). This ensures the function
    // given will be set as the state.
    setOnDiscardChanges: (fn: () => void) => setOnDiscardChanges(() => fn),
    onClose: handleOnClose
  }),
    [handleOnClose]
  );

  return (
    <DrawerContext.Provider value={contextValue}>
      <DefaultModal
        isOpen={modalVisible}
        onConfirm={handleDiscard}
        onDismiss={handleCancel}
        confirmText="Discard"
      >
        <Typography.Text style={{ fontWeight: 500 }}>
          Discard unsaved changes?
        </Typography.Text>
      </DefaultModal>
      <Drawer
        className={classNames('DefaultDrawer', className, {
          'disable-animation': disableAnimation,
          Minebot: whiteLabelTitle === WhiteLabelTitle.MINEBOT
        })}
        placement={placement}
        onClose={() => handleOnClose()}
        open={open || visible}
        width={width}
        title={title}
        mask={mask}
        maskClosable={maskClosable}
        footer={footer}
        closeIcon={closeIcon}
        closable={closable}
        bodyStyle={bodyStyle}
      >
        {children}
      </Drawer>
    </DrawerContext.Provider>
  );
}

export default memo(DefaultDrawer);
