import SamplePoint from 'models/samplePoint';

import Trigger, { TriggerId } from './trigger';

export type EventId = number;

export enum Level {
  INFO = 1,
  WARNING = 2,
  ERROR = 3,
  CRITICAL = 4,
  SOS = 5
}

export type EventSamplePoint = Pick<
  SamplePoint,
  'id' | 'sid' | 'name' | 'siteId' | 'deviceId'
>;
export default interface Event {
  id: EventId;
  level: Level;
  message: string;
  date: number;
  samplePoint: EventSamplePoint;
  triggerValue: number;
  triggerId: TriggerId | null;
  // By the time we send the event, the trigger might have been deleted.
  trigger: Trigger | null;
}
