import { Typography } from 'antd';
import React from 'react';
import { FiExternalLink } from 'react-icons/fi';

import Enterprise from 'models/enterprise';
import constructEnterpriseDomain from 'utils/construct-enterprise-domain';

interface Props {
  enterprise: Enterprise;
  iconTop?: number;
  disabled?: boolean;
}

function EnterpriseLink({
  enterprise,
  disabled = false
}: Props): JSX.Element {
  const href = constructEnterpriseDomain(enterprise);

  return (
    <Typography.Link href={href} target="_blank" disabled={disabled}>
      <FiExternalLink size="22" style={{ verticalAlign: 'middle' }} />
    </Typography.Link>
  );
}

export default EnterpriseLink;
