import GoogleMapReact, { Bounds, Coords } from 'google-map-react';
import React, { memo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { DEVICE_MAP_MIN_ZOOM } from 'constants/device-map';
import BackOfficeDeviceMapPoint from 'models/back-office/backOfficeDeviceMapPoint';
import { MAX_WIDTH_MOBILE } from 'style/constants';
import preventRenderIfFrozen from 'utils/prevent-render-if-frozen';

import BackOfficeDeviceMapClusterMarker from '../BackOfficeDeviceMapClusterMarker/BackOfficeDeviceMapClusterMarker';
import BackOfficeDeviceMapDeviceMarker from '../BackOfficeDeviceMapDeviceMarker';
import YouAreHereMapMarker from '../YouAreHereMapMarker';

const { REACT_APP_GOOGLE_MAP_API_KEY } = process.env;

interface BackOfficeDeviceMapInnerProps {
  mapCenterCoords: Coords;
  userCoords: Coords;
  mapZoom: number;
  mapPoints: BackOfficeDeviceMapPoint[];
  setMapBounds: (bounds: Bounds) => void;
  setMapZoom: (zoom: number) => void;
  setMapCenterCoords: (coords: Coords) => void;
  fetchMapPoints: () => void;
  fetchMapPointsWithDebounce: (bounds: Bounds, zoom: number) => void;
}

function BackOfficeDeviceMapInner({
  mapCenterCoords,
  userCoords,
  mapZoom,
  mapPoints,
  setMapBounds,
  setMapZoom,
  setMapCenterCoords,
  fetchMapPoints,
  fetchMapPointsWithDebounce
}: BackOfficeDeviceMapInnerProps): JSX.Element | null {
  const [shouldFetchForCluster, setShouldFetchForCluster] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: MAX_WIDTH_MOBILE });
  const showZoomControl = !isMobile;
  const showScaleControl = !isMobile;
  const showKeyboardShortCuts = !isMobile;

  const mapOptions: GoogleMapReact.MapOptions = {
    mapTypeId: 'hybrid',
    scaleControl: showScaleControl,
    zoomControl: showZoomControl,
    keyboardShortcuts: showKeyboardShortCuts,
    minZoom: DEVICE_MAP_MIN_ZOOM
  };

  const onMapChange = (value: GoogleMapReact.ChangeEventValue) => {
    const { bounds, zoom, center } = value;
    setMapCenterCoords(center);
    setMapBounds(bounds);
    setMapZoom(zoom);

    if (shouldFetchForCluster) {
      fetchMapPoints();
      setShouldFetchForCluster(false);
    } else {
      fetchMapPointsWithDebounce(bounds, zoom);
    }
  };

  const onClusterClick = (coords: Coords) => {
    setMapCenterCoords(coords);
    setMapZoom(mapZoom + 1);
    setShouldFetchForCluster(true);
  };

  const renderBackOfficeDeviceMapPointMarker = (mapPoint: BackOfficeDeviceMapPoint, index: number) => {
    if ('clusterId' in mapPoint) return (
      <BackOfficeDeviceMapClusterMarker
        key={index}
        lat={mapPoint.lat}
        lng={mapPoint.lng}
        count={mapPoint.count}
        onClick={() => onClusterClick({ lat: mapPoint.lat, lng: mapPoint.lng })}
      />
    );

    return (
      <BackOfficeDeviceMapDeviceMarker
        key={index}
        lat={mapPoint.lat}
        lng={mapPoint.lng}
        deviceId={mapPoint.deviceId}
        name={mapPoint.name}
        serialNumber={mapPoint.serialNumber}
        enterpriseName={mapPoint.enterpriseName}
        ownerName={mapPoint.ownerName}
      />
    );
  };

  return (
    <GoogleMapReact
      onChange={onMapChange}
      options={mapOptions}
      bootstrapURLKeys={{ key: REACT_APP_GOOGLE_MAP_API_KEY }}
      center={mapCenterCoords}
      zoom={mapZoom}
    >
      <YouAreHereMapMarker lat={userCoords.lat} lng={userCoords.lng} />
      {mapPoints.map(renderBackOfficeDeviceMapPointMarker)}
    </GoogleMapReact>
  );
}

export default memo(BackOfficeDeviceMapInner, preventRenderIfFrozen);
