/* eslint-disable no-param-reassign */
import { message as antdMessage } from 'antd';
import axios from 'axios';
import parsedPhoneNumber from 'libphonenumber-js';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import type { SagaIterator } from 'redux-saga';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import Membership from 'models/membership';
import User from 'models/user';
import { setBackOfficeUser } from 'redux/modules/backOfficeUser/actions';
import { selectBackOfficeUsersRequestParameters } from 'redux/modules/routerUtils/selectors';
import parseBackendPhoneNumber from 'utils/phone-number/parse-backend-phone-number';
import {
  deleteRequest,
  getRequest,
  patchRequest
} from 'utils/redux-saga-requests';

import {
  editBackOfficeUser,
  editBackOfficeUserFailure,
  editBackOfficeUserSuccess,
  loadBackOfficeUsers,
  loadBackOfficeUsersFailure,
  loadBackOfficeUsersSuccess,
  setBackOfficeUsers
} from './actions';
import ActionTypes from './constants';
import selectBackOfficeUser from '../backOfficeUser/selectors';
import { appendValuesToQueryString } from '../routerUtils/actions';

function getPhoneNumberValue(
  values: Record<string, unknown>,
  number: string,
  countryCode: string
) {
  return values
    ? {
      [number]: values[number] && values[countryCode]
        ? parsedPhoneNumber(`${values[countryCode]}${values[number]}`)?.number
        : null
    }
    : {};
}

export function* requestBackOfficeUsers(): SagaIterator {
  const params = yield select(selectBackOfficeUsersRequestParameters);
  try {
    const { data } = yield call(getRequest, 'user', { params });
    yield all([
      put(loadBackOfficeUsersSuccess(data)),
      put(
        setBackOfficeUsers({
          total: data.total,
          data: keyBy(
            data.data.map(
              (user: User): User =>
                parseBackendPhoneNumber(
                  user,
                  'mobileNumber',
                  'mobileNumberCountryCode',
                  'mobileNumberFormatted'
                )
            ),
            'id'
          ),
          ids: data.data.map((user: User) => user.id)
        })
      )
    ]);
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    const message =
      error.message === 'Network Error'
        ? error.message
        : get(error, 'response.data.message', 'Sorry, something went wrong.');

    yield put(loadBackOfficeUsersFailure(message, error));
  }
}

export function* watchLoadBackOfficeUsersRequest() {
  yield takeLatest(
    ActionTypes.LOAD_BACK_OFFICE_USERS_REQUEST,
    requestBackOfficeUsers
  );
}

export function* requestEditBackOfficeUser(
  action: ReturnType<typeof editBackOfficeUser>
): SagaIterator {
  const {
    payload: { userId, values }
  } = action;

  try {
    const mobileNumberValue = getPhoneNumberValue(
      values,
      'mobileNumber',
      'mobileNumberCountryCode'
    );
    const landlineNumberValue = getPhoneNumberValue(
      values,
      'landlineNumber',
      'landlineNumberCountryCode'
    );

    // Collect all ids of deleted memberships.
    const oldBackOfficeUser = yield select(selectBackOfficeUser);
    const oldMemberships = (oldBackOfficeUser.memberships ||
      []) as Membership[];
    const newMemberships = (values.memberships || []) as Membership[];

    const oldMembershipIds = oldMemberships.map((m) => m.id).filter((m) => !!m);
    const newMembershipIds = newMemberships.map((m) => m.id).filter((m) => !!m);

    const newMembershipEnterpriseIds = newMemberships.map(
      (m) => m.enterpriseId
    );

    // Get the intersection of old & new memberships.
    // Two memberships are the same if they have the same enterprise ID
    // (we don't allow a user to have two memberships to the same enterprise.)
    const mergedMemberships = [
      ...newMemberships,
      ...(oldMemberships.filter(
        (m) => !newMembershipEnterpriseIds.includes(m.enterpriseId)
      ) || [])
    ];

    // Strip unneeded properties.
    mergedMemberships.forEach((membership: Partial<Membership>) => {
      delete membership.enterprise;
      delete membership.createdAt;
      delete membership.updatedAt;
    });

    if (values.memberships) {
      values.memberships = mergedMemberships;
    }
    const { data } = yield call(patchRequest, `user/${userId}`, {
      ...values,
      isSuperAdmin: values.isSuperAdmin ?? false,
      // Pass phone number in format needed by backend.
      ...mobileNumberValue,
      ...landlineNumberValue
    });
    const deletedMembershipIds = oldMembershipIds.filter(
      (oldId) => !newMembershipIds.includes(oldId)
    );

    // Make DELETE request to user-membership endpoint for each deleted
    // membership.
    yield all(
      deletedMembershipIds.map((id) =>
        call(deleteRequest, `user-membership/${id}`)
      )
    );

    yield put(
      setBackOfficeUser(
        parseBackendPhoneNumber(
          data as User,
          'mobileNumber',
          'mobileNumberCountryCode',
          'mobileNumberFormatted'
        )
      )
    );

    yield all([
      put(loadBackOfficeUsers()),
      put(editBackOfficeUserSuccess(data)),
      put(
        appendValuesToQueryString({
          selectedUser: undefined
        })
      ) // Close drawer
    ]);

    antdMessage.success('User edited');
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );

    antdMessage.error('Failed to edit user');
    yield put(editBackOfficeUserFailure(message, error));
  }
}

export function* watchEditBackOfficeUsersRequest() {
  yield takeLatest(
    ActionTypes.EDIT_BACK_OFFICE_USER_REQUEST,
    requestEditBackOfficeUser
  );
}

export default function* backOfficeUsersSaga() {
  yield all([
    fork(watchLoadBackOfficeUsersRequest),
    fork(watchEditBackOfficeUsersRequest)
  ]);
}
