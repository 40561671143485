import { action } from 'typesafe-actions';

import AssetType, { AssetTypeId } from 'models/assetType';

import ActionTypes from './constants';

export const setAssetTypes = (assetTypes: Record<AssetTypeId, AssetType>) =>
  action(ActionTypes.SET_ASSET_TYPES, assetTypes);

export const setAssetType = (assetType: AssetType) =>
  action(ActionTypes.SET_ASSET_TYPE, assetType);
