import Sample from 'models/sample';
import convertMetricToImperial from 'utils/convert-metric-to-imperial';
import { UnitType } from 'utils/get-unit-by-country';
import { millisecondsToSeconds } from 'utils/unit-conversions';

/**
 * Returns the the data form a flow sample formatted as volume per minute
 */
function getSampleVolumePerMinute(sample: Sample, country = 'AUS') {
  const { date, rwValue: volume, prevDate } = sample;

  const timePeriodInMilliseconds = date - prevDate;
  const timePeriodInSeconds = millisecondsToSeconds(timePeriodInMilliseconds);
  const timePeriodInMinutes = timePeriodInSeconds / 60;
  const volumePerMinute = volume / timePeriodInMinutes;
  return convertMetricToImperial(UnitType.FLOW, country, volumePerMinute);
}

export default getSampleVolumePerMinute;
