import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';

import { loadBackOfficeUsers } from 'redux/modules/backOfficeUsers/actions';
import { selectBackOfficeUsersRequestParameters } from 'redux/modules/routerUtils/selectors';

function BackOfficeUsersRequest() {
  const backOfficeUsersRequestParameters = useSelector(
    selectBackOfficeUsersRequestParameters
  );

  const dispatch = useDispatch();

  useDeepCompareEffect(() => {
    dispatch(loadBackOfficeUsers());
  }, [dispatch, backOfficeUsersRequestParameters]);

  return null;
}

export default memo(BackOfficeUsersRequest);
