import { Button } from 'antd';
import React, { memo } from 'react';
import { useDispatch } from 'react-redux';

import { appendValuesToQueryString } from 'redux/modules/routerUtils/actions';
import { useRouterQueryStringFilterSet } from 'redux/modules/routerUtils/hooks';
import { FilterSetName } from 'redux/modules/routerUtils/types';

interface Props {
  filterSetName: FilterSetName;
}

function BackOfficeFilterDrawerTriggerButton({
  filterSetName
}: Props): JSX.Element | null {
  const filterSet = useRouterQueryStringFilterSet(filterSetName);

  const numberOfAppliedFilters = Object.keys(filterSet).length;

  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(
      appendValuesToQueryString({
        drawerFilter: filterSetName
      })
    );
  };

  if (!filterSetName) {
    return null;
  }

  return (
    <Button size="small" type="primary" ghost onClick={handleClick}>
      FILTERS{numberOfAppliedFilters ? ` · ${numberOfAppliedFilters}` : ''}
    </Button>
  );
}

export default memo(BackOfficeFilterDrawerTriggerButton);
