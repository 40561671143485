import { ColumnType } from 'antd/lib/table';
import moment from 'moment-timezone';
import React, { useCallback } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import BackOfficeTable, {
  Props as BackOfficeTableProps
} from 'components/tables/BackOfficeTable';
import { DATE_TIME_FORMAT } from 'constants/time';
import User from 'models/user';
import {
  selectBackOfficeUsersAsArray,
  selectBackOfficeUsersTotal
} from 'redux/modules/backOfficeUsers/selectors';
import { appendValuesToQueryString } from 'redux/modules/routerUtils/actions';
import { selectRouterQueryStringSelectedUser } from 'redux/modules/routerUtils/selectors';

const renderBoolean = (value: boolean) => (value ? 'Yes' : 'No');
const renderBooleanNotifications = (value: boolean) =>
  value ? 'Enabled' : 'Disabled';

const loadAction = 'LOAD_BACK_OFFICE_USERS';

const columns: ColumnType<User>[] = [
  {
    title: 'First Name',
    dataIndex: 'firstName',
    width: 200,
    ellipsis: true,
    fixed: 'left',
    sorter: true
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    width: 200,
    ellipsis: true,
    sorter: true
  },
  {
    title: 'ID',
    dataIndex: 'id',
    width: 65,
    ellipsis: true,
    sorter: true
  },
  {
    title: 'Username',
    dataIndex: 'username',
    width: 200,
    ellipsis: true,
    sorter: true
  },
  {
    title: 'Email',
    dataIndex: 'email',
    width: 200,
    ellipsis: true,
    sorter: true
  },
  {
    title: 'Mobile Number',
    dataIndex: 'mobileNumberFormatted',
    width: 200,
    ellipsis: true
  },
  {
    title: 'Landline Number',
    dataIndex: 'landlineNumberFormatted',
    width: 200,
    ellipsis: true
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    width: 250,
    ellipsis: false,
    sorter: true,
    render: (createdAt: number) => (
      <div>{moment(createdAt).format(DATE_TIME_FORMAT.DEFAULT)}</div>
    )
  },
  {
    title: 'Super Admin',
    dataIndex: 'isSuperAdmin',
    width: 100,
    ellipsis: false,
    sorter: true,
    render: renderBoolean
  },
  {
    title: 'SMS Notifications',
    dataIndex: 'enableSmsNotifications',
    width: 125,
    ellipsis: false,
    render: renderBooleanNotifications
  },
  {
    title: 'Email Notifications',
    dataIndex: 'enableEmailNotifications',
    width: 125,
    ellipsis: false,
    render: renderBooleanNotifications
  }
];

function BackOfficeUsersTable({
  onPaginationChange
}: Pick<BackOfficeTableProps<User>, 'onPaginationChange'>): JSX.Element {
  const data: User[] = useSelector(selectBackOfficeUsersAsArray, shallowEqual);
  const userId = useSelector(selectRouterQueryStringSelectedUser, shallowEqual);

  const backOfficeUsersTotal = useSelector(
    selectBackOfficeUsersTotal,
    shallowEqual
  );

  const rowIdToOnClickAction = useCallback(
    (id: number) =>
      appendValuesToQueryString({
        drawer: undefined,
        selectedUser: id
      }),
    []
  );

  return (
    <BackOfficeTable<User>
      columns={columns}
      data={data}
      total={backOfficeUsersTotal}
      loadActionType={loadAction}
      rowIdToOnClickAction={rowIdToOnClickAction}
      onPaginationChange={onPaginationChange}
      emptyText="No users found"
      selectedRowId={userId}
    />
  );
}

export default BackOfficeUsersTable;
