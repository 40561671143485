import { AxiosError } from 'axios';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import {
  all,
  call,
  fork,
  put,
  select,
  takeEvery,
  takeLatest
} from 'redux-saga/effects';

import mockActivityEvents from 'components/features/machineControl/ActivityLog/__tests__/mock-activity-events.json';
import { EnterpriseId } from 'models/enterprise';
import SamplePoint, {
  MachineControl,
  MergedSamplePoint
} from 'models/samplePoint';
import { ConnectedSamplePoint, MachineControlStatistic } from 'models/samplePointsStatistic';
import convertUnit from 'utils/convert-unit';
import {
  getRequest,
  patchRequest,
  postRequest
} from 'utils/redux-saga-requests';

import {
  getControlPointActivityLog,
  getControlPointActivityLogFailure,
  getControlPointActivityLogSuccess,
  loadControlPointStatus,
  loadControlPointStatusFailure,
  patchControlPoint,
  patchControlPointFailure,
  patchControlPointSuccess,
  sendMachineControlAction,
  sendMachineControlActionFailure,
  sendMachineControlActionSuccess
} from './actions';
import ControlPointActionType from './constants';
import {
  AutomaticControlType,
  MachineControlActionType,
  MachineControlStatus,
  MachineControlStatusCode,
  RawMachineControlStatus
} from './types';
import {
  selectCurrentEnterpriseId
} from '../enterprise/selectors';
import { setSamplePoint } from '../samplePoints/actions';
import { makeSelectSamplePointById } from '../samplePoints/selectors';
import { updateSamplePointsStatistics } from '../samplePointsStatistics/actions';
import { parseSamplePointStatistics } from '../samplePointsStatistics/saga';
import { SamplePointsStatisticsState } from '../samplePointsStatistics/types';

type PatchPumpControlAutomationConfigDTO = {
  automation: AutomaticControlType;
  destinationSamplePoint: ConnectedSamplePoint | null;
  sourceSamplePoint: ConnectedSamplePoint | null;
  startRunningWindow: string | null;
  stopRunningWindow: string | null;
};

export function parseStatusCode(code: MachineControlStatusCode) {
  switch (code) {
    case MachineControlStatusCode.MS_POSTED_TO_GATEWAY:
      return 'Awaiting satellite comms.';
    case MachineControlStatusCode.MS_POSTED_TO_GATEWAY_FAILED:
      return 'Error while sending action.';
    case MachineControlStatusCode.MS_REQ_DELIVERED_TO_MONITOR:
      return 'Monitor processing request.';
    case MachineControlStatusCode.MS_BLOCK_ASSEMBLY_IN_PROGRESS:
    case MachineControlStatusCode.MS_ACTION_REQUESTED:
    case MachineControlStatusCode.MS_SECONDARY_MC_TURNING_ON:
    case MachineControlStatusCode.MS_MC_TURNING_OFF:
      return 'Work in progress.';
    case MachineControlStatusCode.MS_PAYLOAD_DELIVERED:
      return 'Action completed successfully.';
    case MachineControlStatusCode.MS_PAYLOAD_INCOMPLETE:
      return 'Action incomplete.';
    case MachineControlStatusCode.MS_DELIVERY_FAILED:
      return 'Communications failed.';
    case MachineControlStatusCode.MS_EVENT_RECEIVED_SUCCESS:
      return 'Action received.';
    case MachineControlStatusCode.MS_COMMS_ERROR:
      return 'Communications error.';
    case MachineControlStatusCode.MS_UNKNOWN:
    case MachineControlStatusCode.MS_MC_OFF:
    case MachineControlStatusCode.MS_PRIMARY_MC_FAILED:
    case MachineControlStatusCode.MS_PRIMARY_MC_ON:
    case MachineControlStatusCode.MS_SECONDARY_MC_ON:
    case MachineControlStatusCode.MS_SECONDARY_MC_FAILED:
    case MachineControlStatusCode.MS_CHANGE_CONFIGURATION_SUCCESS:
    case MachineControlStatusCode.MS_CHANGE_CONFIGURATION_REQUESTED_FAILED:
    case MachineControlStatusCode.MS_ACTION_REQUESTED_FAILED:
    default:
      return '';
  }
}

function* requestControlPointStatus({
  payload: { samplePointId }
}: ReturnType<typeof loadControlPointStatus>) {
  try {
    const enterpriseId: EnterpriseId = yield select(selectCurrentEnterpriseId);
    const samplePoint: MergedSamplePoint = yield select(
      makeSelectSamplePointById(samplePointId)
    );
    // Dual
    if (samplePoint._hidden) {
      const [
        { data: primaryMachineControlData },
        { data: secondaryMachineControlData }
      ] = yield all([
        call(
          getRequest,
          `enterprise/${enterpriseId}/machine-control/v2/${samplePointId}/status`
        ),
        call(
          getRequest,
          `enterprise/${enterpriseId}/machine-control/v2/${samplePoint._hidden.id}/status`
        )
      ]);
      const machineControlsStatuses = [
        primaryMachineControlData,
        secondaryMachineControlData
      ];
      const parsedStatistics: SamplePointsStatisticsState['data'] = yield call(
        parseSamplePointStatistics,
        keyBy(machineControlsStatuses, 'id')
      );

      yield put(updateSamplePointsStatistics(parsedStatistics));
      return;
    }
    // Single / Double
    const { data }: { data: RawMachineControlStatus } = yield call(
      getRequest,
      `enterprise/${enterpriseId}/machine-control/v2/${samplePointId}/status`
    );
    const parsedStatistics: SamplePointsStatisticsState['data'] = yield call(
      parseSamplePointStatistics,
      {
        [samplePointId]: data
      }
    );

    yield put(updateSamplePointsStatistics(parsedStatistics));
  } catch (error) {
    const message = get(
      error,
      'response.data.message',
      'We can\'t get the pump (or genset) status. Try again later.'
    );
    // eslint-disable-next-line no-console
    console.error(error);
    yield put(
      loadControlPointStatusFailure(
        samplePointId,
        message,
        error as AxiosError
      )
    );
  }
}

function* requestSendMachineControlAction({
  payload: { samplePointId, machineControlAction, onSuccess, onError }
}: ReturnType<typeof sendMachineControlAction>) {
  try {
    const samplePoint: MergedSamplePoint = yield select(
      makeSelectSamplePointById(samplePointId)
    );
    // To prevent double clicking the START/STOP button, we pre-update the status(es)
    // to tuning before getting the real status(es) from the API.
    const turningStatus =
      machineControlAction.actionRequest === MachineControlActionType.ON
        ? MachineControlStatus.TURNING_ON
        : MachineControlStatus.TURNING_OFF;
    // Pre-update (primary) machine control
    yield put(
      updateSamplePointsStatistics({
        [samplePointId]: {
          ...samplePoint.machineControlStatistics,
          status: turningStatus,
          _status: turningStatus,
          statusCode: MachineControlStatusCode.MS_POSTED_TO_GATEWAY,
          _statusCode: MachineControlStatusCode.MS_POSTED_TO_GATEWAY
        } as MachineControlStatistic
      })
    );
    // If it's dual machine control, pre-update secondary machine control
    if (samplePoint._hidden) {
      yield put(
        updateSamplePointsStatistics({
          [samplePoint._hidden.id]: {
            ...samplePoint._hidden.machineControlStatistics,
            status: turningStatus,
            _status: turningStatus,
            statusCode: MachineControlStatusCode.MS_POSTED_TO_GATEWAY,
            _statusCode: MachineControlStatusCode.MS_POSTED_TO_GATEWAY
          } as MachineControlStatistic
        })
      );
    }

    const enterpriseId: EnterpriseId = yield select(selectCurrentEnterpriseId);
    const { data } = yield call(
      postRequest,
      `enterprise/${enterpriseId}/machine-control/${samplePointId}/action-request`,
      machineControlAction
    );
    yield put(loadControlPointStatus(samplePointId));
    yield put(
      sendMachineControlActionSuccess(
        samplePointId,
        machineControlAction.actionRequest,
        data
      )
    );
    onSuccess();
  } catch (error) {
    const message = get(
      error,
      'response.data.message',
      'Failed to operate the pump. Try again later.'
    );
    onError(
      `Failed to turn ${machineControlAction.actionRequest === MachineControlActionType.OFF
        ? 'off'
        : 'on'
      } the pump. Try again later.`
    );
    yield put(
      sendMachineControlActionFailure(
        samplePointId,
        message,
        error as AxiosError
      )
    );
  }
}

function* requestPatchControlPoint({
  payload: {
    samplePoint,
    config: { destinationSamplePoint, sourceSamplePoint, controlType, startTime, stopTime },
    onSuccess,
    onError
  }
}: ReturnType<typeof patchControlPoint>) {
  try {
    // Update the automation config.
    let controlPointConfig: PatchPumpControlAutomationConfigDTO = {
      automation: controlType ?? AutomaticControlType.MANUAL,
      destinationSamplePoint: null,
      sourceSamplePoint: null,
      startRunningWindow: startTime,
      stopRunningWindow: stopTime
    };
    if (destinationSamplePoint?.sid) {
      controlPointConfig = {
        ...controlPointConfig,
        destinationSamplePoint: {
          sid: destinationSamplePoint?.sid,
          startLevelCm: destinationSamplePoint.startLevel && convertUnit(
            {
              value: destinationSamplePoint.startLevel.value,
              unit: destinationSamplePoint.startLevel.unit
            },
            { symbol: 'cm' }
          ),
          stopLevelCm: destinationSamplePoint.stopLevel && convertUnit(
            {
              value: destinationSamplePoint.stopLevel.value,
              unit: destinationSamplePoint.stopLevel.unit
            },
            { symbol: 'cm' }
          )
        }
      };
    }
    if (sourceSamplePoint?.sid) {
      controlPointConfig = {
        ...controlPointConfig,
        sourceSamplePoint: {
          sid: sourceSamplePoint.sid,
          startLevelCm: sourceSamplePoint.startLevel && convertUnit(
            {
              value: sourceSamplePoint.startLevel.value,
              unit: sourceSamplePoint.startLevel.unit
            },
            { symbol: 'cm' }
          ),
          stopLevelCm: sourceSamplePoint.stopLevel && convertUnit(
            {
              value: sourceSamplePoint.stopLevel.value,
              unit: sourceSamplePoint.stopLevel.unit
            },
            { symbol: 'cm' }
          )
        }
      };
    }
    // FE won't send a pump directly into auto paused mode. It's handled by BE/IoT.
    if (controlType === AutomaticControlType.AUTO_PAUSED) {
      controlPointConfig.automation = AutomaticControlType.AUTO;
    }
    const enterpriseId: EnterpriseId = yield select(selectCurrentEnterpriseId);
    yield call(
      patchRequest,
      `enterprise/${enterpriseId}/machine-control/v2/${samplePoint.id}`,
      controlPointConfig
    );
    yield put(loadControlPointStatus(samplePoint.id));
    yield put(patchControlPointSuccess(samplePoint.id));
    yield put(
      setSamplePoint({
        ...samplePoint,
        machineControlStatistics: {
          ...(samplePoint as MachineControl).machineControlStatistics,
          ...controlPointConfig
        }
      } as SamplePoint)
    );
    onSuccess(samplePoint, 'Pump control has been updated.');
  } catch (error) {
    const message = get(
      error,
      'response.data.message',
      'Failed to update pump control. Try again later.'
    );
    onError('Failed to update pump control. Try again later.');
    yield put(
      patchControlPointFailure(samplePoint.id, message, error as AxiosError)
    );
  }
}

function* requestControlPointActivityLog({
  payload: { samplePointId }
}: ReturnType<typeof getControlPointActivityLog>) {
  try {
    const enterpriseId: EnterpriseId = yield select(selectCurrentEnterpriseId);
    // START - For testing only
    if (
      (['development', 'local'].includes(process.env.REACT_APP_ENV) &&
        enterpriseId === 779 &&
        samplePointId === 637640) ||
      (process.env.REACT_APP_ENV === 'staging' &&
        enterpriseId === 1755 &&
        samplePointId === 434777)
    ) {
      yield put(
        getControlPointActivityLogSuccess(samplePointId, mockActivityEvents)
      );
      return;
    }
    // END - For testing only
    const { data } = yield call(
      getRequest,
      `enterprise/${enterpriseId}/machine-control/${samplePointId}/activity-log?limit=20`
    );
    yield put(getControlPointActivityLogSuccess(samplePointId, data));
  } catch (error) {
    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );
    yield put(
      getControlPointActivityLogFailure(
        samplePointId,
        message,
        error as AxiosError
      )
    );
  }
}

function* watchControlPointStatusRequest() {
  yield takeEvery(
    ControlPointActionType.LOAD_CONTROL_POINT_STATUS_REQUEST,
    requestControlPointStatus
  );
}

function* watchSendMachineControlActionRequest() {
  yield takeLatest(
    ControlPointActionType.SEND_MACHINE_CONTROL_ACTION_REQUEST,
    requestSendMachineControlAction
  );
}

function* watchPatchControlPointRequest() {
  yield takeLatest(
    ControlPointActionType.PATCH_CONTROL_POINT_REQUEST,
    requestPatchControlPoint
  );
}

function* watchControlPointActivityLogRequest() {
  yield takeLatest(
    ControlPointActionType.GET_CONTROL_POINT_ACTIVITY_LOG_REQUEST,
    requestControlPointActivityLog
  );
}

export default function* controlPointsSaga() {
  yield all([
    fork(watchControlPointStatusRequest),
    fork(watchSendMachineControlActionRequest),
    fork(watchPatchControlPointRequest),
    fork(watchControlPointActivityLogRequest)
  ]);
}
