/* LIBRARIES */
import { message as antdMessage } from 'antd';
import axios from 'axios';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';

import { commonDateWithBrowserTimezone } from 'utils/Date/common-date-time/common-date-time';
import downloadFile from 'utils/download-file';
import { getRequest } from 'utils/redux-saga-requests';

import {
  downloadDevicesAndSamplePointsCSV,
  downloadDevicesAndSamplePointsCSVSuccess
} from './actions';
import ActionTypes from './constants';

// ==============================
// SAGAS
// ==============================
export function* exportDevicesWithSamplePoints(
  action: ReturnType<typeof downloadDevicesAndSamplePointsCSV>
) {
  const {
    payload: { enterpriseId, enterpriseName }
  } = action;

  try {
    const { data } = yield call(
      getRequest,
      `device/export?format=csv&enterpriseId=${enterpriseId}`
    );

    const dateText = commonDateWithBrowserTimezone(new Date());
    const fileName = `${enterpriseName.trim()} Devices List - ${dateText}.csv`;

    downloadFile(fileName, data, 'text/csv');

    antdMessage.success('Device list exported!');
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;

    antdMessage.error('Failed to export device list');
  } finally {
    yield put(downloadDevicesAndSamplePointsCSVSuccess());
  }
}

// ==============================
// REGISTRATION
// ==============================
export function* watchExportDevicesWithSamplePoints() {
  yield takeLatest(
    ActionTypes.LOAD_CSV_DEVICES_WITH_SAMPLEPOINTS_LIST_REQUEST,
    exportDevicesWithSamplePoints
  );
}

// ==============================
// EXPORT
// ==============================
export default function* exportsSaga() {
  yield all([fork(watchExportDevicesWithSamplePoints)]);
}
