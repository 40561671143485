import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Redirect, Route, Switch } from 'react-router-dom';

import ErrorBoundaryFallback from 'components/atoms/ErrorBoundaryFallback';
import HelmetWrapper from 'components/atoms/HelmetWrapper';
import BackOfficeDrawerPusher from 'components/features/backOffice/BackOfficeDrawerPusher';
import BackOfficeNavigation from 'components/features/backOffice/BackOfficeNavigation';
import BackOfficeDeviceMap from 'routes/BackOfficeDeviceMap';
import BackOfficeDevices from 'routes/BackOfficeDevices';
import BackOfficeEnterprises from 'routes/BackOfficeEnterprises';
import BackOfficeInvitations from 'routes/BackOfficeInvitations';
import BackOfficeUsers from 'routes/BackOfficeUsers';

function BackOffice(): JSX.Element {
  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <BackOfficeDrawerPusher>
        <>
          <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
            <HelmetWrapper section="Back Office" />
            <BackOfficeNavigation />
          </ErrorBoundary>
          <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
            <Switch>
              <Redirect exact from="/" to="/back-office/enterprises" />
              <Route
                path="/back-office/enterprises"
                component={BackOfficeEnterprises}
              />
              <Route
                path="/back-office/devices"
                component={BackOfficeDevices}
              />
              <Route
                path="/back-office/device-map"
                component={BackOfficeDeviceMap}
              />
              <Route path="/back-office/users" component={BackOfficeUsers} />
              <Route
                path="/back-office/invitations"
                component={BackOfficeInvitations}
              />
              <Redirect from="/*" to="/back-office/enterprises" />
            </Switch>
          </ErrorBoundary>
        </>
      </BackOfficeDrawerPusher>
    </ErrorBoundary>
  );
}

export default BackOffice;
