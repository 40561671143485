import { AxiosError, AxiosResponse } from 'axios';
import { action } from 'typesafe-actions';

import User, { UserId } from 'models/user';

import ActionTypes from './constants';

export const loadBackOfficeUsers = () =>
  action(ActionTypes.LOAD_BACK_OFFICE_USERS_REQUEST);

export const loadBackOfficeUsersSuccess = (response: AxiosResponse) =>
  action(ActionTypes.LOAD_BACK_OFFICE_USERS_SUCCESS, { response });

export const loadBackOfficeUsersFailure = (
  message: string,
  error?: AxiosError
) =>
  action(
    ActionTypes.LOAD_BACK_OFFICE_USERS_FAILURE,
    { message, error },
    undefined,
    true
  );

export const editBackOfficeUser = (
  userId: UserId,
  values: Record<string, unknown>
) =>
  action(ActionTypes.EDIT_BACK_OFFICE_USER_REQUEST, {
    userId,
    values
  });

export const editBackOfficeUserSuccess = (response: AxiosResponse) =>
  action(ActionTypes.EDIT_BACK_OFFICE_USER_SUCCESS, { response });

export const editBackOfficeUserFailure = (
  message: string,
  error?: AxiosError
) =>
  action(
    ActionTypes.EDIT_BACK_OFFICE_USER_FAILURE,
    { message, error },
    undefined,
    true
  );

export const setBackOfficeUsers = (payload: {
  total: number;
  data: Record<string, User>;
  ids: UserId[];
}) => action(ActionTypes.SET_BACK_OFFICE_USERS, payload);
