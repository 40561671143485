import AuthActionTypes from 'redux/modules/auth/constants';
import { ApplicationActions } from 'redux/types';

import ActionTypes from './constants';
import { BackOfficeEnterprisesState } from './types';

export const initialState: BackOfficeEnterprisesState = {
  total: undefined,
  data: {},
  ids: []
};

function backOfficeEnterprisesReducer(
  state: BackOfficeEnterprisesState = initialState,
  action: ApplicationActions
): BackOfficeEnterprisesState {
  switch (action.type) {
    case ActionTypes.SET_BACK_OFFICE_ENTERPRISES: {
      const {
        payload: { total, data, ids }
      } = action;

      return {
        total,
        data,
        ids
      };
    }
    case AuthActionTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export default backOfficeEnterprisesReducer;
