import { Typography } from 'antd';
import { PageHeaderProps } from 'antd/lib/page-header';
import { TagType } from 'antd/lib/tag';
import React, { memo } from 'react';
import { useMediaQuery } from 'react-responsive';

import DefaultPageHeaderButton from 'components/buttons/DefaultPageHeaderButton';
import { MAX_WIDTH_MOBILE } from 'style/constants';

import './styles.less';

const { Text, Paragraph, Link } = Typography;

interface Props
  extends Pick<
    PageHeaderProps,
    'onBack' | 'title' | 'subTitle' | 'backIcon' | 'tags' | 'extra'
  > {
  /**
   * Offset in pixels to allow for situations where you want the extra to align
   * differently.
   * e.g. When an icon button has no border and you want the icon to align with
   * the rest of the page.
   */
  extraOffset?: number;
}

/**
 * A custom PageHeader based on the antd PageHeader.
 */
function DefaultPageHeader({
  onBack,
  title,
  subTitle,
  // On mobile, it's the menu icon on top left.
  backIcon,
  tags,
  extra,
  extraOffset
}: Props): JSX.Element | null {
  const areTopPages = ['Map View', 'Notifications', 'Sensor Types', 'More'].includes(title as string);
  const { PUBLIC_URL } = process.env;
  const isMobile = useMediaQuery({ maxWidth: MAX_WIDTH_MOBILE });

  const Title = () => title ? (
    <div className="DefaultPageHeader-title">{title}</div>
  ) : null;

  const SubTitle = () => subTitle ? (
    <span className="DefaultPageHeader-subtitle">{subTitle}</span>
  ) : null;

  const LeftButton = () => (
    <DefaultPageHeaderButton
      noBorder
      flex
      className="DefaultPageHeader-button-with-title"
      // TODO: This ignore should be removed and Type issues addressed
      // when antd gets upgraded to v5
      // @ts-ignore
      onClick={onBack}
    >
      {backIcon}
      <Title />
    </DefaultPageHeaderButton>
  );

  const LeftButtonMobile = () => (
    <div className="DefaultPageHeader-extra-left">
      <DefaultPageHeaderButton
        noBorder
        // TODO: This ignore should be removed and Type issues addressed
        // when antd gets upgraded to v5
        // @ts-ignore
        onClick={onBack}
      >
        {backIcon}
      </DefaultPageHeaderButton>
    </div>
  );

  const RightButton = () => extra ? (
    <div className="DefaultPageHeader-extra-right" style={{ right: extraOffset }}>
      {extra}
    </div>
  ) : null;

  const SimplifiedTag = ({ tag }: { tag: React.ReactElement<TagType> }) =>
    React.cloneElement(tag, {
      // @ts-ignore
      asPlainText: true,
      className: 'DefaultPageHeader-simplified-tag'
    });

  const showLeftButton = backIcon && onBack;
  const showRowTwo = tags || subTitle;

  if (isMobile) {
    return (
      <div className="DefaultPageHeader">
        {showLeftButton && (
          <LeftButtonMobile />
        )}
        <div className="DefaultPageHeader-content-left">
          <div className="DefaultPageHeader-content-left-row-1">
            <Title />
          </div>
          {showRowTwo && (
            <div className="DefaultPageHeader-content-left-row-2">
              {tags && (
                Array.isArray(tags)
                  ? tags.map((tag, index) => (
                    <SimplifiedTag key={index.toString()} tag={tag} />
                  )) : (
                    <SimplifiedTag tag={tags} />
                  )
              )}
              <SubTitle />
            </div>
          )}
        </div>
        <RightButton />
      </div>
    );
  }
  return (
    <div className="DefaultPageHeader">
      <div className="DefaultPageHeader-content-left">
        <div className="DefaultPageHeader-content-left-row-1">
          {showLeftButton ? <LeftButton /> : <Title />}
        </div>
        {showRowTwo && (
          <div className="DefaultPageHeader-content-left-row-2">
            {tags}
            <SubTitle />
          </div>
        )}
      </div>
      <RightButton />
    </div>
  );
}

export default memo(DefaultPageHeader);
