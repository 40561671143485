import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import {
  selectRouterQueryStringDrawer,
  selectRouterQueryStringInvitationEmail,
  selectRouterQueryStringSelectedDevice,
  selectRouterQueryStringSelectedEnterprise,
  selectRouterQueryStringSelectedUser
} from 'redux/modules/routerUtils/selectors';
import { LEGACY_MAX_WIDTH_TABLET_MEDIUM } from 'style/constants';

import './styles.less';

interface Props {
  children: React.ReactNode;
}

function BackOfficeDrawerPusher({ children }: Props): JSX.Element {
  const isTabletOrMobile = useMediaQuery({ maxWidth: LEGACY_MAX_WIDTH_TABLET_MEDIUM });

  const drawer = useSelector(selectRouterQueryStringDrawer);
  const selectedEnterprise = useSelector(
    selectRouterQueryStringSelectedEnterprise
  );
  const selectedDevice = useSelector(selectRouterQueryStringSelectedDevice);
  const selectedUser = useSelector(selectRouterQueryStringSelectedUser);
  const invitationEmail = useSelector(selectRouterQueryStringInvitationEmail);

  return (
    <div
      className={classNames('BackOfficeDrawerPusher', {
        open:
          !isTabletOrMobile &&
          (drawer ||
            selectedEnterprise ||
            selectedDevice ||
            selectedUser ||
            invitationEmail)
      })}
    >
      {children}
    </div>
  );
}

export default BackOfficeDrawerPusher;
