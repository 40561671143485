import { AxiosError, AxiosResponse } from 'axios';
import { action } from 'typesafe-actions';

import { AgriWebbSiteIntegrations } from 'models/agriwebb';
import { EnterpriseId } from 'models/enterprise';
import { WhiteLabelTitle } from 'models/whiteLabel';

import AgriWebbActionType from './constants';
import { AgriWebbFarm } from './types';

/** Activate one enterprise */
export const activateAccount = (
  values: {
    enterpriseId: number;
    /** AgriWebb auth code */
    agriWebbCode: string;
    whiteLabel: WhiteLabelTitle;
  },
  onSuccess?: () => void,
  onError?: () => void
) =>
  action(AgriWebbActionType.ACTIVATE_ACCOUNT_REQUEST, {
    values,
    onSuccess,
    onError
  });

export const activateAccountSuccess = (response: AxiosResponse) =>
  action(AgriWebbActionType.ACTIVATE_ACCOUNT_SUCCESS, { response });

export const activateAccountFailure = (message: string, error?: AxiosError) =>
  action(
    AgriWebbActionType.ACTIVATE_ACCOUNT_FAILURE,
    { message, error },
    undefined,
    true
  );

/** Deactivate one enterprise */
export const deactivateAccount = (
  enterpriseId: EnterpriseId,
  onSuccess?: () => void,
  onError?: (message: string) => void
) =>
  action(AgriWebbActionType.DEACTIVATE_ACCOUNT_REQUEST, {
    enterpriseId,
    onSuccess,
    onError
  });

export const editSiteFarmMappings = (
  values: { siteMapping: AgriWebbSiteIntegrations[]; enterpriseId: number },
  onSuccess?: () => void,
  onError?: (message: string) => void
) =>
  action(AgriWebbActionType.EDIT_SITE_FARM_MAPPINGS_REQUEST, {
    values,
    onSuccess,
    onError
  });

export const editSiteFarmMappingsSuccess = (response: AxiosResponse) =>
  action(AgriWebbActionType.EDIT_SITE_FARM_MAPPINGS_SUCCESS, { response });

export const editSiteFarmMappingsFailure = (
  message: string,
  error?: AxiosError
) =>
  action(
    AgriWebbActionType.EDIT_SITE_FARM_MAPPINGS_FAILURE,
    { message, error },
    undefined,
    true
  );

export const loadFarms = (
  enterpriseId: EnterpriseId,
  onSuccess: (farms: AgriWebbFarm[]) => void,
  onError: (message: string) => void
) =>
  action(AgriWebbActionType.LOAD_FARMS_REQUEST, {
    enterpriseId,
    onSuccess,
    onError
  });

export const loadFarmsSuccess = (payload: { data: AgriWebbFarm[] }) =>
  action(AgriWebbActionType.LOAD_FARMS_SUCCESS, payload);

export const loadFarmsFailure = (message: string, error?: AxiosError) =>
  action(
    AgriWebbActionType.LOAD_FARMS_FAILURE,
    { message, error },
    undefined,
    true
  );
