import { message as antdMessage } from 'antd';
import axios from 'axios';
import get from 'lodash/get';
import type { SagaIterator } from 'redux-saga';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import Country, { CountryAbbreviation } from 'models/country';
import Enterprise from 'models/enterprise';
import User from 'models/user';
import parseBackendPhoneNumber from 'utils/phone-number/parse-backend-phone-number';
import { getRequest, patchRequest } from 'utils/redux-saga-requests';

import {
  editEnterprise,
  editEnterpriseFailure,
  editEnterpriseSuccess,
  loadEnterprise,
  loadEnterpriseFailure,
  loadEnterpriseSuccess,
  setEnterprise
} from './actions';
import ActionTypes from './constants';
import { selectCurrentEnterprise } from './selectors';
import { getCountryByAbbr } from '../countries/actions';
import { selectCountriesAsArray } from '../countries/selectors';

const parserEnterpriseOwner = (enterpriseOwner: User): User => {
  let owner: User = parseBackendPhoneNumber(
    enterpriseOwner,
    'mobileNumber',
    'mobileNumberCountryCode',
    'mobileNumberFormatted'
  );

  owner = parseBackendPhoneNumber(
    owner,
    'landlineNumber',
    'landlineNumberCountryCode',
    'landlineNumberFormatted'
  );
  return owner;
};

export function* requestEnterprise(
  action: ReturnType<typeof loadEnterprise>
): SagaIterator {
  const {
    payload: { enterpriseSubdomain }
  } = action;
  try {
    const response = yield call(
      getRequest,
      'enterprise?join=owner&join=billingAddress',
      {
        params: {
          s: {
            $or: [
              { id: { $eq: Number(enterpriseSubdomain) } },
              { subdomain: { $eq: enterpriseSubdomain } }
            ]
          }
        }
      }
    );
    const { data }: { data: Enterprise[] } = response;
    if (data.length) {
      const enterpriseCountryAbbr: CountryAbbreviation =
        data[0].billingAddress?.country ?? 'AUS';
      const cachedCountries: Country[] | undefined = yield select(
        selectCountriesAsArray
      );
      if (
        !cachedCountries?.find((c) => c.abbreviation === enterpriseCountryAbbr)
      ) {
        yield put(getCountryByAbbr(enterpriseCountryAbbr));
      }
      yield all([
        put(
          setEnterprise(
            data.map((enterprise: Enterprise): Enterprise => {
              return {
                ...enterprise,
                owner: parserEnterpriseOwner(enterprise.owner)
              };
            })[0]
          )
        ),
        put(loadEnterpriseSuccess(response))
      ]);
    } else {
      yield put(loadEnterpriseFailure('Sorry, something went wrong.'));
    }
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );
    yield put(loadEnterpriseFailure(message, error));
  }
}

export function* watchloadEnterpriseRequest() {
  yield takeLatest(ActionTypes.LOAD_ENTERPRISE_REQUEST, requestEnterprise);
}

export function* requestEditEnterprise(
  action: ReturnType<typeof editEnterprise>
): SagaIterator {
  const {
    payload: { values }
  } = action;

  const enterprise = yield select(selectCurrentEnterprise);
  const enterpriseId = enterprise ? enterprise?.id : values.id;
  try {
    const response = yield call(patchRequest, `enterprise/${enterpriseId}`, {
      ...values,
      billingAddress: {
        id: values.billingAddressId,
        ...values.billingAddress
      }
    });
    const { data } = response;
    yield all([put(setEnterprise(data)), put(editEnterpriseSuccess(response))]);
    antdMessage.success('Enterprise details updated');
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );
    yield put(editEnterpriseFailure(message, error));
  }
}

export function* watchEditEnterprisesRequest() {
  yield takeLatest(ActionTypes.EDIT_ENTERPRISE_REQUEST, requestEditEnterprise);
}

export default function* enterpriseSaga() {
  yield all([
    fork(watchloadEnterpriseRequest),
    fork(watchEditEnterprisesRequest)
  ]);
}
