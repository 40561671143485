import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { useSelector } from 'react-redux';

import LoadingSpinner from 'components/composites/LoadingSpinner';
import ErrorModal from 'components/modals/ErrorModal';
import AppScaffoldActionType from 'redux/modules/appScaffold/constants';
import { selectAppScaffoldInitialDataLoaded } from 'redux/modules/appScaffold/selectors';
import { makeSelectFirstErrorMessage } from 'redux/modules/error/selectors';
import MyUserActionType from 'redux/modules/myUser/constants';
import { selectMyUserExists } from 'redux/modules/myUser/selectors';
import { selectIsNetworkReady } from 'redux/modules/network/selectors';

const actions = [
  MyUserActionType.LOAD_MY_USER_REQUEST,
  AppScaffoldActionType.LOAD_APP_SCAFFOLD_REQUEST
];

export const selectErrorMessage = makeSelectFirstErrorMessage(actions);

interface Props {
  children: JSX.Element;
}

/**
 * Guards protected routes and prevents children from rendering while my user
 * data is loading.
 * Will Display an alert in the event of an error.
 */
function ProtectedRoutesLoadingGate({ children }: Props): JSX.Element {
  const myUserExists = useSelector(selectMyUserExists);
  const appScaffoldInitialDataLoaded = useSelector(
    selectAppScaffoldInitialDataLoaded
  );
  const isNetworkReady = useSelector(selectIsNetworkReady);
  const errorMessage = useSelector(selectErrorMessage);

  const { isAuthenticated, loginWithRedirect } = useAuth0();
  // When a user is not authenticated, redirect them to Auth0 login page.
  // When the user session expires, they are unauthenticated too. But this time,
  // the loginWithRedirect() will silently redirect and back with a new authori-
  // zation code.
  if (!isAuthenticated) {
    loginWithRedirect({
      appState: { target: window.location.href }
    });
  }
  // Waiting for
  // 1) myUser to be fetched from BE
  // 2) app scaffold data to be loaded from BE or redux
  // 3) and axios to be configured
  if (myUserExists && appScaffoldInitialDataLoaded && isNetworkReady) {
    return children;
  }

  if (errorMessage) {
    return <ErrorModal errorMessage={errorMessage} />;
  }

  return <LoadingSpinner useLogo />;
}

export default ProtectedRoutesLoadingGate;
