import Card, { CardProps } from 'antd/lib/card';
import React, { memo } from 'react';

import classNamesWithBase from 'utils/classnames-with-base';

import './styles.less';

interface Props
  extends Pick<
    CardProps,
    | 'id'
    | 'style'
    | 'bodyStyle'
    | 'children'
    | 'className'
    | 'title'
    | 'extra'
    | 'bordered'
  > {
  standardMaxWidth?: boolean;
}

/**
 * A wrapper around the antd Card with our custom config applied.
 */
function DefaultCard({
  id,
  style,
  bodyStyle,
  children,
  className,
  title,
  extra,
  bordered,
  standardMaxWidth = false
}: Props): JSX.Element {
  return (
    <Card
      id={id}
      data-testid={id}
      style={style}
      bodyStyle={bodyStyle}
      className={classNamesWithBase(
        'DefaultCard',
        {
          standardMaxWidth
        },
        className
      )}
      title={title}
      extra={extra}
      bordered={bordered}
    >
      {children}
    </Card>
  );
}

export default memo(DefaultCard);
