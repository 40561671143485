import axios, { AxiosResponse } from 'axios';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import moment from 'moment-timezone';
import { all, call, put } from 'redux-saga/effects';

import Sample from 'models/sample';
import { getRequest } from 'utils/redux-saga-requests';

import {
  loadInternalSamples,
  loadInternalSamplesFailure,
  loadInternalSamplesSuccess,
  setSamplePointSamples
} from '../actions';

// This is almost identical to requestSamples. The difference is the
// request endpoint; we cannot access internal samples from the BE
// with our usual request endpoint. However, we can access internal
// samples by getting all samples and filtering it by the samplePointId.

export function* requestInternalSamples(
  action: ReturnType<typeof loadInternalSamples>
) {
  const {
    payload: { samplePointId, startDateMs, endDateMs, siteTimezoneCode }
  } = action;

  try {
    const startDateInSiteTimezone = moment(startDateMs).tz(siteTimezoneCode);
    const endDateInSiteTimezone = moment(endDateMs).tz(siteTimezoneCode);

    const response: AxiosResponse = yield call(getRequest, `samplePoint/${samplePointId}/sample`, {
      params: {
        startDate: startDateInSiteTimezone.format(),
        endDate: endDateInSiteTimezone.format()
      }
    });

    // Unlike regular samples, internal samples need no conversion.
    // Volts are common to metric and imperial systems.
    const samples = response.data.map((sample: Sample) => ({
      ...sample,
      samplePointId
    }));

    yield all([
      put(loadInternalSamplesSuccess(response)),
      put(setSamplePointSamples(keyBy(samples, 'id')))
    ]);
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    let message;
    if (error.message === 'Network Error') {
      message = 'Network Error';
    } else {
      message = get(
        error,
        'response.data.message',
        'Sorry, something went wrong.'
      );
    }

    yield put(loadInternalSamplesFailure(message, error));
  }
}
