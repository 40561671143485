import rollbarLogger from 'config/rollbar';
import SamplePoint from 'models/samplePoint';
import { TankShape } from 'models/tank';
import roundNumber from 'utils/round-number';

const MAX_ITERATION = 100;

interface Options {
  decimalPlaces?: number;
}

/**
 * Given a function that is strictly monotonically increasing,
 * (see https://en.wikipedia.org/wiki/Monotonic_function)
 * and a number y, find the x such that func(x) = y (to a given precision).
 * An optional starting upper & lower bounds, and test x can be given.
 */
function invertStrictlyMonotonicallyIncreasingFunction(
  func: (x: number) => number,
  targetResult: number,
  decimalPlaces = 2,
  bounds?: [number, number],
  startValue?: number
) {
  const initialLowValue = 0;
  const initialHighValue = 1000000;

  const roundedTarget = roundNumber(targetResult, { decimalPlaces });

  // Find starting upper and lower bounds.
  let low: number;
  let high: number;
  if (bounds) {
    [low, high] = bounds;
  } else {
    low = initialLowValue;
    high = initialHighValue;
    while (func(low) > roundedTarget) {
      high = low;
      low = low < 0 ? low * 2 : low / 2 - 100;
    }
    while (func(high) < roundedTarget) {
      high *= 2;
    }
  }

  // Check if either boundary gives the target value.
  // If bounds are given to the function we assume the target value is inside
  // them. If it is outside this function will run forever.
  if (roundNumber(func(low), { decimalPlaces }) === roundedTarget) {
    return low;
  }
  if (roundNumber(func(high), { decimalPlaces }) === roundedTarget) {
    return high;
  }

  let iteration = 0;
  // Find starting value.
  let guess = startValue || (low + high) / 2;
  // Iteratively try to find best guess.
  let result = func(guess);

  while (
    Math.abs(roundNumber(result, { decimalPlaces }) - roundedTarget) !== 0 &&
    iteration < MAX_ITERATION
  ) {
    if (result < roundedTarget) {
      low = guess;
    } else {
      high = guess;
    }

    guess = (low + high) / 2;
    result = func(guess);
    iteration += 1;
  }
  if (iteration === MAX_ITERATION) {
    rollbarLogger.error(
      'Max iteration reached in invertStrictlyMonotonicallyIncreasingFunction()'
    );
  }
  return guess;
}

/**
 * Calculates the volume for a horizontal cylinder tank.
 *
 * All the parameters are in centimeters. Cubic inches are not gallon.
 *
 * TAKEN FROM BE IMPLEMENTATION
 *
 * @param heightCm, the height of the tank (in centimeters!).
 * @param lengthCm, the length of the tank (in centimeters!).
 * @param filledCm, the height of the liquid in the tank (in centimeters!).
 * @returns the volume for that specific height of the liquid.
 */
function calculateHCylinderVolumeL(
  heightCm: number,
  lengthCm: number,
  filledCm: number
): number {
  const radius = heightCm / 2;
  // θ = 2 * arccos((radius - filled) / radius)
  const theta = 2 * Math.acos((radius - filledCm) / radius);

  // 0.5 * radius² * (θ - sin(θ)) * length
  const hCylinderFilled =
    0.5 * radius ** 2 * (theta - Math.sin(theta)) * lengthCm;
  const volumeInLiters = hCylinderFilled / 1000; // Convert to liters
  return Number(volumeInLiters.toFixed(2));
}

/**
 * Calculates the volume for a horizontal elliptical tank.
 * The equation to calculate the volume for a full tank and a partially full tank
 * is different.
 *
 * All the parameters are in centimeters. Cubic inches are not gallon.
 *
 * TAKEN FROM BE IMPLEMENTATION
 *
 * @param heightCm, the height of the tank (in centimeters!).
 * @param widthCm, the width of the tank (in centimeters!).
 * @param lengthCm, the length of the tank (in centimeters!).
 * @param filledCm, the height of the liquid in the tank (in centimeters!).
 * @returns the volume for that specific height of the liquid.
 */
function calculateHEllipticalVolumeL(
  heightCm: number,
  widthCm: number,
  lengthCm: number,
  filledCm: number
): number {
  // Calculate full volume: π * width * length* height / 4
  if (filledCm === heightCm) {
    const vEllipticalVolume = (Math.PI * widthCm * lengthCm * heightCm) / 4;
    const volumeInLiters = vEllipticalVolume / 1000;
    return Number(volumeInLiters.toFixed(2));
  }
  // Partial fill equation
  // - length * height * width / 4 * (arccos(1 - (2 * filled / height))) - ((1 - (2 * filled / height)
  // * √(4 * filled / height - 4 * filled²/height²)))

  // √(4 * filled / height - 4 * filled²/height²)
  const sRoot1 = (4 * filledCm) / heightCm;
  const sRoot2 = (4 * filledCm ** 2) / heightCm ** 2;
  const sRoot = Math.sqrt(sRoot1 - sRoot2);

  // (arccos(1 - (2 * filled / height))) - ((1 - (2 * filled / height) * √(4 * filled / height - 4 * filled²/height²)))
  const calcOne = 1 - (2 * filledCm) / heightCm;
  const arccos = Math.acos(calcOne) - calcOne * sRoot;

  const vEllipticalFilled = lengthCm * heightCm * (widthCm / 4) * arccos;
  const volumeInLiters = vEllipticalFilled / 1000; // Convert to liters
  return Number(volumeInLiters.toFixed(2));
}

/**
 * Given dimensions of a tank, and its current level in cm, calculate the
 * currently filled volume depending on its shape.
 */
export function calculateTankVolumeL(
  liquidLevelCm?: number | null,
  tankHeightCm?: number,
  maxVolumeL?: number,
  /** Tank shape */
  config?: SamplePoint['config'],
  options?: Options
) {
  const decimalPlaces = options?.decimalPlaces;

  if (!liquidLevelCm || !tankHeightCm || !maxVolumeL) {
    return 0;
  }

  let result;
  switch (config?.tankShape) {
    case TankShape.HorizontalCylinder:
      result = calculateHCylinderVolumeL(
        tankHeightCm,
        config.tankLength || 0,
        Math.min(liquidLevelCm, tankHeightCm)
      );
      break;
    case TankShape.HorizontalElliptical:
      result = calculateHEllipticalVolumeL(
        tankHeightCm,
        config.tankWidth || 0,
        config.tankLength || 0,
        Math.min(liquidLevelCm, tankHeightCm)
      );
      break;
    case TankShape.VerticalCylinder:
    default:
      result = (liquidLevelCm / tankHeightCm) * maxVolumeL;
      break;
  }

  return roundNumber(result, { decimalPlaces });
}

type CalculateLiquidLevelCmByFilledVolumeLParams = {
  tankShape: TankShape;
  tankLengthCm?: number;
  tankWidthCm?: number;
  volumeL: number;
  maxValueCm?: number;
  maxVolumeL?: number;
  options?: Options;
};

/**
 * Given dimensions of a tank, and its current volume in L, calculate the
 * current level in cm depending on its shape.
 *
 * All the parameters are in centimeters. Cubic inches are not gallon.
 */
export function calculateLiquidLevelCmByFilledVolumeL({
  tankShape,
  tankLengthCm,
  tankWidthCm,
  volumeL,
  maxValueCm,
  maxVolumeL,
  options
}: CalculateLiquidLevelCmByFilledVolumeLParams) {
  const decimalPlaces = options?.decimalPlaces;

  if (!volumeL || !maxValueCm) {
    return 0;
  }

  let result;
  switch (tankShape) {
    case TankShape.HorizontalCylinder:
      result = invertStrictlyMonotonicallyIncreasingFunction(
        (x: number) =>
          calculateHCylinderVolumeL(maxValueCm, tankLengthCm || 0, x),
        volumeL,
        0,
        [0, maxValueCm]
      );
      break;
    case TankShape.HorizontalElliptical:
      result = invertStrictlyMonotonicallyIncreasingFunction(
        (x: number) =>
          calculateHEllipticalVolumeL(
            maxValueCm,
            tankWidthCm || 0,
            tankLengthCm || 0,
            x
          ),
        volumeL,
        0,
        [0, maxValueCm]
      );
      break;
    case TankShape.VerticalCylinder:
    default:
      if (!maxVolumeL) {
        return 0;
      }
      result = (volumeL / maxVolumeL) * maxValueCm;
      break;
  }

  return roundNumber(result, { decimalPlaces });
}
