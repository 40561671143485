import { ColumnType } from 'antd/lib/table';
import moment from 'moment-timezone';
import React, { useCallback } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import EnterpriseIndustryText from 'components/atoms/EnterpriseIndustryText';
import EnterpriseStatusText from 'components/atoms/EnterpriseStatusText';
import DevicesLink from 'components/composites/DevicesLink';
import EnterpriseLink from 'components/composites/EnterpriseLink';
import BackOfficeTable, {
  Props as BackOfficeTableProps
} from 'components/tables/BackOfficeTable';
import { DATE_TIME_FORMAT } from 'constants/time';
import Enterprise, { EnterpriseStatus, Industry } from 'models/enterprise';
import { selectBackOfficeEnterprisesTotal } from 'redux/modules/backOfficeEnterprises/selectors';
import { useBackOfficeEnterprises } from 'redux/modules/enterprise/hooks';
import { appendValuesToQueryString } from 'redux/modules/routerUtils/actions';
import { selectRouterQueryStringSelectedEnterprise } from 'redux/modules/routerUtils/selectors';

const loadAction = 'LOAD_BACK_OFFICE_ENTERPRISES';

const columns: ColumnType<Enterprise>[] = [
  {
    title: 'Name',
    dataIndex: 'name',
    width: 200,
    ellipsis: true,
    fixed: 'left',
    sorter: true
  },
  {
    title: 'ID',
    dataIndex: 'id',
    width: 65,
    ellipsis: true,
    sorter: true
  },
  {
    title: 'Super Admin',
    key: 'superAdmin',
    dataIndex: 'id',
    width: 120,
    ellipsis: true,
    render: (_enterpriseId: number, enterprise: Enterprise) => (
      <EnterpriseLink enterprise={enterprise} />
    )
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: (enterpriseStatus: EnterpriseStatus) => (
      <EnterpriseStatusText enterpriseStatus={enterpriseStatus} />
    ),
    width: 150,
    ellipsis: true
  },
  {
    title: 'Devices',
    key: 'devices',
    dataIndex: 'id',
    width: 100,
    ellipsis: true,
    render: (enterpriseId: number) => (
      <DevicesLink enterpriseId={enterpriseId} />
    )
  },
  {
    title: 'State',
    key: 'state',
    dataIndex: ['billingAddress', 'state', 'abbreviation'],
    width: 150,
    ellipsis: true
  },
  {
    title: 'Country',
    key: 'country',
    dataIndex: ['billingAddress', 'country'],
    width: 150,
    ellipsis: true,
    render: (country: string) => <div>{country}</div>
  },
  {
    title: 'Created At',
    key: 'createdAt',
    dataIndex: 'createdAt',
    width: 250,
    ellipsis: false,
    render: (createdAt: number) => (
      <div>{moment(createdAt).format(DATE_TIME_FORMAT.DEFAULT)}</div>
    )
  },
  {
    title: 'Industry',
    dataIndex: 'industry',
    render: (industry: Industry) => (
      <EnterpriseIndustryText industry={industry} />
    ),
    width: 200,
    ellipsis: true
  }
];

function BackOfficeEnterprisesTable({
  onPaginationChange
}: Pick<BackOfficeTableProps<Enterprise>, 'onPaginationChange'>): JSX.Element {
  const backOfficeEnterprisesTotal = useSelector(
    selectBackOfficeEnterprisesTotal,
    shallowEqual
  );
  const enterpriseId = useSelector(
    selectRouterQueryStringSelectedEnterprise,
    shallowEqual
  );

  const enterprises = useBackOfficeEnterprises();

  const rowIdToOnClickAction = useCallback(
    (id: number) =>
      appendValuesToQueryString({
        drawer: undefined,
        selectedEnterprise: id
      }),
    []
  );

  return (
    <BackOfficeTable<Enterprise>
      columns={columns}
      data={enterprises}
      total={backOfficeEnterprisesTotal}
      loadActionType={loadAction}
      rowIdToOnClickAction={rowIdToOnClickAction}
      onPaginationChange={onPaginationChange}
      emptyText="No enterprises found"
      selectedRowId={enterpriseId}
    />
  );
}

export default BackOfficeEnterprisesTable;
