import { AxiosError } from 'axios';
import { action } from 'typesafe-actions';

import Invitation, {
  Invitee,
  NewInvitation
} from 'models/back-office/invitation';

import BackOfficeInvitationActionType from './constants';

export const loadBackOfficeInvitations = () =>
  action(BackOfficeInvitationActionType.LOAD_BACK_OFFICE_INVITATIONS_REQUEST);

export const loadBackOfficeInvitationsSuccess = (payload: {
  total: number;
  data: Invitee[];
}) =>
  action(
    BackOfficeInvitationActionType.LOAD_BACK_OFFICE_INVITATIONS_SUCCESS,
    payload
  );

export const loadBackOfficeInvitationsFailure = (
  message: string,
  error?: AxiosError
) =>
  action(
    BackOfficeInvitationActionType.LOAD_BACK_OFFICE_INVITATIONS_FAILURE,
    { message, error },
    undefined,
    true
  );

export const loadBackOfficeUserInvitations = (email: string) =>
  action(
    BackOfficeInvitationActionType.LOAD_BACK_OFFICE_USER_INVITATIONS_REQUEST,
    { email }
  );

export const loadBackOfficeUserInvitationsSuccess = (invitee: Invitee) =>
  action(
    BackOfficeInvitationActionType.LOAD_BACK_OFFICE_USER_INVITATIONS_SUCCESS,
    {
      invitee
    }
  );

export const loadBackOfficeUserInvitationsFailure = (
  message: string,
  error: AxiosError
) =>
  action(
    BackOfficeInvitationActionType.LOAD_BACK_OFFICE_USER_INVITATIONS_FAILURE,
    { message, error },
    undefined,
    true
  );

export const addBackOfficeInvitation = (
  newInvitation: NewInvitation,
  onSuccess?: () => void
) =>
  action(BackOfficeInvitationActionType.ADD_BACK_OFFICE_INVITATION_REQUEST, {
    newInvitation,
    onSuccess
  });

export const addBackOfficeInvitationSuccess = (invitation: Invitation) =>
  action(BackOfficeInvitationActionType.ADD_BACK_OFFICE_INVITATION_SUCCESS, {
    invitation
  });

export const addBackOfficeInvitationFailure = (
  message: string,
  error: AxiosError
) =>
  action(
    BackOfficeInvitationActionType.ADD_BACK_OFFICE_INVITATION_FAILURE,
    { message, error },
    undefined,
    true
  );

export const removeBackOfficeInvitation = (
  invitationId: number,
  onSuccess: () => void
) =>
  action(BackOfficeInvitationActionType.REMOVE_BACK_OFFICE_INVITATION_REQUEST, {
    invitationId,
    onSuccess
  });

export const removeBackOfficeInvitationSuccess = (
  invitationId: number,
  email: string
) =>
  action(BackOfficeInvitationActionType.REMOVE_BACK_OFFICE_INVITATION_SUCCESS, {
    invitationId,
    email
  });

export const removeBackOfficeInvitationFailure = (
  message: string,
  error: AxiosError
) =>
  action(
    BackOfficeInvitationActionType.REMOVE_BACK_OFFICE_INVITATION_FAILURE,
    { message, error },
    undefined,
    true
  );

export const resendBackOfficeInvitation = (invitationId: number) =>
  action(BackOfficeInvitationActionType.RESEND_BACK_OFFICE_INVITATION_REQUEST, {
    invitationId
  });

export const resendBackOfficeInvitationSuccess = () =>
  action(
    BackOfficeInvitationActionType.RESEND_BACK_OFFICE_INVITATION_SUCCESS,
    {}
  );

export const resendBackOfficeInvitationFailure = (
  message: string,
  error: AxiosError
) =>
  action(
    BackOfficeInvitationActionType.RESEND_BACK_OFFICE_INVITATION_FAILURE,
    { message, error },
    undefined,
    true
  );
