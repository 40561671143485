/**
 * The loading state selectors
 */

import get from 'lodash/get';
import { createSelector } from 'reselect';

import { ApplicationRootState } from 'redux/types';

const selectLoading = (state: ApplicationRootState) => state.loading;

const makeSelectIsLoading = (actions: string[]) =>
  createSelector(selectLoading, (loading) =>
    actions
      .map((a) => a.replace('_REQUEST', ''))
      .some((action) => get(loading, action))
  );

const makeSelectIsLoaded = (actions: string[]) =>
  createSelector(selectLoading, (loading) =>
    actions
      .map((a) => a.replace('_REQUEST', ''))
      .some((action) => loading[action] === false)
  );

export { selectLoading, makeSelectIsLoading, makeSelectIsLoaded };
