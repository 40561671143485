import { action } from 'typesafe-actions';

import ActionTypes from './constants';

export const removeLastSubPath = () => action(ActionTypes.REMOVE_LAST_SUB_PATH);

export const appendToExistingPath = (path: string) =>
  action(ActionTypes.APPEND_TO_EXISTING_PATH, path);

export const appendValuesToQueryString = (object: { [key: string]: unknown }) =>
  action(ActionTypes.APPEND_VALUES_TO_QUERY_STRING, object);

export const storeSearch = (pathname: string, search: string) =>
  action(ActionTypes.STORE_SEARCH, { pathname, search });
