/* eslint-disable func-names */
import antdMessage from 'antd/lib/message';
import { AxiosResponse } from 'axios';
import get from 'lodash/get';
import moment from 'moment-timezone';
import { Task } from 'redux-saga';
import {
  Effect,
  all,
  call,
  cancel,
  delay,
  fork,
  put,
  select,
  takeEvery,
  takeLatest
} from 'redux-saga/effects';
import { ActionCreator } from 'typesafe-actions';

import { emptyFilterDate, getDateKey } from 'components/features/camera/helpers';
import { DEFAULT_TIMEZONE_CODE, ONE_HOUR_IN_MS } from 'constants/time';
import {
  CameraDate,
  CameraImageStatus,
  CameraImageType,
  CameraStatus
} from 'models/camera';
import { SamplePointId } from 'models/samplePoint';
import {
  deleteRequest,
  getRequest,
  postRequest
} from 'utils/redux-saga-requests';

import {
  addCameraScheduleFailure,
  addCameraScheduleSuccess,
  addCameraSchedules,
  deleteCameraScheduleFailure,
  deleteCameraScheduleSuccess,
  deleteCameraSchedules,
  getCameraSchedules,
  getCameraSchedulesFailure,
  getCameraSchedulesSuccess,
  loadCameraImageDates,
  loadCameraImageDatesFailure,
  loadCameraImageDatesSuccess,
  loadCameraImages,
  loadCameraImagesFailure,
  loadCameraImagesSuccess,
  loadCameraStatus,
  loadCameraStatusFailure,
  loadCameraStatusSuccess,
  revokePage,
  takePhoto,
  takePhotoFailure,
  takePhotoSuccess
} from './actions';
import ActionTypes from './constants';
import { makeSelectCameraStatus } from './selectors';
import { CameraScheduleResponse, CamerasState, DateKey } from './types';
import { MachineControlActionRequestResponse } from '../controlPoints/types';
import { makeSelectSamplePointById } from '../samplePoints/selectors';

type RequestGenerator<TNext, TReturn = AxiosResponse<TNext>> = Generator<
  Effect,
  TReturn,
  AxiosResponse<TNext>
>;
type RequestGeneratorFunction<
  TNext,
  TReturn = AxiosResponse<TNext>
> = () => RequestGenerator<TNext, TReturn>;

/**
 * Performs the boilerplate error handling of our request generators.
 */
function* requestGenerator<T>(
  id: number,
  generator: RequestGeneratorFunction<T, void>,
  failureActionCreator: ActionCreator
) {
  try {
    yield* generator();
  } catch (error) {
    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );

    yield put(failureActionCreator(id, message, error));
  }
}

const imageDatePageTasks: Record<
  SamplePointId,
  Record<string, Record<number, Task>>
> = {};

/**
 * The Image urls we are given by the backend expire after 1 hour.
 * Therefore, we must remove that page from our store after then.
 */
function* addRevokePageTask(
  samplePointId: SamplePointId,
  page: number,
  date: DateKey
): Generator<any, any, any> {
  if (!imageDatePageTasks[samplePointId]) {
    imageDatePageTasks[samplePointId] = {};
  }

  const datePageTasks = imageDatePageTasks[samplePointId];

  if (!datePageTasks[date]) {
    datePageTasks[date] = {};
  }

  const pageTasks = datePageTasks[date];

  // If we have an existing task for this page, cancel it.
  // We will replace it with a new one.
  if (pageTasks[page]) {
    yield cancel(pageTasks[page]);
  }

  // Store a task that will wait 1 hour, and then revoke the given page.
  pageTasks[page] = yield fork(function* () {
    yield delay(ONE_HOUR_IN_MS);
    yield put(revokePage(samplePointId, date, page));
  });
}

function* requestCameraImages({
  payload: { samplePointId, params }
}: ReturnType<typeof loadCameraImages>) {
  const samplePoint = yield select(makeSelectSamplePointById(samplePointId));
  const timezoneCode = samplePoint.siteTimezoneCode || DEFAULT_TIMEZONE_CODE;

  const { startDateMs, endDateMs, ...restParams } = params;
  const startDateInSiteTimezone = startDateMs ? moment(startDateMs).tz(timezoneCode) : undefined;
  const endDateInSiteTimezone = endDateMs ? moment(endDateMs).tz(timezoneCode) : undefined;

  yield* requestGenerator<{ data: CameraImageType[]; count: number }>(
    samplePointId,
    function* () {
      const {
        data: { data: images, count }
      } = yield call(getRequest, `camera/${samplePointId}/image`, {
        params: {
          ...restParams,
          startDate: startDateInSiteTimezone?.format(),
          endDate: endDateInSiteTimezone?.format()
        }
      });

      const { page } = params;
      const dateKey = startDateMs ? getDateKey(startDateMs, timezoneCode) : emptyFilterDate;

      yield* addRevokePageTask(samplePointId, page, dateKey);

      yield put(
        loadCameraImagesSuccess(
          samplePointId,
          {
            images,
            count
          },
          page,
          dateKey
        )
      );
    },
    loadCameraImagesFailure
  );
}

function* requestCameraImageDates({
  payload: { samplePointId }
}: ReturnType<typeof loadCameraImageDates>) {
  const samplePoint = yield select(makeSelectSamplePointById(samplePointId));
  const timezoneCode = samplePoint.siteTimezoneCode || DEFAULT_TIMEZONE_CODE;

  yield* requestGenerator<CameraDate[]>(
    samplePointId,
    function* () {
      const { data } = yield call(getRequest, `camera/${samplePointId}/image`, {
        params: { createdByOnly: true }
      });

      yield put(loadCameraImageDatesSuccess(samplePointId, data, timezoneCode));
    },
    loadCameraImageDatesFailure
  );
}

function* requestCameraStatus({
  payload: { samplePointId }
}: ReturnType<typeof loadCameraStatus>) {
  const previousCameraStatus:
    | CamerasState[SamplePointId]['status']
    | undefined = yield select(makeSelectCameraStatus(samplePointId));

  yield* requestGenerator<CameraImageType>(
    samplePointId,
    function* () {
      const {
        data: { status, statusCode }
      } = yield call(getRequest, `camera/${samplePointId}/status`);

      // If the camera was processing but is now ready, fetch the new image.
      if (
        previousCameraStatus &&
        previousCameraStatus.status === CameraStatus.PROCESSING &&
        status === CameraImageStatus.READY
      ) {
        yield put(loadCameraImages(samplePointId, { page: 1 }) as any);
      }

      yield put(loadCameraStatusSuccess(samplePointId, status, statusCode));
    },
    loadCameraStatusFailure
  );
}

function* requestTakePhoto({
  payload: { samplePointId, settings }
}: ReturnType<typeof takePhoto>) {
  yield call(requestCameraStatus, loadCameraStatus(samplePointId));

  const cameraStatus: CamerasState[SamplePointId]['status'] | undefined =
    yield select(makeSelectCameraStatus(samplePointId));

  // Don't take a photo if there's already one in progress.
  if (cameraStatus?.status === CameraStatus.PROCESSING) {
    return;
  }

  const samplePoint = yield select(makeSelectSamplePointById(samplePointId));
  const timezoneCode = samplePoint.siteTimezoneCode || DEFAULT_TIMEZONE_CODE;

  yield* requestGenerator<MachineControlActionRequestResponse>(
    samplePointId,
    function* () {
      const { data } = yield call(
        postRequest,
        `camera/${samplePointId}/take-photo`,
        settings
      );

      yield put(takePhotoSuccess(samplePointId, data, timezoneCode));
    },
    takePhotoFailure
  );
}

function* requestCameraSchedules({
  payload: { samplePointId }
}: ReturnType<typeof getCameraSchedules>) {
  yield* requestGenerator<CameraScheduleResponse[]>(
    samplePointId,
    function* () {
      const response = yield call(
        getRequest,
        `samplepoint-scheduler/${samplePointId}`
      );

      yield put(getCameraSchedulesSuccess(samplePointId, response.data));
    },
    getCameraSchedulesFailure
  );
}

function* requestAddCameraSchedule({
  payload: { samplePointId, requests }
}: ReturnType<typeof addCameraSchedules>) {
  yield* requestGenerator(
    samplePointId,
    function* () {
      const responses = yield all(
        requests.map((request) =>
          call(postRequest, `samplepoint-scheduler/${samplePointId}`, {
            ...request
          })
        )
      );

      yield put(
        addCameraScheduleSuccess(
          samplePointId,
          (responses as any).map(
            ({ data }: { data: CameraScheduleResponse }) => data
          )
        )
      );
      antdMessage.success('Created schedule successfully');
    },
    addCameraScheduleFailure
  );
}

function* requestDeleteCameraSchedule({
  payload: { samplePointId, scheduleIds }
}: ReturnType<typeof deleteCameraSchedules>) {
  yield* requestGenerator<void>(
    samplePointId,
    function* () {
      yield call(
        deleteRequest,
        `samplepoint-scheduler/${samplePointId}/schedule/bulk-action`,
        { ids: scheduleIds }
      );

      yield put(deleteCameraScheduleSuccess(samplePointId, scheduleIds));
      antdMessage.success('Removed schedule successfully');
    },
    deleteCameraScheduleFailure
  );
}

function* watchCameraImagesRequest() {
  yield takeEvery(ActionTypes.LOAD_CAMERA_IMAGES_REQUEST, requestCameraImages);
}

function* watchCameraStatusRequest() {
  yield takeEvery(ActionTypes.LOAD_CAMERA_STATUS_REQUEST, requestCameraStatus);
}

function* watchCameraImageDatesRequest() {
  yield takeEvery(
    ActionTypes.LOAD_CAMERA_IMAGE_DATES_REQUEST,
    requestCameraImageDates
  );
}

function* watchTakePhotoRequest() {
  yield takeLatest(ActionTypes.TAKE_PHOTO_REQUEST, requestTakePhoto);
}

function* watchGetCameraSchedulesRequest() {
  yield takeEvery(
    ActionTypes.GET_CAMERA_SCHEDULES_REQUEST,
    requestCameraSchedules
  );
}

function* watchAddCameraScheduleRequest() {
  yield takeEvery(
    ActionTypes.ADD_CAMERA_SCHEDULE_REQUEST,
    requestAddCameraSchedule
  );
}

function* watchDeleteCameraScheduleRequest() {
  yield takeEvery(
    ActionTypes.DELETE_CAMERA_SCHEDULE_REQUEST,
    requestDeleteCameraSchedule
  );
}

export default function* camerasSaga() {
  yield all([
    fork(watchCameraImagesRequest),
    fork(watchCameraStatusRequest),
    fork(watchCameraImageDatesRequest),
    fork(watchTakePhotoRequest),
    fork(watchGetCameraSchedulesRequest),
    fork(watchAddCameraScheduleRequest),
    fork(watchDeleteCameraScheduleRequest)
  ]);
}
