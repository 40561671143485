/**
 * Request information about the current user
 */

import { message as antdMessage } from 'antd';
import { AxiosError, AxiosResponse } from 'axios';
import parsedPhoneNumber from 'libphonenumber-js';
import get from 'lodash/get';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import User from 'models/user';
import parseBackendPhoneNumber from 'utils/phone-number/parse-backend-phone-number';
import { getRequest, patchRequest } from 'utils/redux-saga-requests';

import {
  editMyUser,
  editMyUserFailure,
  editMyUserSuccess,
  loadMyUserFailure,
  loadMyUserSuccess,
  mergeMyUser
} from './actions';
import ActionTypes from './constants';
import { selectMyUser } from './selectors';

export function* requestMyUser() {
  const user: User = yield select(selectMyUser);
  try {
    const response: AxiosResponse = yield call(getRequest, `user/${user.id}`, {
      params: {
        join: [
          'memberships',
          'memberships.enterprise',
          'memberships.enterprise.billingAddress',
          'memberships.enterprise.billingAddress.state',
          'memberships.enterprise.billingAddress.state.country',
          'memberships.enterprise.enterpriseIntegration'
        ],
        sort: 'memberships.enterprise.name,ASC'
      }
    });
    const { data } = response;
    let myUser = parseBackendPhoneNumber(
      data,
      'mobileNumber',
      'mobileNumberCountryCode',
      'mobileNumberFormatted'
    );
    myUser = parseBackendPhoneNumber(
      myUser,
      'landlineNumber',
      'landlineNumberCountryCode',
      'landlineNumberFormatted'
    );
    yield all([put(mergeMyUser(myUser)), put(loadMyUserSuccess(response))]);
  } catch (error) {
    const message = get(
      error,
      'response.data.message',
      'Sorry, please sign in again.'
    );
    yield put(loadMyUserFailure(message, error as AxiosError));
  }
}

export function* watchLoadMyUserRequest() {
  yield takeLatest(ActionTypes.LOAD_MY_USER_REQUEST, requestMyUser);
}

export function* requestEditMyUser(action: ReturnType<typeof editMyUser>) {
  const user: User = yield select(selectMyUser);

  const {
    payload: {
      title,
      firstName,
      lastName,
      username,
      mobileNumberCountryCode,
      mobileNumber,
      landlineNumberCountryCode,
      landlineNumber,
      enableSmsNotifications,
      email,
      enableEmailNotifications
    }
  } = action;
  try {
    const mobileNumberValue = {
      mobileNumber:
        mobileNumber && mobileNumberCountryCode
          ? parsedPhoneNumber(`${mobileNumberCountryCode}${mobileNumber}`)
              ?.number || null
          : null
    };
    const landlineNumberValue = {
      landlineNumber:
        landlineNumber && landlineNumberCountryCode
          ? parsedPhoneNumber(`${landlineNumberCountryCode}${landlineNumber}`)
              ?.number || null
          : null
    };
    const response: AxiosResponse = yield call(
      patchRequest,
      `user/${user.id}`,
      {
        title,
        firstName,
        lastName,
        username,
        mobileNumberCountryCode: mobileNumberCountryCode || null,
        // Pass phone number in the format needed by backend.
        ...mobileNumberValue,
        landlineNumberCountryCode: landlineNumberCountryCode || null,
        // Pass phone number in the format needed by backend.
        ...landlineNumberValue,
        email: email || null,
        enableEmailNotifications,
        enableSmsNotifications
      }
    );
    const { data } = response;
    yield all([
      put(
        mergeMyUser(
          parseBackendPhoneNumber(
            data,
            'mobileNumber',
            'mobileNumberCountryCode',
            'mobileNumberFormatted'
          )
        )
      ),
      put(editMyUserSuccess(response))
    ]);

    antdMessage.success('Profile settings updated');
  } catch (error) {
    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );
    yield put(editMyUserFailure(message, error as AxiosError));
  }
}

export function* watchEditMyUserRequest() {
  yield takeLatest(ActionTypes.EDIT_MY_USER_REQUEST, requestEditMyUser);
}

export default function* myUserSaga() {
  yield all([fork(watchLoadMyUserRequest), fork(watchEditMyUserRequest)]);
}
