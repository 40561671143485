import AuthActionTypes from 'redux/modules/auth/constants';
import { ApplicationActions } from 'redux/types';

import ActionTypes from './constants';
import { SamplePointsState } from './types';

export const initialState: SamplePointsState = {};

function samplePointsReducer(
  state: SamplePointsState = initialState,
  action: ApplicationActions
): SamplePointsState {
  switch (action.type) {
    case ActionTypes.SET_SAMPLE_POINTS:
      return action.payload;
    case ActionTypes.SET_SAMPLE_POINT: {
      const { payload } = action;
      const { id } = payload;
      return {
        ...state,
        [id]: {
          ...state[id],
          ...payload
        }
      };
    }
    case ActionTypes.REMOVE_SAMPLE_POINT: {
      const { payload } = action;
      const { ids } = payload;
      const newState = { ...state };
      ids.forEach((id: number) => delete newState[id]);
      return newState;
    }
    case AuthActionTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export default samplePointsReducer;
