import groupBy from 'lodash/groupBy';
import keyBy from 'lodash/keyBy';
import orderBy from 'lodash/orderBy';
import { createSelector } from 'reselect';

import { CountryId } from 'models/country';
import { StateAbbreviation } from 'models/state';
import { ApplicationRootState } from 'redux/types';

const selectStates = (state: ApplicationRootState) => state.states;

const selectStatesAsArray = createSelector(selectStates, (states) =>
  orderBy(Object.values(states), 'name')
);

const selectStatesGroupedByCountryId = createSelector(
  selectStatesAsArray,
  (statesAsArray) => groupBy(statesAsArray, 'countryId')
);

const makeSelectStateByAbbreviation = (stateAbbreviation: StateAbbreviation) =>
  createSelector(
    selectStates,
    (states) => keyBy(Object.values(states), 'abbreviation')[stateAbbreviation]
  );

const makeSelectStatesByCountryId = (countryId: CountryId | undefined) =>
  createSelector(selectStatesGroupedByCountryId, (statesGroupedByCountryId) =>
    countryId ? statesGroupedByCountryId[countryId] : undefined
  );

export {
  selectStates,
  selectStatesAsArray,
  selectStatesGroupedByCountryId,
  makeSelectStateByAbbreviation,
  makeSelectStatesByCountryId
};
