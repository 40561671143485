import { AxiosError, AxiosResponse } from 'axios';
import { action } from 'typesafe-actions';

import Enterprise, { EnterpriseId } from 'models/enterprise';

import ActionTypes from './constants';

export const loadBackOfficeEnterprise = (enterpriseId: EnterpriseId) =>
  action(ActionTypes.LOAD_BACK_OFFICE_ENTERPRISE_REQUEST, { enterpriseId });

export const loadBackOfficeEnterpriseSuccess = (response: AxiosResponse) =>
  action(ActionTypes.LOAD_BACK_OFFICE_ENTERPRISE_SUCCESS, { response });

export const loadBackOfficeEnterpriseFailure = (
  message: string,
  error?: AxiosError
) =>
  action(
    ActionTypes.LOAD_BACK_OFFICE_ENTERPRISE_FAILURE,
    { message, error },
    undefined,
    true
  );

export const setBackOfficeEnterprise = (enterprise: Enterprise | null) =>
  action(ActionTypes.SET_BACK_OFFICE_ENTERPRISE, { enterprise });
