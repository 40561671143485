import React from 'react';

import BackOfficeEnterpriseCreateButton from 'components/buttons/BackOfficeEnterpriseCreateButton';
import BackOfficeEnterpriseCreateDrawer from 'components/drawers/BackOfficeEnterpriseCreateDrawer';
import BackOfficeEnterpriseEditDrawer from 'components/drawers/BackOfficeEnterpriseEditDrawer';
import BackOfficeFilterDrawer from 'components/drawers/BackOfficeFilterDrawer';
import BackOfficeSection from 'components/features/backOffice/BackOfficeSection';
import BackOfficeEnterpriseFilterForm from 'components/forms/BackOfficeEnterpriseFilterForm';
import BackOfficeEnterprisesRequest from 'components/requests/BackOfficeEnterprisesRequest';
import CountriesRequest from 'components/requests/CountriesRequest';
import BackOfficeEnterprisesTable from 'components/tables/BackOfficeEnterprisesTable';
import { FilterSetName } from 'redux/modules/routerUtils/types';

function BackOfficeEnterprises(): JSX.Element {
  return (
    <BackOfficeSection
      actions={<BackOfficeEnterpriseCreateButton />}
      table={<BackOfficeEnterprisesTable />}
      filterSetName={FilterSetName.BACK_OFFICE_ENTERPRISES}
      searchInputPlaceholder="Search by Name"
    >
      <BackOfficeEnterprisesRequest />
      <CountriesRequest />
      <BackOfficeEnterpriseCreateDrawer />
      <BackOfficeEnterpriseEditDrawer />
      <BackOfficeFilterDrawer
        filterSetName={FilterSetName.BACK_OFFICE_ENTERPRISES}
      >
        <BackOfficeEnterpriseFilterForm />
      </BackOfficeFilterDrawer>
    </BackOfficeSection>
  );
}

export default BackOfficeEnterprises;
