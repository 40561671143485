import { DeleteFilled } from '@ant-design/icons';
import { Col, Form, Input, Row, Space, Typography } from 'antd';
import classNames from 'classnames';
import React, { memo, useContext } from 'react';

import DefaultBackendSearchSelect from 'components/composites/DefaultBackendSearchSelect';
import { FormContext } from 'components/composites/DefaultForm';
import DefaultFormItem from 'components/composites/DefaultFormItem';
import EnterpriseLink from 'components/composites/EnterpriseLink';
import { BackOfficeInvitationFormItems } from 'components/forms/BackOfficeInvitationForm/BackOfficeInvitationFormItems';
import ContactFormItems from 'components/forms/ContactFormItems';
import FieldValue from 'components/forms/FieldValue';
import FormCheckbox from 'components/forms/FormCheckbox';
import FormItemCol from 'components/forms/FormItemCol';
import FormItemRole from 'components/forms/FormItemRole';
import Enterprise from 'models/enterprise';
import User from 'models/user';
import { SearchTypes } from 'redux/modules/search/types';

import './styles.less';

const formCheckboxStyling = { marginTop: '-10px', marginBottom: '-10px' };

interface Props {
  /** Use responsive column width */
  isFullSpanOnMobileHalfOnDesktop?: boolean;
  readOnly?: boolean;
  initialValues?: Partial<User>;
}

function BackOfficeUserFormItems({
  isFullSpanOnMobileHalfOnDesktop,
  readOnly,
  initialValues
}: Props) {
  const { formInstance } = useContext(FormContext);

  return (
    <Row gutter={24}>
      <FormItemCol
        isFullSpanOnMobileHalfOnDesktop={isFullSpanOnMobileHalfOnDesktop}
      >
        <DefaultFormItem
          name="username"
          label="Username"
          rules={[
            {
              required: true,
              message: 'Please enter a username'
            }
          ]}
        >
          <Input />
        </DefaultFormItem>
      </FormItemCol>

      <FieldValue name="enableSmsNotifications">
        {(enableSmsNotifications) => (
          <ContactFormItems
            isFullSpanOnMobileHalfOnDesktop={isFullSpanOnMobileHalfOnDesktop}
            readOnly={readOnly}
            initialValues={initialValues}
            phoneRequired={!!enableSmsNotifications}
          />
        )}
      </FieldValue>
      <FieldValue name="isSuperAdmin">
        {(userIsSuperAdmin) => (
          <>
            <Col span={24}>
              <Typography.Text strong>Memberships</Typography.Text>
            </Col>
            {userIsSuperAdmin && (
              <Col span={24}>
                <FormCheckbox
                  fieldName="isSuperAdmin"
                  label="Super Admin"
                  style={{ margin: 0 }}
                />
              </Col>
            )}
            {/*
            TODO: Now that we have updated Ant Design, Form.List can take
            'initialValues' & 'rules' as props. These may be needed, or make
            the current logic clearer, when we replace
            DefaultBackendSearchSelect with Select.
          */}
            <Form.List name="memberships">
              {(fields: any[], { remove }: any) =>
                fields.length ? (
                  fields.map(
                    ({ key, name: index }: { key: number; name: number }) => {
                      const handleMenuItemClick = (enterprise: Enterprise) => {
                        // Get membership being edited.
                        const values = formInstance?.getFieldsValue();
                        const { memberships } = values;
                        // const index = (index as unknown) as number;
                        const membership = memberships[index];
                        // Set new enterprise ID, remove enterprise object from
                        // membership.
                        membership.enterpriseId = enterprise.id;
                        membership.enterprise = enterprise;
                        // delete membership ID if present.
                        delete membership.id;

                        // Add edited membership back to form.
                        memberships[index] = membership;
                        formInstance?.setFieldsValue({ memberships });
                      };
                      return (
                        <React.Fragment key={key}>
                          <Col
                            span={2}
                            style={{ marginTop: 13 }}
                          >
                            <FieldValue
                              name={[
                                'memberships',
                                index,
                                'enterprise',
                                'subdomain'
                              ]}
                            >
                              {(subdomain) => (
                                <EnterpriseLink
                                  disabled={!subdomain}
                                  enterprise={{ subdomain } as any}
                                />
                              )}
                            </FieldValue>
                          </Col>
                          <Col
                            span={11}
                            className={classNames('memberships', {
                              initial: key
                            })}
                          >
                            <DefaultFormItem>
                              <FormItemCol
                                isFullSpanOnMobileHalfOnDesktop={
                                  isFullSpanOnMobileHalfOnDesktop
                                }
                              >
                                <FieldValue
                                  name={[
                                    'memberships',
                                    index,
                                    'enterprise',
                                    'name'
                                  ]}
                                >
                                  {(enterpriseName) => (
                                    <DefaultBackendSearchSelect
                                      disabled={userIsSuperAdmin as boolean}
                                      placeholder="Enterprise"
                                      initialValue={enterpriseName as string}
                                      onItemSelect={handleMenuItemClick}
                                      searchType={
                                        SearchTypes.
                                          UserEnterpriseMembershipSearch
                                      }
                                      labelProperty="name"
                                      name={[index, 'enterpriseId']}
                                      fieldKey={[key, 'enterpriseId']}
                                      readOnly={readOnly}
                                      rules={[
                                        {
                                          required: !userIsSuperAdmin,
                                          message:
                                            'Please enter an enterprise.'
                                        }
                                      ]}
                                    />
                                  )}
                                </FieldValue>
                              </FormItemCol>
                            </DefaultFormItem>
                          </Col>

                          <Col
                            span={9}
                            className={classNames('memberships', {
                              initial: key
                            })}
                          >
                            <FormItemRole
                              label=""
                              name={[index, 'role']}
                              fieldKey={[key, 'role']}
                              readOnly={readOnly}
                              rules={[
                                {
                                  required: !userIsSuperAdmin,
                                  message: 'Please enter a role.'
                                }
                              ]}
                              disabled={userIsSuperAdmin as boolean}
                            />
                          </Col>
                          {/* We allow deletion of all memberships for users */}
                          <Col span={1}>
                            <DeleteFilled
                              onClick={() => remove(index)}
                              className={classNames('membershipBin', {
                                initialBin: !key
                              })}
                            />
                          </Col>
                        </React.Fragment>
                      );
                    }
                  )
                ) : (
                  <div
                    style={{
                      width: '100%',
                      textAlign: 'center',
                      margin: '16px'
                    }}
                  >
                    No Memberships
                  </div>
                )
              }
            </Form.List>
            <Col span={24}>
              <FieldValue name="memberships">
                {(memberships) => (
                  <BackOfficeInvitationFormItems
                    disabled={userIsSuperAdmin as boolean}
                  />
                )}
              </FieldValue>
            </Col>
          </>
        )}
      </FieldValue>
      <Col span={24}>
        <Space direction="vertical">
          <Typography.Text strong>Notifications Preferences</Typography.Text>
          <Typography.Text>Send alert notifications via</Typography.Text>
          <FormCheckbox
            fieldName="enableEmailNotifications"
            label="Email"
            style={formCheckboxStyling}
          />
          <FormCheckbox
            fieldName="enableSmsNotifications"
            label="SMS"
            style={formCheckboxStyling}
          />
        </Space>
      </Col>
    </Row>
  );
}

export default memo(BackOfficeUserFormItems);
