import { Form } from 'antd';
import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BackOfficeDrawer from 'components/drawers/BackOfficeDrawer';
import BackOfficeEnterpriseForm from 'components/forms/BackOfficeEnterpriseForm';
import BackOfficeEnterpriseLoadingGate from 'components/loadingGates/BackOfficeEnterpriseLoadingGate';
import BackOfficeEnterpriseRequest from 'components/requests/BackOfficeEnterpriseRequest';
import useBackOfficeEnterprise from 'redux/modules/backOfficeEnterprise/hooks';
import { editBackOfficeEnterprise } from 'redux/modules/backOfficeEnterprises/actions';
import clearErrors from 'redux/modules/error/actions';
import { makeSelectIsLoading } from 'redux/modules/loading/selectors';
import { appendValuesToQueryString } from 'redux/modules/routerUtils/actions';
import { selectRouterQueryStringSelectedEnterprise } from 'redux/modules/routerUtils/selectors';

const actions = ['EDIT_BACK_OFFICE_ENTERPRISE'];

const selectIsLoading = makeSelectIsLoading(actions);

function BackOfficeEnterpriseEditDrawer(): JSX.Element | null {
  const [form] = Form.useForm();
  const enterpriseId = useSelector(selectRouterQueryStringSelectedEnterprise);
  const isLoading = useSelector(selectIsLoading);

  const visible = !!enterpriseId;
  const backOfficeEnterpriseData = useBackOfficeEnterprise();
  let billingAddressId: number | undefined;
  if (backOfficeEnterpriseData)
    billingAddressId = backOfficeEnterpriseData.billingAddressId;

  const dispatch = useDispatch();

  useEffect(() => {
    if (visible) dispatch(clearErrors(actions));
  }, [dispatch, visible]);

  const handleClose = () => {
    dispatch(
      appendValuesToQueryString({
        selectedEnterprise: undefined
      })
    );
  };

  const handleClick = () => {
    form
      .validateFields()
      .then((values) => {
        if (enterpriseId) {
          dispatch(
            editBackOfficeEnterprise(enterpriseId, {
              ...values,
              billingAddressId
            })
          );
        }
      })
      .catch(() => {
        // Ignoring promise rejection
      });
  };

  const handleFinish = useCallback(
    (values) => {
      if (enterpriseId) {
        dispatch(
          editBackOfficeEnterprise(enterpriseId, {
            ...values,
            billingAddressId
          })
        );
      }
    },
    [billingAddressId, dispatch, enterpriseId]
  );

  return (
    <BackOfficeDrawer
      visible={visible}
      backOfficeSection="Edit Enterprise"
      actions={actions}
      handleClick={handleClick}
      handleClose={handleClose}
    >
      <BackOfficeEnterpriseRequest enterpriseId={enterpriseId} />
      <BackOfficeEnterpriseLoadingGate enterpriseId={enterpriseId}>
        <BackOfficeEnterpriseForm
          form={form}
          initialValues={backOfficeEnterpriseData || undefined}
          onFinish={handleFinish}
          isLoading={isLoading}
        />
      </BackOfficeEnterpriseLoadingGate>
    </BackOfficeDrawer>
  );
}

export default memo(BackOfficeEnterpriseEditDrawer);
