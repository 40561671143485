import { Row, Typography, message as antdMessage } from 'antd';
import React, { useCallback, useState } from 'react';
import { FiTrash2 } from 'react-icons/fi';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import WarningModal from 'components/modals/WarningModal';
import Invitation, { InvitationId } from 'models/back-office/invitation';
import { UserRoleLabels } from 'models/membership';
import {
  removeBackOfficeInvitation,
  resendBackOfficeInvitation
} from 'redux/modules/backOfficeInvitations/actions';
import { makeSelectInvitationsByEmail } from 'redux/modules/backOfficeInvitations/selectors';
import { selectRouterQueryStringDrawer } from 'redux/modules/routerUtils/selectors';

import './styles.less';

interface BackOfficeUserInvitationCardProps {
  invitation: Invitation;
  resendInvitation: (id: InvitationId) => void;
  removeInvitation: (invitation: Invitation) => void;
}

function BackOfficeUserInvitationCard({
  invitation,
  resendInvitation,
  removeInvitation
}: BackOfficeUserInvitationCardProps) {
  return (
    <div className="BackOfficeUserInvitation">
      <div className="BackOfficeUserInvitation-top">
        <div>
          <div>
            {invitation.isSuperAdmin
              ? 'All enterprises'
              : invitation.enterprise?.name}
          </div>
          <div className="BackOfficeUserInvitation-subtitle">
            {invitation.isSuperAdmin
              ? 'Super-admin'
              : UserRoleLabels[invitation.role]}{' '}
            · Pending
          </div>
        </div>
        <FiTrash2 size={20} onClick={() => removeInvitation(invitation)} />
      </div>
      <div className="BackOfficeUserInvitation-footer">
        <div onClick={() => resendInvitation(invitation.id)}>Resend Invite</div>
      </div>
    </div>
  );
}

interface BackOfficeUserInvitationsProps {
  email?: string;
}

/** Invitation card list */
export default function BackOfficeUserInvitations({
  email
}: BackOfficeUserInvitationsProps) {
  const dispatch = useDispatch();
  const inCreateUserDrawer =
    useSelector(selectRouterQueryStringDrawer) === 'addUser';
  const invitations = useSelector(
    makeSelectInvitationsByEmail(email),
    shallowEqual
  );
  const [
    isRemoveConfirmationModalVisible,
    setIsRemoveConfirmationModalVisible
  ] = useState<boolean>(false);
  const [invitationToRemove, setInvitationToRemove] = useState<Invitation>();

  const resendInvitation = useCallback(
    (id: any) => dispatch(resendBackOfficeInvitation(id)),
    [dispatch]
  );
  const onRemove = useCallback((invitation: Invitation) => {
    setInvitationToRemove(invitation);
    setIsRemoveConfirmationModalVisible(true);
  }, []);
  const removeInvitation = useCallback(() => {
    if (invitationToRemove) {
      dispatch(
        removeBackOfficeInvitation(invitationToRemove.id, () => {
          setIsRemoveConfirmationModalVisible(false);
          antdMessage.success('Invitation removed successfully');
        })
      );
    }
  }, [dispatch, invitationToRemove]);

  const enterpriseName = invitationToRemove?.isSuperAdmin
    ? 'all enterprises'
    : invitationToRemove?.enterprise?.name;

  return (
    <div className="BackOfficeUserInvitations">
      <Typography.Text strong>Invitations</Typography.Text>
      {!inCreateUserDrawer && invitations && invitations.length
        ? invitations.map((invitation) => (
          <BackOfficeUserInvitationCard
            key={invitation.id}
            invitation={invitation}
            resendInvitation={resendInvitation}
            removeInvitation={onRemove}
          />
        ))
        : 'No invitations'}
      <WarningModal
        visible={isRemoveConfirmationModalVisible}
        title={`Are you sure you want to remove the invitation to ${enterpriseName}?`}
        onCancel={() => setIsRemoveConfirmationModalVisible(false)}
        onOk={() => removeInvitation()}
        okText="Remove"
      >
        <Row>The invitation link sent to their email will no longer work.</Row>
      </WarningModal>
    </div>
  );
}
