import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';

import { useStoredSearch } from 'redux/modules/routerUtils/hooks';

interface Props {
  to: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}

/** This NavLink will auto-append the search params stored in Redux to the URL */
function NavLinkWithStoredSearch({
  to,
  children,
  style,
  className
}: Props): JSX.Element | null {
  const storedSearch = useStoredSearch(to);

  return (
    <NavLink
      style={style}
      className={className}
      to={{
        pathname: to,
        search: storedSearch
      }}
    >
      {children}
    </NavLink>
  );
}

export default memo(NavLinkWithStoredSearch);
