import Form, { FormInstance, FormProps } from 'antd/lib/form';
import { NamePath } from 'antd/lib/form/interface';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef
} from 'react';

import { DrawerContext } from 'components/composites/DefaultDrawer';

interface FormContextValue {
  formInstance?: FormInstance;
}

export const FormContext = React.createContext<FormContextValue>({
  formInstance: undefined
});

interface Props extends FormProps {
  /**
   * If the form is inside a drawer, and this prop is true, the drawer will close
   * on successful form submission.
   * Have to set up the submission success callback in onFinish before using.
   */
  closeDrawerOnSubmit?: boolean;
  /**
   * Close the drawer when all fields are valid regardless the response from
   * BE.
   */
  closeDrawerIfFieldsAreValid?: boolean;
}

/**
 * Wraps the ant design form in a provider that allows us to access the form
 * instance from any child component.
 */
function DefaultForm<T = any>({
  children,
  form,
  initialValues,
  onValuesChange,
  onFinish,
  closeDrawerOnSubmit = false,
  closeDrawerIfFieldsAreValid = true,
  requiredMark = false,
  /** For scrollToFirstError to work, you need to make sure 1) the id of the
   * control is auto-passed from parent DefaultFormItem. 2) the submit button is
   * within the DefaultForm. 3) there is only one form on the page. */
  scrollToFirstError = true,
  ...rest
}: Props) {
  const [formInstance] = Form.useForm(form);

  // If form is inside a drawer, these will be available.
  const { setShouldPromptBeforeClose, setOnDiscardChanges, onClose } =
    useContext(DrawerContext);

  // If form is inside a drawer, reset the form when user confirms discarding
  // unsaved changes.
  useEffect(
    () => setOnDiscardChanges?.(() => formInstance.resetFields()),
    [setOnDiscardChanges, formInstance]
  );

  // If form is inside a drawer, call its 'onClose' callback on successful form
  // submission, if 'closeDrawerOnSubmit' is true.
  const handleDrawerOnSubmit = useCallback(() => {
    setShouldPromptBeforeClose?.(false);
    if (closeDrawerOnSubmit) {
      // Call onClose with 'true' to skip prompting the user about unsaved
      // changes.
      onClose?.(true);
    }
  }, [closeDrawerOnSubmit, onClose, setShouldPromptBeforeClose]);

  const handleOnValuesChange = useCallback(
    (changedValues: Partial<T>, values: T) => {
      setShouldPromptBeforeClose?.(true);
      onValuesChange?.(changedValues, values);
    },
    [onValuesChange, setShouldPromptBeforeClose]
  );

  const handleOnFinish = useCallback(
    (values: T) => {
      handleDrawerOnSubmit();
      onFinish?.(values);
    },
    [handleDrawerOnSubmit, onFinish]
  );

  // Obtain a reference to the original 'validateFields' method.
  const originalValidateFields = useRef(formInstance.validateFields);

  const contextValue = useMemo(() => {
    // TODO: Replace 'validateFields' with a method that calls the original method and
    // calls 'handleDrawerOnSubmit' if the promise returned resolves.
    // We mutate the original 'formInstance' so that the 'form' prop passed to
    // this component by it's parent also receives this new copy of
    // 'validateFields'.

    // @ts-ignore
    formInstance.validateFields = (nameList?: NamePath[]) => {
      return originalValidateFields.current(nameList).then((result) => {
        if (closeDrawerIfFieldsAreValid) handleDrawerOnSubmit();
        return result;
      });
    };

    return { formInstance };
  }, [formInstance, handleDrawerOnSubmit, closeDrawerIfFieldsAreValid]);

  return (
    <FormContext.Provider value={contextValue}>
      <Form<T>
        form={formInstance}
        initialValues={initialValues}
        onValuesChange={handleOnValuesChange}
        onFinish={handleOnFinish}
        requiredMark={requiredMark}
        scrollToFirstError={scrollToFirstError}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      >
        {/* TODO: this type issue should be resolved when we move to antd v5 */}
        {/* @ts-ignore */}
        {children}
      </Form>
    </FormContext.Provider>
  );
}

export default DefaultForm;
