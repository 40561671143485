import isEqual from 'lodash/isEqual';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BackOfficeDeviceEditDrawer from 'components/drawers/BackOfficeDeviceEditDrawer';
import BackOfficeFilterDrawer from 'components/drawers/BackOfficeFilterDrawer';
import BackOfficeSection from 'components/features/backOffice/BackOfficeSection';
import BackOfficeDeviceFilterForm from 'components/forms/BackOfficeDeviceFilterForm';
import BackOfficeDevicesRequest from 'components/requests/BackOfficeDevicesRequest';
import BackOfficeDevicesTable from 'components/tables/BackOfficeDevicesTable';
// Not implemented yet
// import BackOfficeDeviceBulkEdit from "components/BackOfficeDeviceBulkEdit";
import { selectBackOfficeDeviceDataSources } from 'redux/modules/backOfficeDevices/selectors';
import { appendValuesToQueryString } from 'redux/modules/routerUtils/actions';
import { makeSelectRouterQueryStringFilterSet } from 'redux/modules/routerUtils/selectors';
import { FilterSetName } from 'redux/modules/routerUtils/types';

function BackOfficeDevices(): JSX.Element {
  // Not implemented yet.
  // const [bulkEdit, setBulkEdit] = useState(false);

  const dispatch = useDispatch();

  const dataSources = useSelector(selectBackOfficeDeviceDataSources);

  const filterSet = useSelector(
    makeSelectRouterQueryStringFilterSet(FilterSetName.BACK_OFFICE_DEVICES),
    isEqual
  );

  // Keeps track of whether the default dataSourceFilter has been set yet.
  const [defaultDataSourceFilterSet, setDefaultDataSourceFilterSet] = useState(
    !!filterSet.dataSourceId
  );

  useEffect(() => {
    // This logic assumes there are two dataSource objects with
    // environments "Production" & "Staging"
    if (dataSources && !defaultDataSourceFilterSet) {
      const environment =
        process.env.REACT_APP_ENV === 'production' ? 'Production' : 'Staging';
      const dataSourceForEnvironment = Object.values(dataSources).find(
        (dataSource) => dataSource.environment === environment
      );
      if (dataSourceForEnvironment) {
        setDefaultDataSourceFilterSet(true);
        dispatch(
          appendValuesToQueryString({
            'filter.backOfficeDevices.dataSourceId':
              dataSourceForEnvironment.id
          })
        );
      }
    }
  }, [dataSources, dispatch, defaultDataSourceFilterSet]);

  return (
    <BackOfficeSection
      table={
        <BackOfficeDevicesTable
        // Not implemented yet
        // setBulkEdit={setBulkEdit}
        />
      }
      filterSetName={FilterSetName.BACK_OFFICE_DEVICES}
      searchInputPlaceholder="Search by Name/Serial #"
    >
      <BackOfficeDevicesRequest />
      <BackOfficeDeviceEditDrawer />
      <BackOfficeFilterDrawer filterSetName={FilterSetName.BACK_OFFICE_DEVICES}>
        <BackOfficeDeviceFilterForm />
      </BackOfficeFilterDrawer>
    </BackOfficeSection>
  );
}

export default BackOfficeDevices;
