export const DELAY_HELP_ARTICLE_LINK_FARMBOT =
  'https://intercom.help/farmbot-helpcentre/en/articles/5666871-what-does-monitor-status-delay-mean';
export const DELAY_HELP_ARTICLE_LINK_RANCHBOT =
  'https://intercom.help/ranchbot/en/articles/7944517-what-does-monitor-status-delay-mean';
export const HIBERNATION_HELP_ARTICLE_LINK_FARMBOT =
  'https://intercom.help/farmbot-helpcentre/en/articles/8482712-what-does-hibernation-status-mean';
export const HIBERNATION_HELP_ARTICLE_LINK_RANCHBOT =
  'https://intercom.help/ranchbot/en/articles/8482741-what-does-hibernation-status-mean';
export const WATER_TANK_ALERT_HELP_ARTICLE_LINK_RANCHBOT =
  'https://intercom.help/ranchbot/en/articles/6883500-how-to-set-up-water-tank-alerts-notifications';
export const WATER_TANK_ALERT_HELP_ARTICLE_LINK_FARMBOT =
  'https://intercom.help/farmbot-helpcentre/en/articles/5322576-how-to-set-up-alerts-notifications';
export const TROUGH_ALERT_HELP_ARTICLE_LINK_FARMBOT = 'https://intercom.help/farmbot-helpcentre/en/articles/5921494-how-to-set-up-your-trough-alerts-notifications';
export const TROUGH_ALERT_HELP_ARTICLE_LINK_RANCHBOT = 'https://intercom.help/ranchbot/en/articles/7985872-how-to-set-up-your-trough-alerts-notifications';
export const MUTE_DEVICE_HELP_ARTICLE_LINK_FARMBOT =
  'https://intercom.help/farmbot-helpcentre/en/articles/8819742-mute-further-delay-notifications-for-devices';
export const MUTE_DEVICE_HELP_ARTICLE_LINK_RANCHBOT =
  'https://intercom.help/ranchbot/en/articles/8828471-mute-further-delay-notifications-for-devices';
export const BATTERY_LOW_HELP_ARTICLE_LINK_FARMBOT = 'https://intercom.help/farmbot-helpcentre/en/articles/6870219-monitor-battery-low-notifications';
export const BATTERY_LOW_HELP_ARTICLE_LINK_RANCHBOT = 'https://intercom.help/ranchbot/en/articles/5702599-monitor-battery-low-notifications';
export const MONITOR_PORT_NUMBERS_HELP_LINK_FARMBOT = 'https://intercom.help/farmbot-helpcentre/en/articles/7043573-monitor-ports-numbers';
export const MONITOR_PORT_NUMBERS_HELP_LINK_RANCHBOT = 'https://intercom.help/ranchbot/en/articles/7945201-monitor-ports-numbers';
