import { Tooltip } from 'antd';
import invert from 'lodash/invert';
import mapValues from 'lodash/mapValues';
import moment from 'moment-timezone';
import React, { ReactNode } from 'react';
import { FiInfo } from 'react-icons/fi';

import { PAGE_SIZE } from 'constants/list';
import {
  CameraDate,
  CameraImageStatus,
  CameraImageType,
  CameraSettingsKeys,
  CameraStatus,
  RawCameraImageStatusCode
} from 'models/camera';
import {
  CameraScheduleResponse,
  DATE_KEY_FORMAT,
  DateKey
} from 'redux/modules/cameras/types';

const customerServiceEmailAddress = encodeURIComponent(
  'service@farmbot.com.au'
);
const emailSubject = encodeURIComponent('Request for more photos');
const emailBody = encodeURIComponent(
  'Hi Farmbot team,\n\nWe\'d like to add more data to our camera so that we can take more photos.\n\nThanks'
);
export const getMorePhotosEmailHref = `mailto:${customerServiceEmailAddress}?subject=${emailSubject}&body=${emailBody}`;

export const CAMERA_IMAGE_PAGE_SIZE = PAGE_SIZE.CAMERA_MEDIA_LIST;

export const STANDARD_PHOTO_SIZE_KB = 7;

export enum Day {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday
}

export const dayLabels: Record<Day, { option: string; list: string }> = {
  [Day.Sunday]: { option: 'Su', list: 'Sun' },
  [Day.Monday]: { option: 'Mo', list: 'Mon' },
  [Day.Tuesday]: { option: 'Tu', list: 'Tues' },
  [Day.Wednesday]: { option: 'We', list: 'Wed' },
  [Day.Thursday]: { option: 'Th', list: 'Thu' },
  [Day.Friday]: { option: 'Fri', list: 'Fri' },
  [Day.Saturday]: { option: 'Sa', list: 'Sat' }
};

export const cameraSettingsValuesToLabels: Record<
  CameraSettingsKeys,
  Record<string, string>
> = {
  zoom: {
    '1x': '1x',
    '2x': '2x',
    '4x': '4x',
    '8x': '8x',
    '12x': '12x'
  },
  imageQuality: {
    low: 'Low',
    standard: 'Standard',
    high: 'High'
  },
  imageColour: {
    colour: 'Colour',
    'black-and-white': 'B&W'
  }
};

export const cameraSettingsLabelsToValues = mapValues(
  cameraSettingsValuesToLabels,
  invert
);

export const cameraSettingsTitles: Record<CameraSettingsKeys, ReactNode> = {
  zoom: 'Zoom',
  imageQuality: (
    <>
      Quality{' '}
      <Tooltip title="Low (5KB), Standard (7KB), High (10KB)">
        <FiInfo size={18} />
      </Tooltip>
    </>
  ),
  imageColour: 'Colour'
};

export const cameraSettingsKeys: Array<CameraSettingsKeys> = [
  'zoom',
  'imageQuality',
  'imageColour'
];

export const getDateTimeStringForSchedule = ({
  hourTZ,
  minuteTZ
}: CameraScheduleResponse) => `${hourTZ}:${minuteTZ}`;

export const getDateTimeConfigStringForSchedule = ({
  hourTZ,
  minuteTZ,
  config
}: CameraScheduleResponse) =>
  `${getDateTimeStringForSchedule({
    hourTZ,
    minuteTZ
  } as CameraScheduleResponse)}-${JSON.stringify(config)}`;

export function parseCameraStatus(rawStatus: CameraImageStatus): CameraStatus {
  switch (rawStatus) {
    case CameraImageStatus.ERROR:
      return CameraStatus.ERROR;
    case CameraImageStatus.REQUESTED:
    case CameraImageStatus.PROCESSING:
      return CameraStatus.PROCESSING;
    case CameraImageStatus.READY:
    default:
      return CameraStatus.STANDBY;
  }
}

export const emptyFilterDate = undefined as unknown as DateKey;

export function getDateKey(input: number, timezone: string): DateKey {
  return moment(input).tz(timezone).format(DATE_KEY_FORMAT);
}

export function getPageNumberForImageIndex(imageIndex: number) {
  return Math.max(1, Math.ceil((imageIndex + 1) / CAMERA_IMAGE_PAGE_SIZE));
}

/**
 * Given timestamps of camera images, returns a map of which dates (DATE_KEY_FORMAT in local timezone)
 * the images are available
 */
export function getImageAvailableDates(dates: CameraDate[], timezoneCode: string): Record<DateKey, boolean> {
  return dates.reduce(
    (acc, { createdAt }) => ({
      ...acc,
      [getDateKey(createdAt, timezoneCode)]: true
    }), {});
}

export function takenBy(createdBy: CameraImageType['createdBy']) {
  return createdBy && createdBy.firstName && createdBy.lastName ? (
    `Taken by: ${createdBy?.firstName || ''} ${createdBy?.lastName || ''}`
  ) : (
    <>&nbsp;</>
  );
}

export function parseRawCameraImageStatus(
  rawCameraImageStatus?: CameraImageType['statusCode']
) {
  switch (rawCameraImageStatus) {
    case RawCameraImageStatusCode.MS_POSTED_TO_GATEWAY:
      return 'Awaiting satellite comms.';
    case RawCameraImageStatusCode.MS_POSTED_TO_GATEWAY_FAILED:
      return 'An error occurred capturing the photo.';
    case RawCameraImageStatusCode.MS_REQ_DELIVERED_TO_MONITOR:
      return 'Monitor processing request.';
    case RawCameraImageStatusCode.MS_BLOCK_ASSEMBLY_IN_PROGRESS:
      return 'Work in progress.';
    case RawCameraImageStatusCode.MS_PAYLOAD_DELIVERED:
      return 'Photo arrived.';
    case RawCameraImageStatusCode.MS_PAYLOAD_INCOMPLETE:
      return 'Incomplete photo.';
    case RawCameraImageStatusCode.MS_DELIVERY_FAILED:
      return 'Communications failed.';
    case RawCameraImageStatusCode.MS_EVENT_RECEIVED_SUCCESS:
      return 'Photo available.';
    case RawCameraImageStatusCode.MS_COMMS_ERROR:
      return 'Communications error.';
    case RawCameraImageStatusCode.MS_MONITOR_CAMERA_ACTIVE:
      return 'Camera Active.';
    case RawCameraImageStatusCode.MS_MONITOR_CAMERA_FAILED_TO_RESPOND:
      return 'Camera failed to respond.';
    case RawCameraImageStatusCode.MS_MONITOR_CAMERA_FAILED_WHILE_COMPOSING_IMAGE:
      return 'Camera failed during photo compose.';
    case RawCameraImageStatusCode.MS_MONITOR_CAMERA_FAILED_DURING_TRANSFER:
      return 'Camera failed during photo transfer.';
    // We don't have specific errors for the following errors:
    case RawCameraImageStatusCode.MS_MONITOR_CAMERA_FAILED_MAX_TIMEOUT:
    case RawCameraImageStatusCode.MS_MONITOR_CAMERA_SD_CARD_MOUNT_ERROR:
    case RawCameraImageStatusCode.MS_MONITOR_CAMERA_SD_CARD_READ_ERROR:
    case RawCameraImageStatusCode.MS_MONITOR_CAMERA_SD_CARD_MK_NEW_FILE_ERROR:
    case RawCameraImageStatusCode.MS_MONITOR_CAMERA_SD_CARD_WRITE_ERROR:
    case RawCameraImageStatusCode.MS_MONITOR_CAMERA_NACK_ABORT:
    case RawCameraImageStatusCode.MS_MONITOR_CAMERA_FAIL_DURING_IMAGE_CAPTURE:
    case RawCameraImageStatusCode.MS_MONITOR_CAMERA_FAIL_MODULE_CAMERA_ERR:
    case RawCameraImageStatusCode.MS_MONITOR_CAMERA_FAIL_CRC_ERROR_ON:
      return 'An error occurred capturing the photo.';
    default:
      return '';
  }
}
