import { IPCameraStreamId } from 'models/ipCamera';

interface IPCameraPreference {
  streamId: IPCameraStreamId;
}

export enum EntityKind {
  IP_CAMERA = 'ipCamera'
}

export type EntityId = number;

export type PreferenceValue = IPCameraPreference;

export default interface UserPreference {
  id: number;
  userId: number;
  entityKind: EntityKind;
  entityId: EntityId;
  preference: PreferenceValue;
}