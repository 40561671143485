import AuthActionTypes from 'redux/modules/auth/constants';
import { ApplicationActions } from 'redux/types';
import { upsertSafeChildProperties } from 'utils/Object/properties/upsert-child-property';

import ActionTypes from './constants';
import { DevicesState } from './types';

export const initialState: DevicesState = {};

function devicesReducer(
  state: DevicesState = initialState,
  action: ApplicationActions
): DevicesState {
  switch (action.type) {
    case ActionTypes.SET_DEVICES:
      return action.payload;
    case ActionTypes.SET_DEVICE: {
      const { payload } = action;
      const { id } = payload;
      return {
        ...state,
        [id]: payload
      };
    }
    case ActionTypes.UPDATE_DEVICE: {
      const { payload } = action;
      const { id } = payload;
      return upsertSafeChildProperties(state, [id], payload);
    }
    case AuthActionTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export default devicesReducer;
