import User from 'models/user';
import AuthActionTypes from 'redux/modules/auth/constants';
import { ApplicationActions } from 'redux/types';

import ActionTypes from './constants';
import { MyUserState } from './types';

export const initialState: MyUserState = null;

function myUserReducer(
  state: MyUserState = initialState,
  action: ApplicationActions
): MyUserState {
  switch (action.type) {
    case ActionTypes.MERGE_MY_USER:
      return { ...state, ...action.payload } as User;
    case AuthActionTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export default myUserReducer;
