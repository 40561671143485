import { ColumnType } from 'antd/lib/table';
import moment from 'moment-timezone';
import React, { useCallback } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import BackOfficeTable, {
  Props as BackOfficeTableProps
} from 'components/tables/BackOfficeTable';
import { EM_DASH } from 'constants/index';
import { DATE_TIME_FORMAT } from 'constants/time';
import { Invitee } from 'models/back-office/invitation';
import BackOfficeInvitationActionType from 'redux/modules/backOfficeInvitations/constants';
import selectBackOfficeInviteesState from 'redux/modules/backOfficeInvitations/selectors';
import { appendValuesToQueryString } from 'redux/modules/routerUtils/actions';

const loadAction =
  BackOfficeInvitationActionType.LOAD_BACK_OFFICE_INVITATIONS_REQUEST;

const columns: ColumnType<Invitee>[] = [
  {
    title: 'Email',
    dataIndex: 'email',
    width: 250,
    sorter: true
  },
  {
    title: 'First Name',
    dataIndex: 'firstName',
    width: 150,
    sorter: true
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    width: 150,
    sorter: true
  },
  {
    title: 'Username',
    dataIndex: 'username',
    width: 150,
    sorter: true
  },
  {
    title: 'Mobile Number',
    dataIndex: 'mobileNumber',
    width: 200,
    sorter: true
  },
  {
    title: 'Landline Number',
    dataIndex: 'landlineNumber',
    width: 200,
    sorter: true
  },
  {
    title: 'Last Invited',
    dataIndex: 'lastInviteDate',
    width: 250,
    sorter: true,
    render: (lastInviteDate: number) =>
      lastInviteDate
        ? moment(lastInviteDate).format(DATE_TIME_FORMAT.DEFAULT)
        : EM_DASH
  }
];

export default function BackOfficeInvitationsTable({
  onPaginationChange
}: Pick<BackOfficeTableProps<Invitee>, 'onPaginationChange'>) {
  const { total, data } = useSelector(
    selectBackOfficeInviteesState,
    shallowEqual
  );

  // If user exists, show edit user drawer by not appending email to the URL
  // else show invite/create user drawer by appending the invitee email.
  const rowIdToOnClickAction = useCallback(
    (id: string) => {
      const selectedInvitee = data.find((invitee) => invitee.id === id);

      return appendValuesToQueryString({
        drawer: undefined,
        selectedUser: selectedInvitee?.inviteeId || undefined,
        invitationEmail: selectedInvitee?.inviteeId ? undefined : selectedInvitee?.email
      });
    },
    [data]
  );

  return (
    <BackOfficeTable<Invitee>
      columns={columns}
      data={data}
      total={total}
      loadActionType={loadAction}
      rowIdToOnClickAction={rowIdToOnClickAction}
      onPaginationChange={onPaginationChange}
      emptyText="No invitations found"
    />
  );
}
