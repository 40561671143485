import axios from 'axios';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import type { SagaIterator } from 'redux-saga';
import {
  all,
  call,
  cancel,
  fork,
  put,
  select,
  takeLatest,
  throttle
} from 'redux-saga/effects';

import {
  getCountryByAbbr,
  getCountryByAbbrFailure,
  getCountryByAbbrSuccess,
  loadCountriesFailure,
  loadCountriesSuccess,
  setCountries
} from 'redux/modules/countries/actions';
import { getRequest } from 'utils/redux-saga-requests';

import ActionTypes from './constants';
import { selectCountriesAsArray } from './selectors';

export function* requestCountries(): SagaIterator {
  const prevCountries = yield select(selectCountriesAsArray);
  if (prevCountries.length > 1) {
    yield put(loadCountriesSuccess());
    yield cancel();
  }
  try {
    const response = yield call(getRequest, 'address/countries');
    const countries = response.data;
    const countriesData = keyBy(countries, 'abbreviation');
    yield all([
      put(loadCountriesSuccess(response)),
      put(setCountries(countriesData))
    ]);
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;

    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );

    yield put(loadCountriesFailure(message, error));
  }
}

export function* requestCountryByAbbr(
  action: ReturnType<typeof getCountryByAbbr>
): SagaIterator {
  try {
    const response = yield call(
      getRequest,
      `address/countries?filter=abbreviation::$eq::${action.payload}`
    );
    const countries = response.data;
    const countriesData = keyBy(countries, 'abbreviation');
    yield all([
      put(getCountryByAbbrSuccess(response)),
      put(setCountries(countriesData))
    ]);
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;

    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );

    yield put(getCountryByAbbrFailure(message, error));
  }
}

export function* watchCountriesRequest() {
  yield throttle(1000, ActionTypes.LOAD_COUNTRIES_REQUEST, requestCountries);
}

export function* watchCountryByAbbrRequest() {
  yield takeLatest(
    ActionTypes.GET_COUNTRY_BY_ABBR_REQUEST,
    requestCountryByAbbr
  );
}

export default function* countriesSaga() {
  yield all([fork(watchCountriesRequest), fork(watchCountryByAbbrRequest)]);
}
