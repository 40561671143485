import countBy from 'lodash/countBy';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import orderBy from 'lodash/orderBy';
import pickBy from 'lodash/pickBy';
import moment from 'moment-timezone';
import { createSelector } from 'reselect';

import rollbarLogger from 'config/rollbar';
import { DATE_TIME_FORMAT } from 'constants/time';
import { AssetTypeCode } from 'models/assetType';
import { EventId, EventSamplePoint, Level } from 'models/event';
import Notification, {
  NotificationId,
  NotificationStatus
} from 'models/notification';
import { MergedSamplePoint, SamplePointId } from 'models/samplePoint';
import {
  makeSelectSamplePointsByAssetTypeIdForDefaultSite,
  selectSamplePointsAsArray
} from 'redux/modules/samplePoints/selectors';
import { ApplicationRootState } from 'redux/types';

import { selectDefaultSite } from '../localUserSettings/selectors';
import { selectSites } from '../sites/selectors';

const selectRecentNotificationsState = (state: ApplicationRootState) =>
  state.recentNotifications;

const selectRecentNotificationsEnterpriseId = createSelector(
  selectRecentNotificationsState,
  (recentNotificationsState) => recentNotificationsState.enterpriseId as number
);

const selectRecentNotificationsPendingStatusChanges = createSelector(
  selectRecentNotificationsState,
  (recentNotificationsState) =>
    recentNotificationsState.pendingStatusChanges || {}
);

const selectRecentNotifications = createSelector(
  [
    selectRecentNotificationsState,
    selectSamplePointsAsArray,
    selectSites,
    makeSelectSamplePointsByAssetTypeIdForDefaultSite(
      AssetTypeCode.SAFETY_CHECK_IN
    ),
    selectRecentNotificationsPendingStatusChanges
  ],
  (
    recentNotificationsState,
    samplePoints,
    sites,
    safetyCheckSamplePoints,
    recentNotificationsPendingAcknowledgements
  ) => {
    try {
      const notifications = mapValues(
        recentNotificationsState.data,
        (notification: Notification): Notification => {
          const { id, event, notificationStatus } = notification;
          if (!event) {
            return notification;
          }

          let samplePoint: EventSamplePoint | undefined = samplePoints.find(
            (sp) => sp.sid === event.samplePoint.sid
          );
          if (!samplePoint) {
            samplePoint = event.samplePoint;
          }
          const notificationSamplePointId = samplePoint?.id;
          const notificationSamplePoint =
            event.level === Level.SOS
              ? (safetyCheckSamplePoints as MergedSamplePoint[]).find(
                (sp) => sp._hidden?.id === notificationSamplePointId
              ) : samplePoint;

          const pendingAcknowledgement =
            recentNotificationsPendingAcknowledgements[id];

          const eventDate = event.date;
          const eventTriggerSamplePointSiteTimezoneCode = event.samplePoint.siteId
            ? sites[event.samplePoint.siteId]?.timezoneCode
            : undefined;

          return {
            ...notification,
            eventTriggerSamplePointId: notificationSamplePoint?.id,
            eventDate: event.date,
            eventMessage: event.message,
            eventLevel: event.level,
            eventTriggerSamplePointName: notificationSamplePoint?.name,
            eventTriggerSamplePointSiteId: notificationSamplePoint?.siteId,
            eventTriggerSamplePointSiteTimezoneCode,
            notificationStatus: pendingAcknowledgement
              ? NotificationStatus.ACKNOWLEDGED
              : notificationStatus,
            eventFormattedDate:
              eventTriggerSamplePointSiteTimezoneCode && eventDate
                ? moment(eventDate)
                  .tz(eventTriggerSamplePointSiteTimezoneCode)
                  .format(DATE_TIME_FORMAT.DEFAULT)
                : undefined,
            eventTriggerValue: event?.triggerValue
          };
        }
      );
      return notifications;
    } catch (error) {
      rollbarLogger.error(
        'Notification critical error',
        error as Record<string, unknown>,
        recentNotificationsState.data
      );
      return recentNotificationsState.data;
    }
  }
);

const selectRecentNotificationsForDefaultSite = createSelector(
  [selectRecentNotifications, selectDefaultSite],
  (recentNotifications, defaultSite) =>
    pickBy(
      recentNotifications,
      (notification) =>
        notification.event?.samplePoint.siteId === defaultSite?.id
    )
);

const selectRecentNotificationsAsArray = createSelector(
  selectRecentNotifications,
  (recentNotifications) => Object.values(recentNotifications)
);

const selectRecentNotificationsForDefaultSiteAsArray = createSelector(
  selectRecentNotificationsForDefaultSite,
  (recentNotificationsForDefaultSite) =>
    Object.values(recentNotificationsForDefaultSite)
);

const selectRecentNotificationsAsArrayOrderedByEventDate = createSelector(
  selectRecentNotificationsAsArray,
  (recentNotificationsAsArray) =>
    orderBy(recentNotificationsAsArray, 'eventDate', 'desc')
);

const selectRecentNotificationsForDefaultSiteAsArrayOrderedByEventDate =
  createSelector(
    selectRecentNotificationsForDefaultSiteAsArray,
    (recentNotificationsForDefaultSiteAsArray) =>
      orderBy(recentNotificationsForDefaultSiteAsArray, 'eventDate', 'desc')
  );

const selectRecentUnacknowledgedNotificationsAsArray = createSelector(
  selectRecentNotificationsAsArrayOrderedByEventDate,
  (recentNotificationsAsArrayOrderedByEventDate) =>
    recentNotificationsAsArrayOrderedByEventDate.filter(
      ({ notificationStatus }) =>
        notificationStatus === NotificationStatus.RAISED
    )
);

const selectRecentUnacknowledgedNotificationsForDefaultSiteAsArray =
  createSelector(
    selectRecentNotificationsForDefaultSiteAsArrayOrderedByEventDate,
    (recentNotificationsAsArrayOrderedByEventDate) =>
      recentNotificationsAsArrayOrderedByEventDate.filter(
        ({ notificationStatus }) =>
          notificationStatus === NotificationStatus.RAISED
      )
  );

const selectRecentUnacknowledgedNotificationsGroupedByEventTriggerSamplePointId =
  createSelector(
    selectRecentUnacknowledgedNotificationsAsArray,
    (recentUnacknowledgedNotificationsAsArray) =>
      groupBy(
        recentUnacknowledgedNotificationsAsArray,
        'eventTriggerSamplePointId'
      )
  );

const makeSelectRecentUnacknowledgedNotificationsByEventTriggerSamplePointId = (
  samplePointId: SamplePointId
) =>
  createSelector(
    selectRecentUnacknowledgedNotificationsGroupedByEventTriggerSamplePointId,
    (recentUnacknowledgedNotificationsGroupedByEventTriggerSamplePointId) =>
      recentUnacknowledgedNotificationsGroupedByEventTriggerSamplePointId[samplePointId]
  );

const selectRecentAcknowledgedNotificationsAsArray = createSelector(
  selectRecentNotificationsAsArrayOrderedByEventDate,
  (recentNotificationsAsArrayOrderedByEventDate) =>
    recentNotificationsAsArrayOrderedByEventDate.filter(
      ({ notificationStatus }) =>
        notificationStatus === NotificationStatus.ACKNOWLEDGED
    )
);

const selectRecentUnacknowledgedNotificationsMaxEventLevel = createSelector(
  selectRecentUnacknowledgedNotificationsAsArray,
  (recentUnacknowledgedNotificationsAsArray) =>
    Math.max(
      ...recentUnacknowledgedNotificationsAsArray.map(
        (notification) => notification.eventLevel || 0
      )
    )
);

const makeSelectRecentNotificationById = (
  recentNotificationId?: NotificationId
) =>
  createSelector(selectRecentNotifications, (recentNotifications) => {
    if (recentNotificationId) {
      return recentNotifications[recentNotificationId];
    }
    return undefined;
  });

const makeSelectRecentNotificationByEventId = (eventId: EventId) =>
  createSelector(
    selectRecentNotificationsAsArrayOrderedByEventDate,
    (recentNotifications) => {
      return recentNotifications.find((n) => n.eventId === eventId);
    }
  );

const selectRecentUnacknowledgedNotificationsCount = createSelector(
  selectRecentUnacknowledgedNotificationsAsArray,
  (recentUnacknowledgedNotificationsAsArray) =>
    countBy(
      recentUnacknowledgedNotificationsAsArray,
      (e) => e.event?.samplePoint.siteId
    )
);

export {
  selectRecentNotificationsState,
  selectRecentNotificationsEnterpriseId,
  selectRecentNotificationsPendingStatusChanges,
  selectRecentNotifications,
  selectRecentNotificationsForDefaultSite,
  selectRecentNotificationsAsArray,
  selectRecentNotificationsForDefaultSiteAsArray,
  selectRecentNotificationsAsArrayOrderedByEventDate,
  selectRecentNotificationsForDefaultSiteAsArrayOrderedByEventDate,
  selectRecentUnacknowledgedNotificationsAsArray,
  selectRecentUnacknowledgedNotificationsForDefaultSiteAsArray,
  selectRecentUnacknowledgedNotificationsGroupedByEventTriggerSamplePointId,
  makeSelectRecentUnacknowledgedNotificationsByEventTriggerSamplePointId,
  selectRecentUnacknowledgedNotificationsMaxEventLevel,
  selectRecentAcknowledgedNotificationsAsArray,
  selectRecentUnacknowledgedNotificationsCount,
  makeSelectRecentNotificationById,
  makeSelectRecentNotificationByEventId
};
