/* eslint-disable no-underscore-dangle */

import mapKeys from 'lodash/mapKeys';

import { AssetTypeCode, RawAssetTypeCode } from 'models/assetType';
import Sample from 'models/sample';
import SamplePoint, {
  RawSamplePoint,
  SamplePointLastSampleData
} from 'models/samplePoint';
import SamplePointStatistic, {
  SamplePointStatisticWithAggregates
} from 'models/samplePointsStatistic';
import parseRawSamplePoint from 'utils/associated-sample-points/parse-raw-samplepoint';
import dateToISOString from 'utils/date-to-iso-string';

/**
 * Merge associated soil moisture & temperature samples into a single sample.
 */
export const mergeSoilMoistureAndTempSamples = (
  soilMoistureSample: SamplePointLastSampleData,
  soilTempSample: SamplePointLastSampleData
): SamplePointLastSampleData =>
  ({
    ...soilMoistureSample,
    multiDimValues: {
      // Give each value a different dataType so we can tell them apart.
      sampleDim: [
        {
          ...soilMoistureSample,
          dataType: RawAssetTypeCode.SOIL_MOISTURE
        },
        {
          ...soilTempSample,
          dataType: RawAssetTypeCode.SOIL_TEMP
        }
      ].map(({ rwValue, dataType, date, prevDate }) => ({
        rwValue,
        dataType,
        sampleDate: dateToISOString(date),
        prevSampleDate: dateToISOString(prevDate)
      }))
    },
    // Keep original data of temp sample, so we can recreate it if needed.
    _hidden: soilTempSample
  }) as SamplePointLastSampleData;

/**
 * Takes a raw soil samplePoint, and a parsed soil samplePoint, and merges them
 * into a single parsed samplePoint.
 */
const mergeSoilSamplePoints = (
  rawSoilSamplePoint: RawSamplePoint,
  soilSamplePoint: SamplePoint
): SamplePoint => {
  const rawSamplePointIsMoisture =
    rawSoilSamplePoint.assetTypeId === RawAssetTypeCode.SOIL_MOISTURE;

  const [moisture, temp] = rawSamplePointIsMoisture
    ? [parseRawSamplePoint(rawSoilSamplePoint) as SamplePoint, soilSamplePoint]
    : [soilSamplePoint, parseRawSamplePoint(rawSoilSamplePoint) as SamplePoint];
  return {
    ...moisture,
    lastSampleData:
      moisture.lastSampleData && temp.lastSampleData
        ? mergeSoilMoistureAndTempSamples(
            moisture.lastSampleData,
            temp.lastSampleData
          )
        : undefined,
    // Keep original data of temp samplepoint, so we can recreate it if needed.
    _hidden: temp
  } as SamplePoint;
};

/**
 * Merge associated soil moisture & temperature samplePointStatistics into a
 * single samplePointStatistic.
 */
export const mergeSoilSamplePointStatistics = (
  moisture: SamplePointStatisticWithAggregates,
  temp: SamplePointStatisticWithAggregates
) =>
  ({
    ...moisture,
    lastSample:
      moisture.lastSample && temp.lastSample
        ? mergeSoilMoistureAndTempSamples(
            moisture.lastSample as unknown as SamplePointLastSampleData,
            temp.lastSample as unknown as SamplePointLastSampleData
          )
        : moisture.lastSample,
    aggregates: Object.entries(moisture?.aggregates).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: {
          name: value.name,
          values: {
            ...mapKeys(value.values, (_, k) => `${k}Moisture`),
            ...mapKeys(temp.aggregates[key].values, (_, k) => `${k}Temp`)
          }
        }
      }),
      {}
    ),
    _hidden: temp
  }) as SamplePointStatistic;

/**
 * Get original soil moisture sample & soil temperature sample from merged
 * soil sample.
 */
const getOriginalSoilSamples = (
  mergedSoilSample: Sample & { _hidden?: Sample }
) => {
  // Get temperature sample from "_hidden" property of merged samplePoint.
  const [moisture, temp] = [
    { ...mergedSoilSample },
    mergedSoilSample._hidden as Sample
  ];

  delete moisture._hidden;
  delete moisture.multiDimValues;

  // Both moisture & temp originally had the same data type.
  moisture.dataType = temp.dataType;

  return [moisture, temp] as [Sample, Sample];
};

/**
 * Get original soil moisture samplePoint and soil temperature samplePoint from
 * merged soil samplePoint.
 */
export const getOriginalSoilSamplePoints = (
  mergedSoilSamplePoint: SamplePoint & { _hidden?: RawSamplePoint }
) => {
  // Get temperature samplePoint from "_hidden" property of merged samplePoint.
  const [moisture, temp] = [
    { ...mergedSoilSamplePoint } as any as RawSamplePoint & {
      _hidden?: RawSamplePoint;
    },
    mergedSoilSamplePoint._hidden as RawSamplePoint
  ];

  delete moisture._hidden;

  moisture.lastSampleData = moisture.lastSampleData
    ? (getOriginalSoilSamples(
        moisture.lastSampleData as unknown as Sample
      )[0] as unknown as SamplePointLastSampleData)
    : undefined;

  moisture.assetTypeId = RawAssetTypeCode.SOIL_MOISTURE;
  temp.assetTypeId = RawAssetTypeCode.SOIL_TEMP;

  return [moisture, temp] as [RawSamplePoint, RawSamplePoint];
};

/**
 * Returns true if the given raw & parsed soil samplePoints are associated.
 * That is, they have the same serial number (and are both soil samplePoints).
 */
const isMatchingSoilSamplePoint = (
  soilSamplePoint: RawSamplePoint,
  samplePoint: SamplePoint
) =>
  (soilSamplePoint.assetTypeId === RawAssetTypeCode.SOIL_MOISTURE ||
    soilSamplePoint.assetTypeId === RawAssetTypeCode.SOIL_TEMP) &&
  samplePoint.assetTypeId === AssetTypeCode.SOIL &&
  soilSamplePoint.deviceTags?.serialNumber ===
    samplePoint.deviceTags?.serialNumber;

/**
 * Takes an array of parsed samplePoints (& nulls) and a raw samplePoint, and
 * returns an array of parsed samplePoints (& nulls).
 */
export const soilSamplePointReducer = (
  samplePoints: Array<SamplePoint | null>,
  soilSamplePoint: RawSamplePoint
) => {
  // TODO: should not have any
  const arr: any[] = [];
  let merged = false;

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < samplePoints.length; i++) {
    const samplePoint = samplePoints[i];

    if (
      samplePoint &&
      isMatchingSoilSamplePoint(soilSamplePoint, samplePoint)
    ) {
      arr[i] = mergeSoilSamplePoints(soilSamplePoint, samplePoint);
      merged = true;
    } else {
      arr[i] = samplePoint;
    }
  }

  if (!merged) {
    arr.push(parseRawSamplePoint(soilSamplePoint));
  }

  return arr;
};
