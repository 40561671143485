import React, { memo } from 'react';
import { useDispatch } from 'react-redux';

import DefaultButton from 'components/atoms/DefaultButton';
import { appendValuesToQueryString } from 'redux/modules/routerUtils/actions';

function BackOfficeEnterpriseCreateButton(): JSX.Element {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(
      appendValuesToQueryString({
        selectedEnterprise: undefined,
        drawer: 'addEnterprise'
      })
    );
  };

  return (
    <DefaultButton
      id="add-enterprise-button"
      type="primary"
      size="small"
      onClick={handleClick}
      mobileChildren="ADD"
    >
      ADD ENTERPRISE
    </DefaultButton>
  );
}

export default memo(BackOfficeEnterpriseCreateButton);
