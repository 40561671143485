enum ActionTypes {
  LOAD_NOTIFICATION_POLICY_REQUEST = 'LOAD_NOTIFICATION_POLICY_REQUEST',
  LOAD_NOTIFICATION_POLICY_SUCCESS = 'LOAD_NOTIFICATION_POLICY_SUCCESS',
  LOAD_NOTIFICATION_POLICY_FAILURE = 'LOAD_NOTIFICATION_POLICY_FAILURE',

  EDIT_NOTIFICATION_POLICY_REQUEST = 'EDIT_NOTIFICATION_POLICY_REQUEST',
  EDIT_NOTIFICATION_POLICY_SUCCESS = 'EDIT_NOTIFICATION_POLICY_SUCCESS',
  EDIT_NOTIFICATION_POLICY_FAILURE = 'EDIT_NOTIFICATION_POLICY_FAILURE',

  SET_NOTIFICATION_POLICY = 'SET_NOTIFICATION_POLICY'
}

export default ActionTypes;
