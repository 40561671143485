import { Alert, Button, Form, Select } from 'antd';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom-v5-compat';

import BackOfficeUserInvitations from 'components/atoms/BackOfficeUserInvitations';
import DefaultBackendSearchSelect from 'components/composites/DefaultBackendSearchSelect';
import DefaultFormItem from 'components/composites/DefaultFormItem';
import FieldValue from 'components/forms/FieldValue';
import { UserRole, UserRoleLabels } from 'models/membership';
import { addBackOfficeInvitation } from 'redux/modules/backOfficeInvitations/actions';
import ActionType from 'redux/modules/backOfficeInvitations/constants';
import clearErrors from 'redux/modules/error/actions';
import { makeSelectFirstErrorMessage } from 'redux/modules/error/selectors';
import { makeSelectIsLoading } from 'redux/modules/loading/selectors';
import { SearchTypes } from 'redux/modules/search/types';

import './BackOfficeInvitationFormItems.less';

const actions = [ActionType.ADD_BACK_OFFICE_INVITATION_REQUEST];
const selectIsLoading = makeSelectIsLoading(actions);
const selectErrorMessage = makeSelectFirstErrorMessage(actions);

interface Props {
  closeDrawer?: () => void;
  disabled?: boolean;
}

const roleOptions = [
  UserRole.VIEW_ONLY,
  UserRole.SUPERVISOR,
  UserRole.ADMIN
].map((role) => ({
  label: UserRoleLabels[role],
  value: role
}));

export function BackOfficeInvitationFormItems({
  closeDrawer,
  disabled = false
}: Props) {
  const isLoading: boolean = useSelector(selectIsLoading);
  const errorMessage = useSelector(selectErrorMessage);
  const form = Form.useFormInstance();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const inUserEditDrawer = !!searchParams.get('selectedUser');

  useEffect(() => {
    if (inUserEditDrawer) {
      dispatch(clearErrors(actions));
    }
  }, [inUserEditDrawer, form, dispatch]);

  const handleEnterpriseItemSelect = useCallback(
    (enterprise) =>
      form.setFieldsValue({ enterpriseId: enterprise.id }),
    [form]
  );

  const onSubmit = useCallback(() => {
    // We do not validate form fields because the form used here sometimes is not for invitation only.
    // e.g., back office user edit form
    const { enterpriseId, role } = form.getFieldsValue();
    dispatch(
      addBackOfficeInvitation(
        {
          email: form.getFieldValue('email'),
          enterpriseId,
          role,
          isSuperAdmin: false
        },
        () => {
          form?.setFieldsValue({ enterpriseId: undefined });
          closeDrawer?.();
        }
      )
    );
  }, [closeDrawer, dispatch, form]);

  return (
    <>
      <div className="BackOfficeInvitationFormItems-enterprise">
        <DefaultBackendSearchSelect
          name="enterpriseId"
          labelProperty="name"
          placeholder="Enterprise"
          label="Invite to Enterprise"
          searchType={SearchTypes.UserEnterpriseMembershipSearch}
          onItemSelect={handleEnterpriseItemSelect}
          disabled={disabled}
          required
        />
        {/* Deliberate empty label */}
        <DefaultFormItem label=" " name="role" required>
          <Select
            placeholder="Role"
            options={roleOptions}
            disabled={disabled}
          />
        </DefaultFormItem>
      </div>
      <Button
        block
        type="primary"
        disabled={disabled}
        loading={isLoading}
        // To make sure BackOfficeInvitationFormItems can work standalone (i.e., in BackOfficeUserForm) when the external
        // form's onFinish method has nothing to do with invitation, we made this custom submit method.
        onClick={onSubmit}
      >
        Invite
      </Button>
      {errorMessage && (
        <DefaultFormItem style={{ marginTop: '1rem' }}>
          <Alert message={errorMessage} type="error" />
        </DefaultFormItem>
      )}
      <FieldValue name="email">
        {(email) => <BackOfficeUserInvitations email={email as string} />}
      </FieldValue>
    </>
  );
}