import { Form } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import React, { memo, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BackOfficeDrawer from 'components/drawers/BackOfficeDrawer';
import BackOfficeUserForm from 'components/forms/BackOfficeUserForm';
import BackOfficeUserLoadingGate from 'components/loadingGates/BackOfficeUserLoadingGate';
import BackOfficeUserInvitationsRequest from 'components/requests/BackOfficeUserInvitationsRequest';
import BackOfficeUserRequest from 'components/requests/BackOfficeUserRequest';
import BackOfficeInvitationsActionType from 'redux/modules/backOfficeInvitations/constants';
import useBackOfficeUser from 'redux/modules/backOfficeUser/hooks';
import { editBackOfficeUser } from 'redux/modules/backOfficeUsers/actions';
import BackOfficeUsersActionType from 'redux/modules/backOfficeUsers/constants';
import clearErrors from 'redux/modules/error/actions';
import { makeSelectIsLoading } from 'redux/modules/loading/selectors';
import { appendValuesToQueryString } from 'redux/modules/routerUtils/actions';
import { selectRouterQueryStringSelectedUser } from 'redux/modules/routerUtils/selectors';

const actions = [
  BackOfficeUsersActionType.EDIT_BACK_OFFICE_USER_REQUEST,
  BackOfficeInvitationsActionType.ADD_BACK_OFFICE_INVITATION_REQUEST,
  BackOfficeInvitationsActionType.REMOVE_BACK_OFFICE_INVITATION_REQUEST
];

const selectIsLoading = makeSelectIsLoading(actions);

function BackOfficeUserEditDrawer(): JSX.Element {
  const [form] = Form.useForm();
  const userId = useSelector(selectRouterQueryStringSelectedUser);
  const isLoading = useSelector(selectIsLoading);

  const visible = !!userId;
  const backOfficeUserData = useBackOfficeUser();

  const initialValues = useMemo(
    () => cloneDeep(backOfficeUserData),
    [backOfficeUserData]
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (visible) dispatch(clearErrors(actions));
  }, [dispatch, visible]);

  const handleClose = () => {
    dispatch(
      appendValuesToQueryString({
        selectedUser: undefined
      })
    );
  };

  const handleClick = () => {
    form
      .validateFields()
      .then((values) => {
        if (userId) {
          if (values && values.memberships) {
            // eslint-disable-next-line no-param-reassign
            values.memberships = values.memberships.map(
              (m: { [key: string]: string }) => {
                const membership = m;

                delete membership.enterprise;
                delete membership.createdAt;
                delete membership.updatedAt;

                return membership;
              }
            );
          }
          dispatch(editBackOfficeUser(userId, values));
        }
      })
      .catch(() => {
        // Ignoring promise rejection
      });
  };

  return (
    <BackOfficeDrawer
      visible={visible}
      backOfficeSection="Edit User"
      actions={actions}
      handleClick={handleClick}
      handleClose={handleClose}
    >
      <BackOfficeUserRequest userId={userId} />
      <BackOfficeUserInvitationsRequest email={initialValues?.email} />
      <BackOfficeUserLoadingGate userId={userId}>
        <BackOfficeUserForm
          form={form}
          initialValues={initialValues || undefined}
          isLoading={isLoading}
        />
      </BackOfficeUserLoadingGate>
    </BackOfficeDrawer>
  );
}

export default memo(BackOfficeUserEditDrawer);
