import React from 'react';

import BackOfficeUserCreateButton from 'components/buttons/BackOfficeUserCreateButton';
import BackOfficeFilterDrawer from 'components/drawers/BackOfficeFilterDrawer';
import BackOfficeUserCreateDrawer from 'components/drawers/BackOfficeUserCreateDrawer';
import BackOfficeUserEditDrawer from 'components/drawers/BackOfficeUserEditDrawer';
import BackOfficeSection from 'components/features/backOffice/BackOfficeSection';
import BackOfficeUserFilterForm from 'components/forms/BackOfficeUserFilterForm';
import BackOfficeUsersRequest from 'components/requests/BackOfficeUsersRequest';
import BackOfficeUsersTable from 'components/tables/BackOfficeUsersTable';
import { FilterSetName } from 'redux/modules/routerUtils/types';

function BackOfficeUsers(): JSX.Element {
  return (
    <BackOfficeSection
      actions={<BackOfficeUserCreateButton />}
      table={<BackOfficeUsersTable />}
      filterSetName={FilterSetName.BACK_OFFICE_USERS}
      searchInputPlaceholder="Search Users"
    >
      <BackOfficeUsersRequest />
      <BackOfficeUserCreateDrawer />
      <BackOfficeUserEditDrawer />
      <BackOfficeFilterDrawer filterSetName={FilterSetName.BACK_OFFICE_USERS}>
        <BackOfficeUserFilterForm />
      </BackOfficeFilterDrawer>
    </BackOfficeSection>
  );
}

export default BackOfficeUsers;
