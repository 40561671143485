import AuthActionTypes from 'redux/modules/auth/constants';
import { ApplicationActions } from 'redux/types';

import ActionTypes from './constants';
import { VolumeMappingsState } from './types';

const initialState: VolumeMappingsState = {};

function volumeMappingsReducer(
  state: VolumeMappingsState = initialState,
  action: ApplicationActions
): VolumeMappingsState {
  switch (action.type) {
    case ActionTypes.SET_VOLUME_MAPPINGS: {
      const { payload } = action;
      const { samplePointId, volumeMappings } = payload;
      return {
        ...state,
        [samplePointId]: {
          ...state[samplePointId],
          volumeMappings
        }
      };
    }
    case ActionTypes.SET_CALCULATED_VOLUME_MAPPINGS: {
      const { payload } = action;
      const { samplePointId, calculatedVolumeMappings } = payload;
      return {
        ...state,
        [samplePointId]: {
          ...state[samplePointId],
          // The items in calculatedVolumeMappings can be duplicated.
          calculatedVolumeMappings: [
            ...(state[samplePointId]?.calculatedVolumeMappings ?? []),
            ...calculatedVolumeMappings
          ]
        }
      };
    }
    case AuthActionTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export default volumeMappingsReducer;
