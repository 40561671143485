import { FormInstance } from 'antd';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import DefaultForm from 'components/composites/DefaultForm';
import { appendValuesToQueryString } from 'redux/modules/routerUtils/actions';

import { BackOfficeInvitationFormItems } from './BackOfficeInvitationFormItems';
import FormItemEmail from '../FormItemEmail';

interface Props<T extends { email?: string }> {
  form: FormInstance;
  initialValues?: T;
}

/** The form is only used in BackOfficeUserCreateDrawer which won't cause nested form issue. */
export function BackOfficeInvitationForm<T extends { email?: string }>({ initialValues, form }: Props<T>) {
  const dispatch = useDispatch();

  const closeDrawer = useCallback(() => {
    dispatch(
      appendValuesToQueryString({
        drawer: undefined,
        invitationEmail: undefined
      })
    );
  }, [dispatch]);

  return (
    <DefaultForm
      layout="vertical"
      form={form}
      initialValues={initialValues}
    >
      <FormItemEmail
        readOnly={!!initialValues?.email}
      />
      <BackOfficeInvitationFormItems closeDrawer={closeDrawer} />
    </DefaultForm>
  );
}
