import { createSelector } from 'reselect';

import { ApplicationRootState } from 'redux/types';

const selectNetworkState = (state: ApplicationRootState) => state.network;

const selectNetworkOnline = createSelector(
  selectNetworkState,
  (networkState) => networkState.online
);

const selectIsNetworkReady = createSelector(
  selectNetworkState,
  (networkState) => networkState.isReady
);

export { selectNetworkState, selectNetworkOnline, selectIsNetworkReady };
