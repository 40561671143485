import isEqual from 'lodash/isEqual';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { UserRole } from 'models/membership';

import { makeSelectUserByUserRole, selectUsersAsArray } from './selectors';
import { selectCurrentEnterpriseOwner } from '../enterprise/selectors';
import { selectSitesAsArray } from '../sites/selectors';

export function useUsersAsArray() {
  return useSelector(selectUsersAsArray, isEqual);
}

export function useUser(userRole: UserRole) {
  const selectUserByUserRole = useMemo(
    () => makeSelectUserByUserRole(userRole),
    [userRole]
  );

  return useSelector(selectUserByUserRole, isEqual);
}

export function useIsEnterpriseOwner(userId?: number) {
  const enterpriseOwner = useSelector(selectCurrentEnterpriseOwner, isEqual);
  return !!enterpriseOwner && !!userId && enterpriseOwner.id === userId;
}

export function useIsAnyPrimaryContact(userId?: number) {
  const sites = useSelector(selectSitesAsArray, isEqual);
  return !!userId && sites.some(({ ownerId }) => ownerId === userId);
}
