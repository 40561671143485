import { Select } from 'antd';
import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';

import DefaultFormItem from 'components/composites/DefaultFormItem';
import LoadingSpinner from 'components/composites/LoadingSpinner';
import { CountryAbbreviation } from 'models/country';
import { useCountry } from 'redux/modules/countries/hooks';
import { makeSelectIsLoading } from 'redux/modules/loading/selectors';
import ActionTypes from 'redux/modules/states/constants';
import { useStatesByCountryId } from 'redux/modules/states/hooks';

interface Props {
  fieldName: string | string[];
  countryAbbreviation?: CountryAbbreviation;
  readOnly?: boolean;
}

const selectIsLoading = makeSelectIsLoading([ActionTypes.LOAD_STATES_REQUEST]);

function AddressFormItemSelectState({
  fieldName,
  countryAbbreviation,
  readOnly
}: Props) {
  const isLoading = useSelector(selectIsLoading);

  const country = useCountry(countryAbbreviation);
  const statesByCountryId = useStatesByCountryId(country?.id);

  const statesOptions = useMemo(
    () =>
      statesByCountryId?.map(({ name, id }) => ({
        label: name,
        value: id
      })),
    [statesByCountryId]
  );

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <DefaultFormItem
      name={fieldName}
      label="State/Province"
      rules={[
        {
          required: true,
          message: 'Please enter a State/Province'
        }
      ]}
      readOnly={readOnly}
    >
      <Select
        showSearch
        optionFilterProp="label"
        options={statesOptions}
        disabled={!countryAbbreviation}
      />
    </DefaultFormItem>
  );
}

export default memo(AddressFormItemSelectState);
