// ==============================
// Conversion factors taken from Google Search
// some cases are metric to imperial and some cases are imperial to metric, but they will be basis for the utils below
// ==============================
enum LengthConversion {
  IN_TO_CM = 2.54,
  IN_TO_MM = 25.4,
  M_TO_FT = 3.281
}
enum AreaConversion {
  HA_TO_AC = 2.471,
  SQM_TO_FT_2 = 10.764
}
enum VolumeConversion {
  GAL_TO_L = 3.785,
  AC_FT_TO_ML = 1.233
}
enum PressureConversion {
  PSI_TO_KPA = 6.895
}

// ==============================
// Exportable conversion factors (derived to help with readability in utils files)
// ordered by imperial_to_metric, then metric_to_imperial
// ==============================
// Length
export const IN_TO_CM = LengthConversion.IN_TO_CM;
export const CM_TO_IN = 1 / LengthConversion.IN_TO_CM;

export const IN_TO_MM = LengthConversion.IN_TO_MM;
export const MM_TO_IN = 1 / LengthConversion.IN_TO_MM;

export const FT_TO_M = 1 / LengthConversion.M_TO_FT;
export const M_TO_FT = LengthConversion.M_TO_FT;

// Area
export const AC_TO_HA = 1 / AreaConversion.HA_TO_AC;
export const HA_TO_AC = AreaConversion.HA_TO_AC;

export const FT_2_TO_SQM = 1 / AreaConversion.SQM_TO_FT_2;
export const SQM_TO_FT_2 = AreaConversion.SQM_TO_FT_2;

// Volume
export const GAL_TO_L = VolumeConversion.GAL_TO_L;
export const L_TO_GAL = 1 / VolumeConversion.GAL_TO_L;

export const AC_FT_TO_ML = VolumeConversion.AC_FT_TO_ML;
export const ML_TO_AC_FT = 1 / VolumeConversion.AC_FT_TO_ML;

// Pressure
export const PSI_TO_KPA = PressureConversion.PSI_TO_KPA;
export const KPA_TO_PSI = 1 / PressureConversion.PSI_TO_KPA;