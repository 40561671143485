import { AimOutlined, ReloadOutlined } from '@ant-design/icons';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import DefaultButton from 'components/atoms/DefaultButton';
import ActionType from 'redux/modules/backOfficeDeviceMapPoints/constants';
import { makeSelectIsLoading } from 'redux/modules/loading/selectors';

import './styles.less';

interface BackOfficeDeviceMapLegendProps {
  onRecenter: () => void;
  onRefresh: () => void;
}

const actions = [ActionType.LOAD_BACK_OFFICE_DEVICE_MAP_POINTS_REQUEST];
const selectIsLoading = makeSelectIsLoading(actions);

function BackOfficeDeviceMapLegend({
  onRecenter,
  onRefresh
}: BackOfficeDeviceMapLegendProps): JSX.Element {
  const isLoading = useSelector(selectIsLoading);

  function ControlButtons() {
    return (
      <div className="BackOfficeDeviceMapLegend-action">
        <DefaultButton
          type="primary"
          onClick={onRecenter}
        >
          <AimOutlined />
        </DefaultButton>
        <DefaultButton
          type="lets-go"
          loading={isLoading}
          onClick={onRefresh}
        >
          {!isLoading && <ReloadOutlined />}
        </DefaultButton>
      </div>
    );
  }

  return (
    <div className="BackOfficeDeviceMapLegend">
      <h4>Controls</h4>
      <ControlButtons />
    </div>
  );
}

export default memo(BackOfficeDeviceMapLegend);
