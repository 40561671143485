import mapValues from 'lodash/mapValues';
import { MigrationManifest, PersistedState } from 'redux-persist';

import {
  AdvancedDamChartType,
  FlowChartType,
  SimpleDamChartType,
  TankChartType
} from 'components/features/samplePoint/SamplePointSampleChart/sampleChartType.enum';
import { EnterpriseId } from 'models/enterprise';
import Notification from 'models/notification';
import { SiteId } from 'models/site';

import { initialState as localUserSettingsInitialState } from './modules/localUserSettings/reducer';
import { LocalUserSettingsState } from './modules/localUserSettings/types';
import { ApplicationRootState } from './types';

type StateMapper = (state: any) => ApplicationRootState | undefined;
type PersistedStateMapper = (state: PersistedState) => PersistedState;

const stateMappers: Record<number, StateMapper> = {
  0: () => undefined,
  1: () => undefined,
  2: () => undefined,
  3: () => undefined,
  4: () => undefined,
  5: () => undefined,
  6: () => undefined,
  7: () => undefined,
  8: () => undefined,
  9: () => undefined,
  10: () => undefined,
  11: (
    state: ApplicationRootState & {
      localUserSettings: Omit<
        ApplicationRootState['localUserSettings'],
        'defaultSites'
      > & { defaultSites: Record<EnterpriseId, SiteId> };
    }
  ) => ({
    ...state,
    localUserSettings: {
      ...state?.localUserSettings,
      defaultSites: state?.localUserSettings.defaultSites ?? {}
    }
  }),
  12: (
    state: ApplicationRootState & {
      recentNotificationEvents: Record<number, Notification['event']>;
    }
  ) => {
    const { recentNotificationEvents, recentNotifications, ...newState } =
      state;

    const recentNotificationsV2 = {
      ...recentNotifications,
      data: mapValues(recentNotifications.data, (rn) => ({
        ...rn,
        event: recentNotificationEvents[rn.eventId]
      }))
    };

    return { recentNotifications: recentNotificationsV2, ...newState };
  },
  13: (
    state: ApplicationRootState & {
      localUserSettings: ApplicationRootState & {
        defaultSites: Record<EnterpriseId, SiteId>;
      };
    }
  ) => {
    const { localUserSettings, ...newState } = state;

    const localUserSettingsV2: LocalUserSettingsState & {
      defaultSites?: Record<EnterpriseId, SiteId>;
    } = {
      ...localUserSettings,
      defaultSiteId: Object.values(localUserSettings.defaultSites)[0]
    };
    delete localUserSettingsV2.defaultSites;

    const result: ApplicationRootState = {
      ...newState,
      localUserSettings: localUserSettingsV2
    };
    return result;
  },
  14: (
    state: ApplicationRootState & {
      localUserSettings: ApplicationRootState['localUserSettings'] & {
        dieselLevelChartType: ApplicationRootState['localUserSettings']['fuelLevelChartType'];
      };
    }
  ) => {
    const newState = {
      ...state,
      localUserSettings: {
        ...state.localUserSettings,
        fuelLevelChartType: state.localUserSettings.dieselLevelChartType
      }
    };

    delete (newState.localUserSettings as any).dieselLevelChartType;

    return newState;
  },
  15: (
    state: ApplicationRootState & {
      localUserSettings: ApplicationRootState['localUserSettings'] & {
        simpleDamChartType: ApplicationRootState['localUserSettings']['simpleDamChartType'] &
        'megalitres';
        advancedDamChartType: ApplicationRootState['localUserSettings']['advancedDamChartType'] &
        'advancedDamMegalitres';
      };
    }
  ) => {
    return {
      ...state,
      localUserSettings: {
        ...state.localUserSettings,
        simpleDamChartType:
          state.localUserSettings.simpleDamChartType === 'megalitres'
            ? SimpleDamChartType.VOLUME
            : state.localUserSettings.simpleDamChartType,
        advancedDamChartType:
          state.localUserSettings.advancedDamChartType ===
            'advancedDamMegalitres'
            ? AdvancedDamChartType.VOLUME
            : state.localUserSettings.advancedDamChartType
      }
    };
  },
  16: (state: ApplicationRootState) => {
    if (state.enterprise?.billingAddress?.country !== 'USA') return state;
    return {
      ...state,
      localUserSettings: {
        ...state.localUserSettings,
        fuelLevelChartType:
          state.localUserSettings.fuelLevelChartType === TankChartType.LITRES
            ? TankChartType.GALLONS
            : state.localUserSettings.fuelLevelChartType,
        liquidFertiliserLevelChartType:
          state.localUserSettings.liquidFertiliserLevelChartType ===
            TankChartType.LITRES
            ? TankChartType.GALLONS
            : state.localUserSettings.liquidFertiliserLevelChartType,
        flowChartType:
          state.localUserSettings.flowChartType === FlowChartType.VOLUME_PER_MIN
            ? null
            : state.localUserSettings.flowChartType
      }
    };
  },
  17: (state: ApplicationRootState) => {
    return {
      ...state,
      recentNotifications: {
        enterpriseId: null,
        data: {},
        pendingStatusChanges: {}
      }
    };
  },
  18: (state: ApplicationRootState) => {
    return {
      ...state,
      localUserSettings: {
        ...state.localUserSettings,
        defaultChartDateRanges:
          localUserSettingsInitialState.defaultChartDateRanges
      }
    };
  }
};

const migrations: MigrationManifest = mapValues(
  stateMappers,
  (fn: StateMapper) => fn as PersistedStateMapper
);

export default migrations;
