import axios from 'axios';
import get from 'lodash/get';
import { all, fork, put, takeLatest } from 'redux-saga/effects';

import { userPreferenceClient } from 'clients';
import UserPreference from 'models/userPreference';

import {
  editMyPreference,
  editMyPreferenceFailure,
  editMyPreferenceSuccess,
  fetchMyPreferences,
  fetchMyPreferencesFailure,
  fetchMyPreferencesSuccess,
  setMyPreferences
} from './actions';
import ActionTypes from './constants';

// ==============================
// WORKER SAGAS
// ==============================
export function* requestFetchMyPreferences(action: ReturnType<typeof fetchMyPreferences>) {
  const { payload: { entityKind, entityId } } = action;

  try {
    const response = yield userPreferenceClient.fetchPreferences({ entityKind, entityId });
    yield put(fetchMyPreferencesSuccess(response));

    const preferences: UserPreference[] = response.data;
    yield put(setMyPreferences(preferences));

  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;

    const message = get(
      error,
      'response.data.message',
      `Failed to fetch preference(s) for ${entityKind} ${entityId}`
    );
    yield put(fetchMyPreferencesFailure(message, error));
  }
}

export function* requestEditMyPreference(action: ReturnType<typeof editMyPreference>) {
  const { payload: updateDTO } = action;

  try {
    const response = yield userPreferenceClient.editPreference(updateDTO);
    yield put(editMyPreferenceSuccess(response));

    const updatedPreference: UserPreference = response.data;
    yield put(setMyPreferences([updatedPreference]));

  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;

    const message = get(
      error,
      'response.data.message',
      `Failed to update preference for ${updateDTO.entityKind} ${updateDTO.entityId}`
    );
    yield put(editMyPreferenceFailure(message, error));
  }
}

// ==============================
// WATCHER SAGAS
// ==============================
export function* watchFetchMyPreferencesRequest() {
  yield takeLatest(ActionTypes.FETCH_MY_PREFERENCES_REQUEST, requestFetchMyPreferences);
}

export function* watchEditMyPreferenceRequest() {
  yield takeLatest(ActionTypes.EDIT_MY_PREFERENCE_REQUEST, requestEditMyPreference);
}

// ==============================
// EXPORTS
// ==============================
export default function* myPreferencesSaga() {
  yield all([
    fork(watchFetchMyPreferencesRequest),
    fork(watchEditMyPreferenceRequest)
  ]);
}
