import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from 'react-use';

import { search } from 'redux/modules/search/actions';
import makeSelectSearchResult from 'redux/modules/search/selectors';
import { SearchTypes } from 'redux/modules/search/types';

export default function useSearch(
  searchType: SearchTypes,
  searchTerm?: string
) {
  const dispatch = useDispatch();

  useDebounce(
    () => {
      if (searchTerm) {
        dispatch(search(searchType, searchTerm));
      }
    },
    300,
    [searchTerm]
  );

  const selectSearchResult = useMemo(
    () => makeSelectSearchResult(searchType),
    [searchType]
  );

  const searchResult = useSelector(selectSearchResult) ?? [];

  return searchResult;
}
