import { message as antdMessage } from 'antd';
import { AxiosError } from 'axios';
import { goBack } from 'connected-react-router';
import parsedPhoneNumber from 'libphonenumber-js';
import get from 'lodash/get';
import pick from 'lodash/pick';
import {
  all,
  call,
  delay,
  fork,
  put,
  select,
  takeLatest,
  takeLeading
} from 'redux-saga/effects';

import Membership, { UserRole } from 'models/membership';
import User from 'models/user';
import { selectCurrentEnterpriseId } from 'redux/modules/enterprise/selectors';
import parseBackendPhoneNumber from 'utils/phone-number/parse-backend-phone-number';
import {
  deleteRequest,
  patchRequest,
  postRequest
} from 'utils/redux-saga-requests';

import {
  addUser,
  addUserFailure,
  addUserSuccess,
  deleteUser,
  deleteUserFailure,
  editUser,
  editUserFailure,
  editUserSuccess,
  unsetUser,
  userLeaveEnterprise,
  userLeaveEnterpriseFailure,
  userLeaveEnterpriseSuccess
} from './actions';
import ActionTypes from './constants';
import { makeSelectUser } from './selectors';
import { appendValuesToQueryString } from '../routerUtils/actions';
import trackEvent from '../tracking/actions';
import TrackingActionType from '../tracking/constants';
import { sendTrackingEvent } from '../tracking/saga';
import { EventType } from '../tracking/types';

const getMobileNumber = (number: string, numberCountryCode: string) => {
  return number && numberCountryCode
    ? parsedPhoneNumber(`${numberCountryCode}${number}`)?.number || null
    : null;
};

export function* requestAddUser({
  payload: { values, onSuccess }
}: ReturnType<typeof addUser>) {
  const {
    firstName,
    lastName,
    username,
    mobileNumberCountryCode,
    mobileNumber,
    landlineNumberCountryCode,
    landlineNumber,
    enableSmsNotifications,
    invitation,
    enableEmailNotifications,
    password
  } = values;
  try {
    const mobileNumberValue = {
      mobileNumber: getMobileNumber(
        mobileNumber as string,
        mobileNumberCountryCode as string
      )
    };
    const landlineNumberValue = {
      landlineNumber: getMobileNumber(
        landlineNumber as string,
        landlineNumberCountryCode as string
      )
    };

    const { data } = yield call(postRequest, 'user/v1', {
      firstName,
      lastName,
      username,
      mobileNumberCountryCode: mobileNumberCountryCode || null,
      // Pass phone number in format needed by backend.
      ...mobileNumberValue,
      landlineNumberCountryCode: landlineNumberCountryCode || null,
      // Pass phone number in format needed by backend.
      ...landlineNumberValue,
      invitationId: invitation.sid,
      enableEmailNotifications,
      enableSmsNotifications,
      password
    });
    // Use `call` (saga) instead of `put` (action) to make sure the action is
    // finished before onSuccess is called.
    yield call(sendTrackingEvent, {
      type: TrackingActionType.TRACK_EVENT,
      payload: {
        event: {
          type: EventType.USER_SIGNED_UP,
          data: {
            userId: data.sid,
            email: invitation.email,
            isEnterpriseOwner: invitation.isEnterpriseOwner,
            entryPoint: 'Profile Set Up Page'
          }
        }
      }
    });
    // Since we can't tell if the tracking request is finished, we wait for extra
    // time. This request can take 100 - 700ms to finish with good network.
    yield delay(850);
    onSuccess?.();
    yield put(addUserSuccess(data));
  } catch (error) {
    yield put(
      addUserFailure('Sorry, something went wrong.', error as AxiosError)
    );
  }
}

export function* requestEditUser(action: ReturnType<typeof editUser>) {
  const {
    payload: {
      values: {
        title,
        firstName,
        lastName,
        username,
        role,
        mobileNumberCountryCode,
        mobileNumber,
        landlineNumberCountryCode,
        landlineNumber,
        enableSmsNotifications,
        email,
        enableEmailNotifications
      },
      userId
    }
  } = action;
  const prevUserData: User = yield select(makeSelectUser(Number(userId)));
  try {
    const mobileNumberValue = {
      mobileNumber: getMobileNumber(
        mobileNumber as string,
        mobileNumberCountryCode as string
      )
    };
    const landlineNumberValue = {
      landlineNumber: getMobileNumber(
        landlineNumber as string,
        landlineNumberCountryCode as string
      )
    };
    const currentEnterpriseId: number = yield select(selectCurrentEnterpriseId);

    const updatedMemberships = prevUserData.memberships?.map((m: Membership) =>
      pick(m, ['id', 'role', 'enterpriseId'])
    );
    const updatedMembership = updatedMemberships?.find(
      (m) => m.enterpriseId === currentEnterpriseId
    );
    if (updatedMembership && role) {
      updatedMembership.role = role as UserRole;
    } else if (!updatedMembership) {
      throw new Error('User has no membership yet.');
    } // It's okay for role to be undefined because it's a PATCH request.

    const { data } = yield call(patchRequest, `user/team-member/${userId}`, {
      title,
      firstName,
      lastName,
      username,
      memberships: updatedMemberships,
      mobileNumberCountryCode: mobileNumberCountryCode || null,
      // Pass phone number in format needed by backend.
      ...mobileNumberValue,
      landlineNumberCountryCode: landlineNumberCountryCode || null,
      // Pass phone number in format needed by backend.
      ...landlineNumberValue,
      email: email || null,
      enableEmailNotifications,
      enableSmsNotifications
    });
    let updatedUserData = parseBackendPhoneNumber(
      { ...prevUserData, ...data },
      'mobileNumber',
      'mobileNumberCountryCode',
      'mobileNumberFormatted'
    );
    updatedUserData = parseBackendPhoneNumber(
      updatedUserData,
      'landlineNumber',
      'landlineNumberCountryCode',
      'landlineNumberFormatted'
    );
    updatedUserData.memberships = updatedMemberships;
    yield put(editUserSuccess(updatedUserData));
    yield put(
      // TODO: should belong to commit 'refactor(ReactRouterV6): apply to User' but I don't know how to solve it yet
      appendValuesToQueryString({ modal: undefined, selectedUser: undefined })
    );
    antdMessage.success('User updated');
  } catch (error) {
    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );
    yield put(editUserFailure(message, error as AxiosError));
  }
}

// Delete the membership from current enterprise.
function* requestDeleteUser(action: ReturnType<typeof deleteUser>) {
  const { payload: userId } = action;
  const prevUserData: User = yield select(makeSelectUser(Number(userId)));
  const enterpriseId: number = yield select(selectCurrentEnterpriseId);
  const membershipId: number | undefined = prevUserData.memberships?.find(
    (m) => m.enterpriseId === enterpriseId
  )?.id;
  if (!membershipId) {
    antdMessage.error('Please refresh the team member page');
    throw new Error('User has no membership yet.');
  }
  try {
    yield call(deleteRequest, `user-membership/${membershipId}`);
    antdMessage.success('User removed');
    yield put(goBack());
    yield put(
      trackEvent({ type: EventType.USER_REMOVED, data: { status: true } })
    );
    yield put(unsetUser(userId));
  } catch (error) {
    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );
    antdMessage.error('Failed to remove user');
    yield put(deleteUserFailure(message, error as AxiosError));
    yield put(
      trackEvent({ type: EventType.USER_REMOVED, data: { status: false } })
    );
  }
}

function* requestUserLeaveEnterprise({
  payload: { membershipId }
}: ReturnType<typeof userLeaveEnterprise>) {
  try {
    yield call(deleteRequest, `user-membership/${membershipId}`);

    yield put(userLeaveEnterpriseSuccess(membershipId));
    antdMessage.success('You left the enterprise');
  } catch (error) {
    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );
    yield put(userLeaveEnterpriseFailure(message, error as AxiosError));
  }
}

export function* watchAddUserRequest() {
  yield takeLeading(ActionTypes.ADD_USER_REQUEST, requestAddUser);
}

export function* watchEditUserRequest() {
  yield takeLatest(ActionTypes.EDIT_USER_REQUEST, requestEditUser);
}

export function* watchDeleteUserRequest() {
  yield takeLatest(ActionTypes.DELETE_USER_REQUEST, requestDeleteUser);
}

export function* watchUserLeaveEnterpriseRequest() {
  yield takeLatest(
    ActionTypes.USER_LEAVE_ENTERPRISE_REQUEST,
    requestUserLeaveEnterprise
  );
}

export default function* myUserSaga() {
  yield all([
    fork(watchAddUserRequest),
    fork(watchEditUserRequest),
    fork(watchDeleteUserRequest),
    fork(watchUserLeaveEnterpriseRequest)
  ]);
}
