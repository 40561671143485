import { NotificationStatus } from 'models/notification';
import AuthActionTypes from 'redux/modules/auth/constants';
import { ApplicationActions } from 'redux/types';

import ActionTypes from './constants';
import { RecentNotificationsState } from './types';

export const initialState: RecentNotificationsState = {
  enterpriseId: null,
  data: {},
  pendingStatusChanges: {}
};

function recentNotificationsReducer(
  state: RecentNotificationsState = initialState,
  action: ApplicationActions
): RecentNotificationsState {
  switch (action.type) {
    case ActionTypes.SET_PENDING_ACKNOWLEDGEMENT: {
      const {
        payload: { notificationId }
      } = action;
      return {
        ...state,
        pendingStatusChanges: {
          ...state.pendingStatusChanges,
          [notificationId]: NotificationStatus.ACKNOWLEDGED
        }
      };
    }
    case ActionTypes.REMOVE_PENDING_ACKNOWLEDGEMENT: {
      const {
        payload: { notificationId }
      } = action;
      const newPendingStatusChanges = { ...state.pendingStatusChanges };
      delete newPendingStatusChanges[notificationId];
      return {
        ...state,
        pendingStatusChanges: newPendingStatusChanges
      };
    }
    case ActionTypes.SET_RECENT_NOTIFICATIONS: {
      const {
        payload: { enterpriseId, recentNotifications }
      } = action;
      return {
        ...state,
        enterpriseId,
        data: recentNotifications
      };
    }
    case ActionTypes.SET_RECENT_NOTIFICATION: {
      const { payload } = action;
      const { id } = payload;
      return {
        ...state,
        data: {
          ...state.data,
          [id]: { ...state.data[id], ...payload }
        }
      };
    }
    case AuthActionTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export default recentNotificationsReducer;
