import { DeleteFilled, PlusSquareOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Tooltip,
  Typography
} from 'antd';
import React, { memo, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import MFBNumericInput from 'components/atoms/MFBNumericInput';
import { FormContext } from 'components/composites/DefaultForm';
import DefaultFormItem from 'components/composites/DefaultFormItem';
import DrawerSimpleMap from 'components/composites/DrawerSimpleMap';
import StatusTag from 'components/composites/StatusTag';
import BackOfficeDeviceCameraForm from 'components/features/camera/BackOfficeDeviceCameraForm';
import FieldsValue from 'components/forms/FieldsValue';
import FieldValue from 'components/forms/FieldValue';
import FormItemCol from 'components/forms/FormItemCol';
import { MAX_ASSOCIATED_LORA_DEVICES } from 'constants/samplePoint';
import { LatLong } from 'models/asset';
import { EnterpriseId } from 'models/enterprise';
import { loadBackOfficeEnterpriseSites } from 'redux/modules/backOfficeEnterpriseSites/actions';
import useBackOfficeEnterpriseSites from 'redux/modules/backOfficeEnterpriseSites/hooks';
import { useCountry } from 'redux/modules/countries/hooks';
import { useEnterpriseCountry } from 'redux/modules/enterprise/hooks';
import { FILTER_NULL } from 'redux/modules/routerUtils/selectors';
import { SearchTypes } from 'redux/modules/search/types';
import useSearch from 'utils/use-search';

import './index.less';

interface Props {
  initialEnterpriseId?: number | null;
  initialSiteId?: number | null;
  location?: LatLong;
}

interface LoraDeviceDto {
  id?: number;
  loraSerialNumber: string;
  action?: string;
}

function BackOfficeDeviceFormItems({
  initialEnterpriseId,
  location,
  initialSiteId
}: Props): JSX.Element {
  const { formInstance } = useContext(FormContext);

  const [enterpriseId, setEnterpriseId] = useState<
    EnterpriseId | undefined | null
  >(initialEnterpriseId);

  const dispatch = useDispatch();

  const sites = useBackOfficeEnterpriseSites();

  const country = useEnterpriseCountry();
  const countryObj = useCountry(country);

  const siteOptions = useMemo(
    () => [
      { label: 'No Site', value: FILTER_NULL },
      ...sites.map((site) => ({
        label: site.name,
        value: site.id
      }))
    ],
    [sites]
  );

  // Fetch sites when enterprise changes
  useEffect(() => {
    if (enterpriseId) {
      dispatch(loadBackOfficeEnterpriseSites(enterpriseId));
    }
  }, [enterpriseId, dispatch]);

  useEffect(() => {
    if (enterpriseId === initialEnterpriseId) {
      formInstance?.setFieldsValue({
        // If site is already set, we default to it.
        // Defaults to 'No sites' otherwise.
        siteId: initialSiteId || FILTER_NULL
      });
    } else {
      formInstance?.setFieldsValue({
        // Selects first option on a new enterprise.
        // Defaults to 'No sites' otherwise.
        siteId: (enterpriseId && siteOptions?.[1]?.value) || FILTER_NULL
      });
    }
  }, [
    siteOptions,
    formInstance,
    enterpriseId,
    initialEnterpriseId,
    initialSiteId
  ]);

  const [enterpriseSearchTerm, setEnterpriseSearchTerm] = useState(
    enterpriseId ? enterpriseId.toString() : (enterpriseId as undefined)
  );

  const enterpriseSearchResults = useSearch(
    SearchTypes.UserEnterpriseMembershipSearch,
    enterpriseSearchTerm
  );

  const enterpriseOptions = useMemo(
    () => [
      ...(enterpriseSearchTerm
        ? []
        : [{ label: 'No Enterprise', value: FILTER_NULL }]),
      ...(enterpriseSearchResults || []).map(({ name, id }) => ({
        label: name,
        value: id
      }))
    ],
    [enterpriseSearchTerm, enterpriseSearchResults]
  );

  if (!countryObj) return <></>;

  return (
    <Col span={24}>
      <Space direction="vertical">
        <Typography.Text strong>Serial Number</Typography.Text>
        <div className="BODeviceForm-header">
          <FieldValue name="serialNumber">
            {(serialNumber: any) => (
              <Typography.Text
                strong
                className="BODeviceForm-header-serialnumber"
              >
                {serialNumber}
              </Typography.Text>
            )}
          </FieldValue>
          <FieldValue name="status">
            {(status: any) => (
              <div>
                <StatusTag status={status} />
              </div>
            )}
          </FieldValue>
        </div>
      </Space>
      <DefaultFormItem
        name="name"
        label="Name"
        rules={[
          {
            required: false,
            message: 'Please enter a name'
          }
        ]}
      >
        <Input id="device-name-input" />
      </DefaultFormItem>
      <DefaultFormItem name="enterpriseId" label="Enterprise" required>
        <Select
          id="enterprise-select"
          filterOption={false}
          showSearch
          onFocus={() => setEnterpriseSearchTerm(undefined)}
          onBlur={() => setEnterpriseSearchTerm(undefined)}
          onSearch={setEnterpriseSearchTerm}
          options={enterpriseOptions}
          placeholder="Enterprise"
          onSelect={(value) => {
            if (value === FILTER_NULL) {
              setEnterpriseId(null);
            } else {
              setEnterpriseId(Number(value));
            }

            setEnterpriseSearchTerm(undefined);
          }}
        />
      </DefaultFormItem>
      <DefaultFormItem
        name="siteId"
        label="Site"
        // Required only when an enterprise is selected.
        rules={[
          {
            required: !!enterpriseId,
            message: 'Please select a site.'
          }
        ]}
      >
        <Select
          id="site-select"
          options={siteOptions}
          disabled={!enterpriseId}
        />
      </DefaultFormItem>
      {/* Lat & Long fields are mandatory if either is filled. */}
      <FieldsValue names={['positionLat', 'positionLong']}>
        {({ positionLat, positionLong }) => (
          <>
            <DefaultFormItem
              name="positionLat"
              label="Manually Override Latitude"
              validateTrigger="onBlur"
              rules={[
                {
                  type: 'number',
                  min: -90,
                  max: 90,
                  transform: (value) => +value,
                  message: 'Please enter a valid latitude.'
                },
                {
                  required: !!positionLat || !!positionLong,
                  message: 'Please enter a latitude.'
                }
              ]}
            >
              <MFBNumericInput />
            </DefaultFormItem>

            <DefaultFormItem
              name="positionLong"
              label="Manually Override Longitude"
              validateTrigger="onBlur"
              rules={[
                {
                  type: 'number',
                  min: -180,
                  max: 180,
                  transform: (value) => +value,
                  message: 'Please enter a valid longitude.'
                },
                {
                  required: !!positionLat || !!positionLong,
                  message: 'Please enter a longitude.'
                }
              ]}
            >
              <MFBNumericInput />
            </DefaultFormItem>
          </>
        )}
      </FieldsValue>
      <DefaultFormItem label="LoRa Devices">
        <Form.List name="loraDevices">
          {(fields, { add, remove }) => {
            const maxAssociatedLoraDevicesReachedWarningMsg =
              fields.length >= MAX_ASSOCIATED_LORA_DEVICES
                ? `You can only add up to ${MAX_ASSOCIATED_LORA_DEVICES} LoRa devices.`
                : '';
            const updateLoraDevice = (index: number) => {
              const loraDevices =
                formInstance?.getFieldValue('loraDevices') || [];
              loraDevices[index].action = 'upsert';
              formInstance?.setFieldsValue({
                loraDevices
              });
            };
            const deleteLoraDevice = (index: number) => {
              formInstance?.setFields([
                {
                  name: ['loraDevices', index],
                  errors: []
                }
              ]);
              const loraDevices =
                formInstance?.getFieldValue('loraDevices') || [];
              if (loraDevices[index]?.id) {
                // Tag the existing lora device as deleted and wait for
                // submission.
                loraDevices[index].action = 'delete';
                formInstance?.setFieldsValue({
                  loraDevices
                });
              } else {
                // If the lora device is newly added without submission, remove
                // it directly.
                remove(index);
              }
            };
            return (
              <>
                {fields.map(({ name: idx, ...rest }: any) => {
                  return (
                    <Row key={idx} style={{ alignItems: 'baseline' }}>
                      <FieldValue name={['loraDevices', idx]}>
                        {(loraDevice: any) => {
                          return loraDevice &&
                            loraDevice.action === 'delete' ? null : (
                            <>
                              <Col span={17}>
                                <DefaultFormItem
                                  name={[idx, 'loraSerialNumber']}
                                  validateTrigger="onBlur"
                                  normalize={(v) => v?.trim()}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please enter a serial number.'
                                    },
                                    {
                                      validator: async (_, sn) => {
                                        const loraDevices =
                                          formInstance?.getFieldValue(
                                            'loraDevices'
                                          ) || [];
                                        if (!sn || !loraDevices)
                                          return Promise.resolve();
                                        if (
                                          loraDevices
                                            .filter(
                                              (ld?: LoraDeviceDto) =>
                                                ld !== undefined
                                            )
                                            .filter(
                                              ({
                                                loraSerialNumber
                                              }: Pick<
                                                LoraDeviceDto,
                                                'loraSerialNumber'
                                              >) => loraSerialNumber === sn
                                            ).length > 1
                                        ) {
                                          return Promise.reject();
                                        }
                                        return Promise.resolve(true);
                                      },
                                      message: 'Duplicate SN'
                                    }
                                  ]}
                                  {...rest}
                                >
                                  <Input
                                    placeholder="LoRa Serial Number"
                                    onChange={() => updateLoraDevice(idx)}
                                  />
                                </DefaultFormItem>
                              </Col>
                              <Col span={3} offset={1}>
                                <DeleteFilled
                                  onClick={() => deleteLoraDevice(idx)}
                                />
                              </Col>
                            </>
                          );
                        }}
                      </FieldValue>
                    </Row>
                  );
                })}
                <FormItemCol>
                  <Tooltip title={maxAssociatedLoraDevicesReachedWarningMsg}>
                    <Button
                      type="dashed"
                      block
                      icon={<PlusSquareOutlined />}
                      disabled={fields.length >= MAX_ASSOCIATED_LORA_DEVICES}
                      onClick={() => {
                        if (fields.length < MAX_ASSOCIATED_LORA_DEVICES) {
                          add();
                        }
                      }}
                    >
                      Add LoRa Device
                    </Button>
                  </Tooltip>
                </FormItemCol>
              </>
            );
          }}
        </Form.List>
      </DefaultFormItem>
      <BackOfficeDeviceCameraForm />
      <DrawerSimpleMap location={location} country={countryObj} />
    </Col>
  );
}

export default memo(BackOfficeDeviceFormItems);
