import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { DeviceId } from 'models/device';
import { loadBackOfficeDevice } from 'redux/modules/backOfficeDevice/actions';

interface Props {
  deviceId?: DeviceId;
}

function BackOfficeDeviceRequest({ deviceId }: Props): null {
  const dispatch = useDispatch();

  useEffect(() => {
    if (deviceId) {
      dispatch(loadBackOfficeDevice(deviceId));
    }
  }, [deviceId, dispatch]);

  return null;
}

export default BackOfficeDeviceRequest;
