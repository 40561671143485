import AuthActionTypes from 'redux/modules/auth/constants';
import { ApplicationActions } from 'redux/types';

import ActionTypes from './constants';
import { EnterpriseState } from './types';

export const initialState: EnterpriseState = null;

function enterpriseReducer(
  state: EnterpriseState = initialState,
  action: ApplicationActions
): EnterpriseState {
  switch (action.type) {
    case ActionTypes.SET_ENTERPRISE:
      return action.payload;
    case AuthActionTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export default enterpriseReducer;
