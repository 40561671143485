import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import classnames from 'classnames';
import React, { memo } from 'react';
import { useMediaQuery } from 'react-responsive';

import { LEGACY_MAX_WIDTH_TABLET_MEDIUM } from 'style/constants';

import './styles.less';

interface Props
  extends Pick<
    ButtonProps,
    | 'id'
    | 'block'
    | 'children'
    | 'className'
    | 'danger'
    | 'disabled'
    | 'htmlType'
    | 'loading'
    | 'onClick'
    | 'size'
  > {
  type?: ButtonProps['type'] | 'lets-go';
  mobileChildren?: string;
}

/**
 * A wrapper around the antd Button with our custom config applied.
 */
function DefaultButton({
  id,
  block,
  children,
  className,
  danger,
  disabled,
  htmlType,
  loading,
  mobileChildren,
  onClick,
  size,
  type
}: Props): JSX.Element {
  const isTabletOrMobile = useMediaQuery({ maxWidth: LEGACY_MAX_WIDTH_TABLET_MEDIUM });

  return (
    <Button
      id={id}
      block={block}
      className={classnames('DefaultButton', { 'green': type === 'lets-go' }, className)}
      danger={danger}
      type={type === 'lets-go' ? 'primary' : type}
      size={size}
      onClick={onClick}
      disabled={disabled}
      loading={loading}
      htmlType={htmlType}
    >
      {isTabletOrMobile && mobileChildren ? mobileChildren : children}
    </Button>
  );
}

export default memo(DefaultButton);
