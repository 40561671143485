import antdMessage from 'antd/lib/message';
import axios from 'axios';
import get from 'lodash/get';
import { SagaIterator } from 'redux-saga';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import Invitation from 'models/invitation';
import { selectBackOfficeInvitationsRequestParameters } from 'redux/modules/routerUtils/selectors';
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest
} from 'utils/redux-saga-requests';

import {
  addBackOfficeInvitation,
  addBackOfficeInvitationFailure,
  addBackOfficeInvitationSuccess,
  loadBackOfficeInvitations,
  loadBackOfficeInvitationsFailure,
  loadBackOfficeInvitationsSuccess,
  loadBackOfficeUserInvitations,
  loadBackOfficeUserInvitationsFailure,
  loadBackOfficeUserInvitationsSuccess,
  removeBackOfficeInvitation,
  removeBackOfficeInvitationFailure,
  removeBackOfficeInvitationSuccess,
  resendBackOfficeInvitation,
  resendBackOfficeInvitationFailure,
  resendBackOfficeInvitationSuccess
} from './actions';
import BackOfficeInvitationsActionType from './constants';
import {
  makeSelectInvitationById,
  makeSelectInvitationsByEmail
} from './selectors';
import { appendValuesToQueryString } from '../routerUtils/actions';
import trackEvent from '../tracking/actions';
import { EventType } from '../tracking/types';

const BASE_INVITATION_URL = 'back-office/invitation';
const BASE_INVITEE_URL = 'back-office/invitee';

function* requestBackOfficeInvitations(): SagaIterator {
  const params = yield select(selectBackOfficeInvitationsRequestParameters);

  try {
    const { data } = yield call(getRequest, 'back-office/invitee', {
      params
    });

    yield put(loadBackOfficeInvitationsSuccess(data));
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;

    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );

    yield put(loadBackOfficeInvitationsFailure(message, error));
  }
}

function* watchLoadBackOfficeInvitationsRequest() {
  yield takeLatest(
    BackOfficeInvitationsActionType.LOAD_BACK_OFFICE_INVITATIONS_REQUEST,
    requestBackOfficeInvitations
  );
}

function* requestBackOfficeUserInvitations({
  payload: { email }
}: ReturnType<typeof loadBackOfficeUserInvitations>) {
  try {
    const {
      data: [invitee]
    } = yield call(getRequest, BASE_INVITEE_URL, {
      params: {
        filter: `email::eq::${email.toLowerCase()}`
      }
    });
    if (invitee) {
      yield put(loadBackOfficeUserInvitationsSuccess(invitee));
    }
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;

    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );

    yield put(loadBackOfficeUserInvitationsFailure(message, error));
  }
}

function* requestAddBackOfficeUserInvitation({
  payload: { newInvitation, onSuccess }
}: ReturnType<typeof addBackOfficeInvitation>) {
  try {
    const { data } = yield call(postRequest, BASE_INVITATION_URL, {
      ...newInvitation
    });
    yield put(addBackOfficeInvitationSuccess(data));
    onSuccess?.();
    antdMessage.success('User successfully invited');
    yield put(loadBackOfficeInvitations());
    // Super-admin creation is not tracked.
    if (data.enterprise) {
      yield put(
        trackEvent({
          type: EventType.USER_INVITED,
          data: {
            inviteeId: data.sid,
            inviteeEmail: data.email,
            inviteeRole: data.role,
            inviteeIsEnterpriseOwner: data.isEnterpriseOwner,
            companyId: data.enterprise.sid,
            company: data.enterprise.name,
            entryPoint: 'Back office'
          }
        })
      );
    }
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );
    yield put(addBackOfficeInvitationFailure(message, error));
  }
}

function* requestRemoveBackOfficeUserInvitation({
  payload: { invitationId, onSuccess }
}: ReturnType<typeof removeBackOfficeInvitation>) {
  try {
    const { email } = yield select(makeSelectInvitationById(invitationId));
    yield call(deleteRequest, `${BASE_INVITATION_URL}/${invitationId}`);
    onSuccess();
    yield put(removeBackOfficeInvitationSuccess(invitationId, email));
    const invitations: Invitation[] = yield select(
      makeSelectInvitationsByEmail(email)
    );
    // If an invitee has no invitations, we have to close the drawer, because the
    // invitee is removed from the database. (Delete last invitation from users
    // tab is an exception but not handled yet.)
    if (!invitations.length) {
      yield put(
        appendValuesToQueryString({
          drawer: undefined,
          selectedUser: undefined,
          invitationEmail: undefined
        })
      );
    }
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;

    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );
    antdMessage.error('Failed to remove invitation');
    yield put(removeBackOfficeInvitationFailure(message, error));
  }
}

function* requestResendBackOfficeUserInvitation({
  payload: { invitationId }
}: ReturnType<typeof resendBackOfficeInvitation>) {
  try {
    yield call(putRequest, `${BASE_INVITATION_URL}/${invitationId}/resend`, {});
    antdMessage.success('Invitation resent successfully');
    yield put(resendBackOfficeInvitationSuccess());
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;

    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );

    yield put(resendBackOfficeInvitationFailure(message, error));
  }
}

function* watchAddBackOfficeInvitationRequest() {
  yield takeLatest(
    BackOfficeInvitationsActionType.ADD_BACK_OFFICE_INVITATION_REQUEST,
    requestAddBackOfficeUserInvitation
  );
}

function* watchRemoveBackOfficeInvitationRequest() {
  yield takeLatest(
    BackOfficeInvitationsActionType.REMOVE_BACK_OFFICE_INVITATION_REQUEST,
    requestRemoveBackOfficeUserInvitation
  );
}

function* watchResendBackOfficeInvitationRequest() {
  yield takeLatest(
    BackOfficeInvitationsActionType.RESEND_BACK_OFFICE_INVITATION_REQUEST,
    requestResendBackOfficeUserInvitation
  );
}

function* watchLoadBackOfficeUserInvitationsRequest() {
  yield takeLatest(
    BackOfficeInvitationsActionType.LOAD_BACK_OFFICE_USER_INVITATIONS_REQUEST,
    requestBackOfficeUserInvitations
  );
}

export default function* backOfficeInvitationsSaga() {
  yield all([
    fork(watchAddBackOfficeInvitationRequest),
    fork(watchRemoveBackOfficeInvitationRequest),
    fork(watchResendBackOfficeInvitationRequest),
    fork(watchLoadBackOfficeUserInvitationsRequest),
    fork(watchLoadBackOfficeInvitationsRequest)
  ]);
}
