import { AxiosError, AxiosResponse } from 'axios';
import { action } from 'typesafe-actions';

import { EnterpriseId } from 'models/enterprise';
import Site from 'models/site';

import ActionTypes from './constants';

export const loadBackOfficeEnterpriseSites = (enterpriseId: EnterpriseId) =>
  action(ActionTypes.LOAD_BACK_OFFICE_ENTERPRISE_SITES_REQUEST, {
    enterpriseId
  });

export const loadBackOfficeEnterpriseSitesSuccess = (response: AxiosResponse) =>
  action(ActionTypes.LOAD_BACK_OFFICE_ENTERPRISE_SITES_SUCCESS, { response });

export const loadBackOfficeEnterpriseSitesFailure = (
  message: string,
  error?: AxiosError
) =>
  action(
    ActionTypes.LOAD_BACK_OFFICE_ENTERPRISE_SITES_FAILURE,
    { message, error },
    undefined,
    true
  );

export const setBackOfficeEnterpriseSites = (sites: Site[]) =>
  action(ActionTypes.SET_BACK_OFFICE_ENTERPRISE_SITES, { sites });
