import { message as antdMessage } from 'antd';
import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import type { SagaIterator } from 'redux-saga';
import {
  all,
  call,
  fork,
  put,
  select,
  take,
  takeLatest
} from 'redux-saga/effects';

import { AssetTypeCode } from 'models/assetType';
import Device from 'models/device';
import SamplePoint from 'models/samplePoint';
import { setBackOfficeDevice } from 'redux/modules/backOfficeDevice/actions';
import { makeSelectIsLoading } from 'redux/modules/loading/selectors';
import { selectBackOfficeDevicesRequestParameters } from 'redux/modules/routerUtils/selectors';
import {
  getRequest,
  patchRequest,
  postRequest
} from 'utils/redux-saga-requests';

import {
  addBackOfficeDevice,
  addBackOfficeDeviceFailure,
  addBackOfficeDeviceSuccess,
  editBackOfficeDevice,
  editBackOfficeDeviceFailure,
  editBackOfficeDeviceSuccess,
  loadBackOfficeDeviceCameraSamplePointsFailure,
  loadBackOfficeDeviceCameraSamplePointsSuccess,
  loadBackOfficeDevices,
  loadBackOfficeDevicesFailure,
  loadBackOfficeDevicesSuccess,
  setBackOfficeDevices
} from './actions';
import ActionTypes from './constants';
import { selectBackOfficeDeviceCameraSamplePoints } from './selectors';
import { BackOfficeDeviceCameraFilterValues } from './types';
import { appendValuesToQueryString } from '../routerUtils/actions';

/**
 * Get array of all camera samplepoints.
 */
export function* getCameraSamplePoints() {
  // If the request for the camera samplepoints is still in progress, we need to
  // wait for it to finish.
  const cameraSamplePointsAreLoading: boolean = yield select(
    makeSelectIsLoading(['LOAD_BACK_OFFICE_DEVICE_CAMERA_SAMPLEPOINTS'])
  );

  if (cameraSamplePointsAreLoading) {
    yield take([
      ActionTypes.LOAD_BACK_OFFICE_DEVICE_CAMERA_SAMPLEPOINTS_SUCCESS,
      ActionTypes.LOAD_BACK_OFFICE_DEVICE_CAMERA_SAMPLEPOINTS_FAILURE
    ]);
  }

  const cameraSamplePoints: SamplePoint[] = yield select(
    selectBackOfficeDeviceCameraSamplePoints
  );

  return cameraSamplePoints;
}

export function* requestBackOfficeDevices(): SagaIterator {
  const params = yield select(selectBackOfficeDevicesRequestParameters);

  // Create a deep clone to the params object, so we can modify it freely
  // without causing other references to the params object to also change.
  const clonedParams = cloneDeep(params);

  try {
    // Need to sort by createdAt DESC by default, but this can be overriden
    // by route query params. Ant design's columns allow column headers to
    // set the route query params for sorting, but their 'defaultSortOrder'
    // prop doesn't do this. As such, we need to add that functionality
    // ourselves here.
    const paramsWithDefaultSortOrder = {
      ...clonedParams,
      ...(clonedParams.sort
        ? {
          sort: clonedParams.sort.some((sortValue: string) => sortValue.includes('createdAt'))
            ? clonedParams.sort
            : [...clonedParams.sort, 'createdAt,DESC'] // Sort by createdAt last
        } : {})
    };

    // Check if the 'camera' filter condition exists.
    const cameraParams = paramsWithDefaultSortOrder.s?.$and.find(
      ({ camera }: any) => !!camera
    );

    // If it does, we need to get a list of all deviceIds that have cameras, and
    // filter by that.
    if (cameraParams) {
      const cameraSamplePoints = yield* getCameraSamplePoints();

      cameraParams.id = {
        [cameraParams.camera.$in.pop() ===
          BackOfficeDeviceCameraFilterValues.WITH_CAMERA
          ? '$in'
          : '$notin']: [
            ...new Set(cameraSamplePoints.map(({ deviceId }) => deviceId))
          ]
      };

      delete cameraParams.camera;
    }

    const { data } = yield call(
      getRequest,
      'device?join=site&join=enterprise',
      { params: paramsWithDefaultSortOrder }
    );

    yield all([
      put(loadBackOfficeDevicesSuccess(data)),
      put(
        setBackOfficeDevices({
          total: data.total,
          data: keyBy(data.data, 'id'),
          ids: data.data.map((device: Device) => device.id)
        })
      )
    ]);
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;

    const message =
      error.message === 'Network Error'
        ? error.message
        : get(error, 'response.data.message', 'Sorry, something went wrong.');

    yield put(loadBackOfficeDevicesFailure(message, error));
  }
}

export function* watchLoadBackOfficeDevicesRequest() {
  yield takeLatest(
    ActionTypes.LOAD_BACK_OFFICE_DEVICES_REQUEST,
    requestBackOfficeDevices
  );
}

export function* requestAddBackOfficeDevice(
  action: ReturnType<typeof addBackOfficeDevice>
) {
  const {
    payload: { values }
  } = action;

  try {
    const { data } = yield call(postRequest, 'device', {
      ...values
    });

    yield all([
      put(loadBackOfficeDevices()),
      put(addBackOfficeDeviceSuccess(data)),
      put(
        appendValuesToQueryString({
          selectedDevice: undefined
        })
      )
    ]);

    antdMessage.success('Device added');
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;

    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );

    antdMessage.error('Failed to add device');
    yield put(addBackOfficeDeviceFailure(message, error));
  }
}

export function* watchAddBackOfficeDevicesRequest() {
  yield takeLatest(
    ActionTypes.ADD_BACK_OFFICE_DEVICE_REQUEST,
    requestAddBackOfficeDevice
  );
}

export function* requestEditBackOfficeDevice(
  action: ReturnType<typeof editBackOfficeDevice>
) {
  const {
    payload: { deviceId, values }
  } = action;

  try {
    const { data } = yield call(patchRequest, `device/${deviceId}`, {
      ...values
    });

    yield all([
      put(setBackOfficeDevice(data)),
      // TODO: cleanup, why reload?
      put(loadBackOfficeDevices()),
      // TODO: cleanup, success action does nothing
      put(editBackOfficeDeviceSuccess(data)),
      put(
        appendValuesToQueryString({
          selectedDevice: undefined
        })
      )
    ]);

    antdMessage.success('Device edited');
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );

    antdMessage.error('Failed to edit device');
    yield put(editBackOfficeDeviceFailure(message, error));
  }
}

export function* watchEditBackOfficeDevicesRequest() {
  yield takeLatest(
    ActionTypes.EDIT_BACK_OFFICE_DEVICE_REQUEST,
    requestEditBackOfficeDevice
  );
}

export function* requestBackOfficeDeviceCameraSamplePoints() {
  try {
    const { data } = yield call(
      getRequest,
      `samplepoint?fields=deviceId,assetTypeId&filter=assetTypeId::$eq::${AssetTypeCode.CAMERA}`
    );

    yield put(loadBackOfficeDeviceCameraSamplePointsSuccess(data));
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );

    antdMessage.error('Failed to retrieve camera samplepoints');
    yield put(loadBackOfficeDeviceCameraSamplePointsFailure(message, error));
  }
}

export function* watchLoadBackOfficeDeviceCameraSamplePointsRequest() {
  yield takeLatest(
    ActionTypes.LOAD_BACK_OFFICE_DEVICE_CAMERA_SAMPLEPOINTS_REQUEST,
    requestBackOfficeDeviceCameraSamplePoints
  );
}

export default function* backOfficeDevicesSaga() {
  yield all([
    fork(watchLoadBackOfficeDevicesRequest),
    fork(watchAddBackOfficeDevicesRequest),
    fork(watchEditBackOfficeDevicesRequest),
    fork(watchLoadBackOfficeDeviceCameraSamplePointsRequest)
  ]);
}
