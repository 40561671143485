import Select, { SelectProps } from 'antd/lib/select';
import Tag from 'antd/lib/tag';
import React from 'react';

import DefaultFormItem, {
  DefaultFormItemProps
} from 'components/composites/DefaultFormItem';
import { UserRole, UserRoleLabels } from 'models/membership';

const roleOptions = [
  UserRole.VIEW_ONLY,
  UserRole.SUPERVISOR,
  UserRole.ADMIN
].map((role) => ({
  label: UserRoleLabels[role],
  value: role
}));

const defaultRules = [
  {
    required: true,
    message: 'Please select a role'
  }
];

type Props = DefaultFormItemProps &
  Pick<SelectProps<UserRole>, 'placeholder' | 'disabled'>;

export default function FormItemRole({
  label = 'Role',
  name = 'role',
  placeholder = 'Role',
  rules = defaultRules,
  disabled,
  ...rest
}: Props) {
  return (
    <DefaultFormItem
      label={label}
      name={name}
      rules={rules}
      formatReadOnlyValue={(value) => <Tag>{value?.toUpperCase()}</Tag>}
      {...rest}
    >
      <Select
        placeholder={placeholder}
        disabled={disabled}
        options={roleOptions}
      />
    </DefaultFormItem>
  );
}
