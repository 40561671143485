import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { UserId } from 'models/user';
import { loadBackOfficeUser } from 'redux/modules/backOfficeUser/actions';

interface Props {
  userId?: UserId;
}

function BackOfficeUserRequest({ userId }: Props): null {
  const dispatch = useDispatch();

  useEffect(() => {
    if (userId) {
      dispatch(loadBackOfficeUser(userId));
    }
  }, [userId, dispatch]);

  return null;
}

export default BackOfficeUserRequest;
