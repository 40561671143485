import Device, { DeviceId } from 'models/device';
import SamplePoint from 'models/samplePoint';

export type DeviceFilter<T> = {
  value: T | null;
  count: number;
};

export type DeviceFilters = Record<
  DeviceFilterKey,
  DeviceFilter<Device[DeviceFilterKey]>[]
>;

export type DataSource = {
  id: string;
  name: string;
  environment: string;
  groupId: string;
};

export type DataSources = Record<number, DataSource>;

type DeviceFilterKey = keyof Pick<
  Device,
  | 'make'
  | 'model'
  | 'fwVersion'
  | 'hwVersion'
  | 'libVersion'
  | 'backhaulDesc'
  | 'backhaulType'
>;

export interface BackOfficeDevicesState {
  total: number | undefined;
  data: Record<string, Device>;
  ids: DeviceId[];
  deviceFilters?: DeviceFilters;
  dataSources?: DataSources;
  cameraSamplePoints: SamplePoint[];
}

export enum BackOfficeDeviceCameraFilterValues {
  WITH_CAMERA = 'withCamera',
  WITHOUT_CAMERA = 'withoutCamera'
}
