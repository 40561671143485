import { message as antdMessage } from 'antd';
import axios from 'axios';
import get from 'lodash/get';
import type { SagaIterator } from 'redux-saga';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';

import { getRequest, putRequest } from 'utils/redux-saga-requests';

import {
  editNotificationPolicy,
  editNotificationPolicyFailure,
  editNotificationPolicySuccess,
  loadNotificationPolicy,
  loadNotificationPolicyFailure,
  loadNotificationPolicySuccess,
  setNotificationPolicy
} from './actions';
import ActionTypes from './constants';

export function* requestNotificationPolicy(
  action: ReturnType<typeof loadNotificationPolicy>
): SagaIterator {
  const {
    payload: { siteId }
  } = action;
  try {
    const response = yield call(
      getRequest,
      `notification-policy/site/${siteId}`
    );
    const { data } = response;
    const values = { siteId, data };
    yield all([
      put(setNotificationPolicy(values)),
      put(loadNotificationPolicySuccess(response))
    ]);
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );
    yield put(loadNotificationPolicyFailure(message, error));
  }
}

export function* watchLoadNotificationPolicyRequest() {
  yield takeLatest(
    ActionTypes.LOAD_NOTIFICATION_POLICY_REQUEST,
    requestNotificationPolicy
  );
}

export function* requestEditNotificationPolicy(
  action: ReturnType<typeof editNotificationPolicy>
): SagaIterator {
  const {
    payload: { siteId, notificationPolicy }
  } = action;
  // FMBT-1728 Temporarily workaround
  const updatedNotificationPolicy = notificationPolicy?.data.map((policy) => ({
    ...policy,
    userIds: policy.userIds.filter((id) => typeof id === 'number')
  }));
  try {
    const response = yield call(
      putRequest,
      `notification-policy/site/${siteId}`,
      updatedNotificationPolicy
    );

    const { data } = response;

    yield all([
      put(setNotificationPolicy({ siteId, data })),
      put(editNotificationPolicySuccess(response))
    ]);

    antdMessage.success('Team notifications updated');
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );
    antdMessage.error('Failed to update team notifications');
    yield put(editNotificationPolicyFailure(message, error));
  }
}

export function* watchEditNotificationPolicyRequest() {
  yield takeLatest(
    ActionTypes.EDIT_NOTIFICATION_POLICY_REQUEST,
    requestEditNotificationPolicy
  );
}

export default function* notificationPolicySaga() {
  yield all([
    fork(watchLoadNotificationPolicyRequest),
    fork(watchEditNotificationPolicyRequest)
  ]);
}
