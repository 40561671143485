export const MAX_ASSOCIATED_LORA_DEVICES = 11;

export const OFFSET_MAX_CM = 600;
export const OFFSET_MAX_IN = 240;
export const OFFSET_MIN_CM = -600;
export const OFFSET_MIN_IN = -240;

export const BORE_MAX_DEPTH_M = 300;
export const BORE_MAX_DEPTH_FT = 1000;

export const TANK_MAX_HEIGHT_CM = 900;
export const TANK_MAX_HEIGHT_IN = 790;

export const TROUGH_MAX_HEIGHT_CM = 125;
export const TROUGH_MAX_HEIGHT_IN = 50;

export const DEFAULT_PRECISION_IMPERIAL = 2;
export const DEFAULT_PRECISION_METRIC = 1;
export const DEFAULT_PERCENTAGE_PRECISION = 0;
export const WATER_TANK_LEVEL_PRECISION = 1;
export const WATER_TANK_VOLUME_PRECISION = 0;
export const DAM_PRECISION = 2;
export const LINE_PRESSURE_PRECISION = 1;
export const LIQUID_FERTILISER_TANK_VOLUME_PRECISION = WATER_TANK_VOLUME_PRECISION;
export const BORE_LEVEL_PRECISION = 2;
// Cannot be higher because the trigger precision is limited to 0.01 m by BE.
// For example, 3.22 ft is 0.98m (0.9815m) but 3.23 ft is also 0.98m (0.9845m).
export const BORE_LEVEL_TRIGGER_PRECISION_IMPERIAL = 1;
export const PIPE_FLOW_RATE_PRECISION = 1;
export const PIPE_VOLUME_PRECISION_IMPERIAL = 1;
export const PIPE_VOLUME_PRECISION_METRIC = 0;
export const RAIN_GAUGE_PRECISION_IMPERIAL = 2;
export const RAIN_GAUGE_PRECISION_METRIC = 1;
export const WIRELESS_TROUGH_LEVEL_PRECISION = 1;
export const TROUGH_DIRECT_PRECISION = 1;
export const TROUGH_DIRECT_DEFAULT_HEIGHT_CM = 15;
export const TROUGH_DIRECT_DEFAULT_HEIGHT_IN = 6;

export const CHART_DEFAULT_DATE_RANGE = 'day.14';
export const RAIN_GAUGE_CHART_DEFAULT_DATE_RANGE = 'day.30';
export const FLOW_CHART_DEFAULT_DATE_RANGE = 'hour.48';
export const SAFETY_CHECK_IN_DEFAULT_DATE_RANGE = 'month.1';

export const LEGACY_DAILY_RAINFALL_RESET_HOUR = 0;
export const DEFAULT_DAILY_RAINFALL_RESET_HOUR = 9;
export const DEFAULT_DAILY_RAINFALL_RESET_HOUR_US = 7;
