import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectMyUser } from 'redux/modules/myUser/selectors';
import trackEvent from 'redux/modules/tracking/actions';
import { EventType } from 'redux/modules/tracking/types';

/**
 * A component for setting the user id for track in Google Analytics.
 */
function GoogleAnalyticsSetUserId(): null {
  const dispatch = useDispatch();
  const myUser = useSelector(selectMyUser);

  useEffect(() => {
    if (myUser) {
      const {
        sid: userId,
        firstName,
        lastName,
        email,
        username,
        createdAt
      } = myUser;

      if (userId && firstName && lastName && email && username && createdAt) {
        dispatch(
          trackEvent({ type: EventType.SET_USER, data: { status: true } })
        );
      }
    }
  }, [dispatch, myUser]);

  return null;
}

export default GoogleAnalyticsSetUserId;
