import isEqual from 'lodash/isEqual';
import React, { Suspense, lazy } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import ErrorBoundaryFallback from 'components/atoms/ErrorBoundaryFallback';
import LoadingSpinner from 'components/composites/LoadingSpinner';
// Don't lazily import this or production build will fail
import ProtectedRoutesLoadingGate from 'components/loadingGates/ProtectedRoutesLoadingGate';
import { ROUTE_PATHS } from 'constants/routePaths';
import {
  selectHasOnlyOneMembership,
  selectMyUserIsSuperAdmin,
  selectMyUserMembershipsEnterprises
} from 'redux/modules/myUser/selectors';
import BackOffice from 'routes/BackOffice';
import constructEnterpriseDomain from 'utils/construct-enterprise-domain';
import getCurrentSubdomain from 'utils/get-current-subdomain';

import Integrations from './Integrations';
import InstallAgriWebb from './Integrations/AgriWebb/Install';
import NewUser from './NewUser';

const Enterprise = lazy(() => import('routes/Enterprise'));
const MyMemberships = lazy(() => import('routes/MyMemberships'));
const PageNotFound = lazy(() => import('routes/PageNotFound'));

const { REACT_APP_URL_DEFAULT_SUBDOMAIN } = process.env;

function MainRoutes(): JSX.Element | null {
  const myUserIsSuperAdmin = useSelector(selectMyUserIsSuperAdmin);
  const hasOnlyOneMembership = useSelector(selectHasOnlyOneMembership);
  const currentSubdomain = getCurrentSubdomain();
  const myUserMembershipsEnterprises = useSelector(
    selectMyUserMembershipsEnterprises,
    isEqual
  );
  const myUserBelongsToCurrentEnterprise =
    currentSubdomain &&
    myUserMembershipsEnterprises.some(
      ({ id, subdomain }) =>
        subdomain === currentSubdomain || `${id}` === currentSubdomain
    );
  const enterprise = myUserMembershipsEnterprises[0];
  const currentEnterpriseSubdomain = constructEnterpriseDomain(enterprise);

  if (
    currentSubdomain &&
    currentSubdomain !== REACT_APP_URL_DEFAULT_SUBDOMAIN &&
    (myUserBelongsToCurrentEnterprise || myUserIsSuperAdmin)
  ) {
    return (
      <Suspense fallback={<LoadingSpinner useLogo />}>
        <Switch>
          <Route path={ROUTE_PATHS.enterprise._index} component={Enterprise} />
          <Redirect from="/*" to={ROUTE_PATHS.enterprise._index} />
        </Switch>
      </Suspense>
    );
  }

  if (myUserIsSuperAdmin) {
    return (
      <Suspense fallback={<LoadingSpinner />}>
        <Switch>
          <Route path="/back-office">
            <BackOffice />
          </Route>
          <Route path="/connections">
            <Integrations />
          </Route>
          <Redirect from="/*" to="/back-office" />
        </Switch>
      </Suspense>
    );
  }

  if (hasOnlyOneMembership) {
    return (
      <Suspense fallback={<LoadingSpinner useLogo />}>
        <Switch>
          <Route path="/enterprises">
            <MyMemberships />
          </Route>
          <Route path="/connections">
            <Integrations />
          </Route>
          <Route
            path="/*"
            component={() => {
              window.location.replace(currentEnterpriseSubdomain);
              return null;
            }}
          />
        </Switch>
      </Suspense>
    );
  }

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Switch>
        <Route path="/connections">
          <Integrations />
        </Route>
        <Route path="/*">
          <MyMemberships />
        </Route>
      </Switch>
    </Suspense>
  );
}

const Routes = () => (
  <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
    <Switch>
      {/* FIX: subdomain can access this link too */}
      <Route exact path="/invite/:token">
        <NewUser />
      </Route>
      <Route exact path="/connections/agriwebb/install">
        <InstallAgriWebb />
      </Route>
      <Route exact path="/404">
        <Suspense fallback={<LoadingSpinner useLogo />}>
          <PageNotFound />
        </Suspense>
      </Route>
      <Route path="/*">
        <ProtectedRoutesLoadingGate>
          <>
            <MainRoutes />
          </>
        </ProtectedRoutesLoadingGate>
      </Route>
    </Switch>
  </ErrorBoundary>
);

export default Routes;
