import { AxiosError, AxiosResponse } from 'axios';
import { action } from 'typesafe-actions';

import { FetchIPCameraPhotosParams } from 'clients/ipCamera';
import { EnterpriseId } from 'models/enterprise';
import IPCamera, { IPCameraEditableFields, IPCameraEvent, IPCameraMetadata, IPCameraPTZPresetEditableFields, IPCameraPhoto, IPCameraPreview, PTZControl, PTZPreset } from 'models/ipCamera';
import { SiteId } from 'models/site';

import ActionTypes from './constants';
import { IPCameraPhotosState, IPCamerasState } from './types';

// ================================================
// IP CAMERA LIST
// ================================================
export const fetchCamerasBySite = (enterpriseId: EnterpriseId, siteId: SiteId) =>
  action(ActionTypes.FETCH_CAMERAS_BY_SITE_REQUEST, { enterpriseId, siteId });

export const fetchCamerasBySiteSuccess = (ipCameras: IPCamerasState) =>
  action(ActionTypes.FETCH_CAMERAS_BY_SITE_SUCCESS, ipCameras);

export const fetchCamerasBySiteFailure = () =>
  action(ActionTypes.FETCH_CAMERAS_BY_SITE_FAILURE);

// ================================================
// IP CAMERA DETAILS
// ================================================
export const fetchCameraById = (id: number) =>
  action(ActionTypes.FETCH_CAMERA_BY_ID_REQUEST, { id });

export const fetchCameraByIdSuccess = (ipCamera: IPCamera) =>
  action(ActionTypes.FETCH_CAMERA_BY_ID_SUCCESS, ipCamera);

// ================================================
// IP CAMERA PREVIEW
// ================================================
export const takeIPCameraPreview = (ipCameraId: number) =>
  action(ActionTypes.TAKE_IP_CAMERA_PREVIEW_REQUEST, { ipCameraId });

export const takeIPCameraPreviewSuccess = (response?: AxiosResponse) =>
  action(ActionTypes.TAKE_IP_CAMERA_PREVIEW_SUCCESS, { response });

export const takeIPCameraPreviewFailure = (message: string, error?: AxiosError) =>
  action(
    ActionTypes.TAKE_IP_CAMERA_PREVIEW_FAILURE,
    { message, error },
    undefined,
    true
  );

export const setIPCameraPreview = (ipCameraId: number, preview: IPCameraPreview) =>
  action(ActionTypes.SET_IP_CAMERA_PREVIEW, { ipCameraId, preview });

export const setIPCameraPreviewError = (ipCameraId: number, error: string) =>
  action(ActionTypes.SET_IP_CAMERA_PREVIEW_ERROR, { ipCameraId, error });

// ================================================
// IP CAMERA STATE (e.g. online, ptz capabilities)
// ================================================
export const fetchIPCameraState = (ipCameraId: number) =>
  action(ActionTypes.FETCH_IP_CAMERA_STATE_REQUEST, { ipCameraId });

export const fetchIPCameraStateSuccess = (response?: AxiosResponse) =>
  action(ActionTypes.FETCH_IP_CAMERA_STATE_SUCCESS, { response });

export const fetchIPCameraStateFailure = (message: string, error?: AxiosError) =>
  action(
    ActionTypes.FETCH_IP_CAMERA_STATE_FAILURE,
    { message, error },
    undefined,
    true
  );

export const setIPCameraState = (ipCameraId: number, metadata: IPCameraMetadata) =>
  action(ActionTypes.SET_IP_CAMERA_STATE, { ipCameraId, metadata });

// ================================================
// IP CAMERA PHOTOS
// ================================================
export const fetchIPCameraPhotos = (ipCameraId: number, params: FetchIPCameraPhotosParams) =>
  action(ActionTypes.FETCH_IP_CAMERA_PHOTOS_REQUEST, { ipCameraId, params });

export const fetchIPCameraPhotosSuccess = (response?: AxiosResponse) =>
  action(ActionTypes.FETCH_IP_CAMERA_PHOTOS_SUCCESS, { response });

export const fetchIPCameraPhotosFailure = (message: string, error?: AxiosError) =>
  action(
    ActionTypes.FETCH_IP_CAMERA_PHOTOS_FAILURE,
    { message, error },
    undefined,
    true
  );

export const setIPCameraPhotos = (ipCameraId: number, photoList: IPCameraPhotosState) =>
  action(ActionTypes.SET_IP_CAMERA_PHOTOS, { ipCameraId, photoList });

export const takeIPCameraPhoto = (ipCameraId: number) =>
  action(ActionTypes.TAKE_IP_CAMERA_PHOTO_REQUEST, { ipCameraId });

export const takeIPCameraPhotoSuccess = (response: AxiosResponse) =>
  action(ActionTypes.TAKE_IP_CAMERA_PHOTO_SUCCESS, { response });

export const takeIPCameraPhotoFailure = (message: string, error?: AxiosError) =>
  action(
    ActionTypes.TAKE_IP_CAMERA_PHOTO_FAILURE,
    { message, error },
    undefined,
    true
  );

export const setIPCameraUnseenPhoto = (ipCameraId: number, newPhoto: IPCameraPhoto) =>
  action(ActionTypes.SET_IP_CAMERA_UNSEEN_PHOTO, { ipCameraId, newPhoto });

export const resetIPCameraUnseenPhoto = (ipCameraId: number) =>
  action(ActionTypes.SET_IP_CAMERA_UNSEEN_PHOTO, { ipCameraId, newPhoto: undefined });

// ================================================
// IP CAMERA PTZ
// ================================================
export const sendCameraPTZControlRequest = (id: number, control: PTZControl) =>
  action(ActionTypes.SEND_CAMERA_PTZ_CONTROL_REQUEST, { id, control });

// ================================================
// IP CAMERA EVENTS
// ================================================
export const fetchIPCameraEvents = (ipCameraId: number, startDate: string, endDate: string, isAppend: boolean) =>
  action(ActionTypes.FETCH_IP_CAMERA_EVENTS_REQUEST, { ipCameraId, startDate, endDate, isAppend });

export const fetchIPCameraEventsSuccess = (response?: AxiosResponse) =>
  action(ActionTypes.FETCH_IP_CAMERA_EVENTS_SUCCESS, { response });

export const fetchIPCameraEventsFailure = (message: string, error?: AxiosError) =>
  action(
    ActionTypes.FETCH_IP_CAMERA_EVENTS_FAILURE,
    { message, error },
    undefined,
    true
  );

export const setIPCameraEvents = (ipCameraId: number, events: IPCameraEvent[]) =>
  action(ActionTypes.SET_IP_CAMERA_EVENTS, { ipCameraId, events });

export const resetIPCameraEvents = (ipCameraId: number) =>
  action(ActionTypes.SET_IP_CAMERA_EVENTS, { ipCameraId, events: undefined });

export const appendIPCameraEvents = (ipCameraId: number, events: IPCameraEvent[]) =>
  action(ActionTypes.APPEND_IP_CAMERA_EVENTS, { ipCameraId, events });

// ================================================
// IP CAMERA EDIT
// ================================================
export const editIPCamera = (id: number, values: IPCameraEditableFields) =>
  action(ActionTypes.EDIT_IP_CAMERA_REQUEST, { id, values });

export const editIPCameraSuccess = (response: AxiosResponse) =>
  action(ActionTypes.EDIT_IP_CAMERA_SUCCESS, { response });

export const editIPCameraFailure = (message: string, error?: AxiosError) =>
  action(
    ActionTypes.EDIT_IP_CAMERA_FAILURE,
    { message, error },
    undefined,
    true
  );

// ================================================
// IP CAMERA REMOVAL
// ================================================
export const removeIPCamera = (id: number) =>
  action(ActionTypes.REMOVE_IP_CAMERA_REQUEST, { id });

export const removeIPCameraSuccess = (response: AxiosResponse) =>
  action(ActionTypes.REMOVE_IP_CAMERA_SUCCESS, { response });

export const removeIPCameraFailure = (message: string, error?: AxiosError) =>
  action(
    ActionTypes.REMOVE_IP_CAMERA_FAILURE,
    { message, error },
    undefined,
    true
  );

export const removeFromIPCameraList = (id: number) =>
  action(ActionTypes.REMOVE_FROM_IP_CAMERA_LIST, { id });

// ================================================
// IP CAMERA PTZ PRESETS
// ================================================
export const fetchIPCameraPTZPresetsRequest = (ipCameraId: number) =>
  action(ActionTypes.FETCH_IP_CAMERA_PTZ_PRESETS_REQUEST, { ipCameraId });
export const fetchIPCameraPTZPresetsSuccess = (response?: AxiosResponse) =>
  action(ActionTypes.FETCH_IP_CAMERA_PTZ_PRESETS_SUCCESS, { response });
export const fetchIPCameraPTZPresetsFailure = (message: string, error?: AxiosError) =>
  action(
    ActionTypes.FETCH_IP_CAMERA_PTZ_PRESETS_FAILURE,
    { message, error },
    undefined,
    true
  );

export const createIPCameraPTZPresetRequest = (ipCameraId: number) =>
  action(ActionTypes.CREATE_IP_CAMERA_PTZ_PRESET_REQUEST, { ipCameraId });
export const createIPCameraPTZPresetSuccess = (response?: AxiosResponse) =>
  action(ActionTypes.CREATE_IP_CAMERA_PTZ_PRESET_SUCCESS, { response });
export const createIPCameraPTZPresetFailure = (message: string, error?: AxiosError) =>
  action(
    ActionTypes.CREATE_IP_CAMERA_PTZ_PRESET_FAILURE,
    { message, error },
    undefined,
    true
  );

export const activateIPCameraPTZPresetRequest = (ipCameraId: number, presetId: number) =>
  action(ActionTypes.ACTIVATE_IP_CAMERA_PTZ_PRESET_REQUEST, { ipCameraId, presetId });
export const activateIPCameraPTZPresetSuccess = (response?: AxiosResponse) =>
  action(ActionTypes.ACTIVATE_IP_CAMERA_PTZ_PRESET_SUCCESS, { response });
export const activateIPCameraPTZPresetFailure = (message: string, error?: AxiosError) =>
  action(
    ActionTypes.ACTIVATE_IP_CAMERA_PTZ_PRESET_FAILURE,
    { message, error },
    undefined,
    true
  );

export const updateIPCameraPTZPresetRequest = (
  ipCameraId: number,
  presetId: number,
  values: IPCameraPTZPresetEditableFields
) => action(ActionTypes.UPDATE_IP_CAMERA_PTZ_PRESET_REQUEST, { ipCameraId, presetId, values });
export const updateIPCameraPTZPresetSuccess = (response?: AxiosResponse) =>
  action(ActionTypes.UPDATE_IP_CAMERA_PTZ_PRESET_SUCCESS, { response });
export const updateIPCameraPTZPresetFailure = (message: string, error?: AxiosError) =>
  action(
    ActionTypes.UPDATE_IP_CAMERA_PTZ_PRESET_FAILURE,
    { message, error },
    undefined,
    true
  );

export const deleteIPCameraPTZPresetRequest = (ipCameraId: number, presetId: number) =>
  action(ActionTypes.DELETE_IP_CAMERA_PTZ_PRESET_REQUEST, { ipCameraId, presetId });
export const deleteIPCameraPTZPresetSuccess = (response?: AxiosResponse) =>
  action(ActionTypes.DELETE_IP_CAMERA_PTZ_PRESET_SUCCESS, { response });
export const deleteIPCameraPTZPresetFailure = (message: string, error?: AxiosError) =>
  action(
    ActionTypes.DELETE_IP_CAMERA_PTZ_PRESET_FAILURE,
    { message, error },
    undefined,
    true
  );

export const setIPCameraPTZPresets = (ipCameraId, presets: PTZPreset[]) =>
  action(ActionTypes.SET_IP_CAMERA_PTZ_PRESETS, { ipCameraId, presets });
export const setIPCameraPTZPreset = (ipCameraId, preset: PTZPreset) =>
  action(ActionTypes.SET_IP_CAMERA_PTZ_PRESET, { ipCameraId, preset });
export const removeIPCameraPTZPreset = (ipCameraId: number, presetId: number) =>
  action(ActionTypes.REMOVE_IP_CAMERA_PTZ_PRESET, { ipCameraId, presetId });