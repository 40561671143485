import { useAuth0 } from '@auth0/auth0-react';
import { Divider, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import DefaultButton from 'components/atoms/DefaultButton';
import DefaultCard from 'components/atoms/DefaultCard';
import ErrorBoundaryFallback from 'components/atoms/ErrorBoundaryFallback';
import FarmbotLogoHorizontal from 'components/atoms/FarmbotLogoHorizontal';
import HelmetWrapper from 'components/atoms/HelmetWrapper';
import PageHeaderPusher from 'components/atoms/PageHeaderPusher';
import DefaultForm from 'components/composites/DefaultForm';
import DefaultPageHeader from 'components/composites/DefaultPageHeader';
import LoadingSpinner from 'components/composites/LoadingSpinner';
import PasswordFormItems from 'components/forms/PasswordFormItems';
import UserFormItems from 'components/forms/UserFormItems';
import useTranslation from 'hooks/useTranslation';
import Invitation from 'models/invitation';
import { loadInvitation } from 'redux/modules/invitations/actions';
import InvitationActionType from 'redux/modules/invitations/constants';
import { makeSelectIsLoading } from 'redux/modules/loading/selectors';
import { addUser } from 'redux/modules/users/actions';
import UserActionType from 'redux/modules/users/constants';
import { isRanchbot } from 'utils/is-x-bot';

import './styles.less';

const TERMS_AND_CONDITIONS_LINK_FARMBOT =
  'https://farmbot.com.au/terms-and-conditions/';
const TERMS_AND_CONDITIONS_LINK_RANCHBOT =
  'https://ranch-bot.com/pages/terms-conditions';
const PRIVACY_POLICY_LINK_FARMBOT = 'https://farmbot.com.au/privacy-policy/';
const PRIVACY_POLICY_LINK_RANCHBOT =
  'https://ranch-bot.com/pages/privacy-policy';

const selectIsInvitationLoading = makeSelectIsLoading([
  InvitationActionType.LOAD_INVITATION_REQUEST
]);
const selectIsAddUserLoading = makeSelectIsLoading([
  UserActionType.ADD_USER_REQUEST
]);

export default function NewUser() {
  const { t } = useTranslation();
  const isInvitationLoading = useSelector(selectIsInvitationLoading);
  const isAddUserLoading = useSelector(selectIsAddUserLoading);
  const dispatch = useDispatch();
  const [form] = useForm();
  const { token } = useParams() as { token: string };
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const [invitation, setInvitation] = useState<Invitation | undefined>();
  const [email, setEmail] = useState<string>('');
  const [isNewCustomer, setIsNewCustomer] = useState<boolean>(true);

  useEffect(() => {
    try {
      const { id } = JSON.parse(atob(token));
      dispatch(
        loadInvitation(id, (i) => {
          setEmail(i.email);
          setIsNewCustomer(i.isNewCustomer);
          setInvitation(i);
        })
      );
    } catch {
      setEmail('');
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (isAuthenticated || !isNewCustomer) {
      // Go to invitation list
      loginWithRedirect({
        appState: { target: `${window.location.origin}/enterprises` }
      });
    }
  }, [isAuthenticated, isNewCustomer, loginWithRedirect]);

  const submit = useCallback(() => {
    form
      .validateFields()
      .then((values) => {
        // Flow: Sign up -> enterprise/invitation list -> Auth0 sign in
        // -> enterprise/invitation list
        dispatch(
          addUser({ ...values, invitation }, () =>
            loginWithRedirect({
              appState: { target: `${window.location.origin}/enterprises` },
              login_hint: email
            })
          )
        );
      })
      .catch(() => { }); // Ignore promise rejection.
  }, [dispatch, email, form, invitation, loginWithRedirect]);

  // If user is authenticated, loading the spinner while redirecting
  if (isInvitationLoading || isAuthenticated) {
    return <Suspense fallback={<LoadingSpinner useLogo />} />;
  }

  if (!email) {
    return (
      <div className="NewUser-invitation-not-found">
        <FarmbotLogoHorizontal style={{ maxWidth: 440, margin: '2rem' }} />
        <Typography.Title level={2}>Invitation not found</Typography.Title>
        <Typography.Title level={5} type="secondary" style={{ marginTop: 0 }}>
          Please make sure the invitation is not expired.
        </Typography.Title>
      </div>
    );
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <HelmetWrapper section="Profile Set Up" />
      <DefaultPageHeader title="Profile Set Up" />
      <PageHeaderPusher>
        <div className="NewUser">
          <Typography.Title level={5}>Profile Set Up</Typography.Title>
          <DefaultForm
            form={form}
            layout="vertical"
            name="newUser"
            initialValues={{
              enableEmailNotifications: true,
              enableSmsNotifications: true
            }}
            onFinish={submit}
            requiredMark="optional"
          >
            <DefaultCard className="NewUser-card">
              <UserFormItems isNewUser />
            </DefaultCard>
            <DefaultCard className="NewUser-card">
              <PasswordFormItems />
              <Divider />
              <div className="NewUser-footer">
                <div>
                  {`By continuing, I accept ${t('FARMBOT')}’s `}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={
                      isRanchbot()
                        ? TERMS_AND_CONDITIONS_LINK_RANCHBOT
                        : TERMS_AND_CONDITIONS_LINK_FARMBOT
                    }
                  >
                    Terms and Conditions
                  </a>{' '}
                  and{' '}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={
                      isRanchbot()
                        ? PRIVACY_POLICY_LINK_RANCHBOT
                        : PRIVACY_POLICY_LINK_FARMBOT
                    }
                  >
                    Privacy Policy
                  </a>
                  .
                </div>
                <DefaultButton
                  type="primary"
                  htmlType="submit"
                  className="NewUser-footer-button"
                  onClick={submit}
                  loading={isAddUserLoading}
                >
                  Submit
                </DefaultButton>
              </div>
            </DefaultCard>
          </DefaultForm>
        </div>
      </PageHeaderPusher>
    </ErrorBoundary>
  );
}
