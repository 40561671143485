import { useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';

import { loadBackOfficeInvitations } from 'redux/modules/backOfficeInvitations/actions';
import { selectBackOfficeInvitationsRequestParameters } from 'redux/modules/routerUtils/selectors';

export default function BackOfficeInvitationsRequest() {
  const dispatch = useDispatch();

  const backOfficeInvitationsRequestParameters = useSelector(
    selectBackOfficeInvitationsRequestParameters
  );

  useDeepCompareEffect(() => {
    dispatch(loadBackOfficeInvitations());
  }, [dispatch, backOfficeInvitationsRequestParameters]);

  return null;
}
