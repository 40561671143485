import { Alert, Button } from 'antd';
import { FormInstance } from 'antd/lib/form';
import isEqual from 'lodash/isEqual';
import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';

import DefaultForm from 'components/composites/DefaultForm';
import DefaultFormItem from 'components/composites/DefaultFormItem';
import BackOfficeDeviceFormItems from 'components/forms/BackOfficeDeviceFormItems';
import { BackOfficeDevice } from 'redux/modules/backOfficeDevice/types';
import { makeSelectFirstErrorMessage } from 'redux/modules/error/selectors';
import { FILTER_NULL } from 'redux/modules/routerUtils/selectors';
import { getDeviceLocation } from 'utils/get-location';

const actions = ['EDIT_BACK_OFFICE_DEVICE'];

const selectErrorMessage = makeSelectFirstErrorMessage(actions);

interface Props {
  form: FormInstance;
  initialValues?: BackOfficeDevice;
  onFinish?: (values: Record<string, unknown>) => void;
  isLoading?: boolean;
}

function BackOfficeDeviceForm({
  form,
  initialValues,
  onFinish,
  isLoading
}: Props): JSX.Element | null {
  const errorMessage = useSelector(selectErrorMessage);

  useDeepCompareEffect(() => {
    form.resetFields();
  }, [form, initialValues]);

  const parsedInitialValues = useMemo(() => {
    if (!initialValues) {
      return initialValues;
    }
    const parsed: Record<string, unknown> = {};
    // We want to be able to explicitly set enterpriseId & siteId to be null in
    // the form, but the form dropdown cannot use 'null' as an options' value.
    // Instead, we map to a constant FILTER_NULL when filling the form, and map
    // back to 'null' on form submission.
    Object.entries(initialValues).forEach(([key, value]) => {
      if (['enterpriseId', 'siteId'].includes(key)) {
        parsed[key] = value ? Number(value) : FILTER_NULL;
      } else {
        parsed[key] = value;
      }
    });

    return parsed;
  }, [initialValues]);

  const location = useMemo(
    () => getDeviceLocation(initialValues),
    [initialValues]
  );

  return (
    <DefaultForm
      form={form}
      layout="vertical"
      name="backOfficeDevice"
      autoComplete="chrome-off"
      initialValues={parsedInitialValues}
      onFinish={isLoading ? undefined : onFinish}
      requiredMark="optional"
      scrollToFirstError
      closeDrawerOnSubmit
      closeDrawerIfFieldsAreValid={false}
    >
      <BackOfficeDeviceFormItems
        initialEnterpriseId={initialValues?.enterpriseId}
        initialSiteId={initialValues?.siteId}
        location={location}
      />
      {errorMessage && (
        <DefaultFormItem>
          <Alert message={errorMessage} type="error" />
        </DefaultFormItem>
      )}
      <Button htmlType="submit" style={{ display: 'none' }} />
    </DefaultForm>
  );
}

export default memo(BackOfficeDeviceForm, isEqual);
