import { AxiosError, AxiosResponse } from 'axios';
import { action } from 'typesafe-actions';

import Device, { DeviceId } from 'models/device';
import SamplePoint from 'models/samplePoint';

import ActionTypes from './constants';
import { DataSources, DeviceFilters } from './types';

export const loadBackOfficeDevices = () =>
  action(ActionTypes.LOAD_BACK_OFFICE_DEVICES_REQUEST);

export const loadBackOfficeDevicesSuccess = (response: AxiosResponse) =>
  action(ActionTypes.LOAD_BACK_OFFICE_DEVICES_SUCCESS, { response });

export const loadBackOfficeDevicesFailure = (
  message: string,
  error?: AxiosError
) =>
  action(
    ActionTypes.LOAD_BACK_OFFICE_DEVICES_FAILURE,
    { message, error },
    undefined,
    true
  );

export const addBackOfficeDevice = (values: Record<string, unknown>) =>
  action(ActionTypes.ADD_BACK_OFFICE_DEVICE_REQUEST, { values });

export const addBackOfficeDeviceSuccess = (response: AxiosResponse) =>
  action(ActionTypes.ADD_BACK_OFFICE_DEVICE_SUCCESS, { response });

export const addBackOfficeDeviceFailure = (
  message: string,
  error?: AxiosError
) =>
  action(
    ActionTypes.ADD_BACK_OFFICE_DEVICE_FAILURE,
    { message, error },
    undefined,
    true
  );

export const editBackOfficeDevice = (
  deviceId: DeviceId,
  values: Record<string, unknown>
) =>
  action(ActionTypes.EDIT_BACK_OFFICE_DEVICE_REQUEST, {
    deviceId,
    values
  });

export const editBackOfficeDeviceSuccess = (response: AxiosResponse) =>
  action(ActionTypes.EDIT_BACK_OFFICE_DEVICE_SUCCESS, { response });

export const editBackOfficeDeviceFailure = (
  message: string,
  error?: AxiosError
) =>
  action(
    ActionTypes.EDIT_BACK_OFFICE_DEVICE_FAILURE,
    { message, error },
    undefined,
    true
  );

export const setBackOfficeDevices = (payload: {
  total: number;
  data: Record<string, Device>;
  ids: DeviceId[];
}) => action(ActionTypes.SET_BACK_OFFICE_DEVICES, payload);

export const setBackOfficeDeviceFilters = (payload: DeviceFilters) =>
  action(ActionTypes.SET_BACK_OFFICE_DEVICE_FILTERS, payload);

export const setBackOfficeDeviceDataSources = (payload: DataSources) =>
  action(ActionTypes.SET_BACK_OFFICE_DEVICE_DATA_SOURCES, payload);

export const loadBackOfficeDeviceCameraSamplePoints = () =>
  action(ActionTypes.LOAD_BACK_OFFICE_DEVICE_CAMERA_SAMPLEPOINTS_REQUEST);

export const loadBackOfficeDeviceCameraSamplePointsSuccess = (
  samplePoints: SamplePoint[]
) =>
  action(ActionTypes.LOAD_BACK_OFFICE_DEVICE_CAMERA_SAMPLEPOINTS_SUCCESS, {
    samplePoints
  });

export const loadBackOfficeDeviceCameraSamplePointsFailure = (
  message: string,
  error?: AxiosError
) =>
  action(
    ActionTypes.LOAD_BACK_OFFICE_DEVICE_CAMERA_SAMPLEPOINTS_FAILURE,
    { message, error },
    undefined,
    true
  );
