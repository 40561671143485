import { action } from 'typesafe-actions';

import Device, { DeviceId } from 'models/device';

import ActionTypes from './constants';

export const setDevices = (devices: Record<DeviceId, Device>) =>
  action(ActionTypes.SET_DEVICES, devices);

export const setDevice = (device: Device) =>
  action(ActionTypes.SET_DEVICE, device);

export const updateDevice = (device: Device) =>
  action(ActionTypes.UPDATE_DEVICE, device);

export const editDevice = (
  deviceId: DeviceId,
  values: Record<string, unknown>
) =>
  action(ActionTypes.EDIT_DEVICE_REQUEST, {
    deviceId,
    values
  });

export const editDeviceSuccess = () => action(ActionTypes.EDIT_DEVICE_SUCCESS);
export const editDeviceFailure = () => action(ActionTypes.EDIT_DEVICE_FAILURE);