import AuthActionTypes from 'redux/modules/auth/constants';
import { ApplicationActions } from 'redux/types';

import ActionTypes from './constants';
import { SamplesState } from './types';

export const initialState: SamplesState = {
  samplePointSamples: [],
  controlPointSamples: []
};

function samplesReducer(
  state: SamplesState = initialState,
  action: ApplicationActions
): SamplesState {
  switch (action.type) {
    case ActionTypes.SET_SAMPLE_POINT_SAMPLES:
      return {
        ...state,
        samplePointSamples: action.payload
      };
    case ActionTypes.SET_CONTROL_POINT_SAMPLES:
      return {
        ...state,
        controlPointSamples: action.payload
      };
    case AuthActionTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export default samplesReducer;
