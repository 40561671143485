import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { EnterpriseId } from 'models/enterprise';
import {
  loadBackOfficeEnterprise,
  setBackOfficeEnterprise
} from 'redux/modules/backOfficeEnterprise/actions';

interface Props {
  enterpriseId?: EnterpriseId;
}

function BackOfficeEnterpriseRequest({ enterpriseId }: Props): null {
  const dispatch = useDispatch();

  useEffect(() => {
    if (enterpriseId) {
      dispatch(loadBackOfficeEnterprise(enterpriseId));
      return () => {
        dispatch(setBackOfficeEnterprise(null));
      };
    }
    // eslint complains otherwise
    return () => {};
  }, [enterpriseId, dispatch]);

  return null;
}

export default BackOfficeEnterpriseRequest;
