import { Button, Input, Space } from 'antd';
import useFormInstance from 'antd/lib/form/hooks/useFormInstance';
import React, { memo } from 'react';
import { useDispatch } from 'react-redux';

import DefaultFormItem from 'components/composites/DefaultFormItem';
import { AssetTypeCode } from 'models/assetType';
import { CAMERA_DATA_PLAN_BASE_KB } from 'models/camera';
import SamplePoint from 'models/samplePoint';
import { editSamplePoint } from 'redux/modules/samplePoints/actions';

import { getMorePhotosEmailHref } from '../helpers';

import './styles.less';

function CameraDataIndicator({ percentage }: { percentage: string }) {
  return (
    <div className="CameraDataUsage-indicator">
      <div
        className="CameraDataUsage-indicator-inner"
        style={{ width: percentage }}
      />
    </div>
  );
}

function CameraDataUsageText({
  title,
  total,
  amountLeft
}: {
  title: string;
  total: number;
  amountLeft: number;
}) {
  return (
    <div className="CameraDataUsage-text">
      <div className="CameraDataUsage-text-title">{title}</div>
      <div>
        <div className="CameraDataUsage-text-data">
          {amountLeft.toLocaleString()} KB left
        </div>
        <div> of {total.toLocaleString()} KB</div>
      </div>
    </div>
  );
}

interface Props {
  samplePoint?: SamplePoint;
  isBackOffice?: boolean;
}

function CameraDataUsage({ samplePoint, isBackOffice }: Props) {
  const dispatch = useDispatch();
  const backOfficeDeviceForm = useFormInstance();

  if (
    !samplePoint ||
    !samplePoint.config ||
    samplePoint.assetTypeId !== AssetTypeCode.CAMERA
  )
    return null;

  const {
    config: { maxKb, kbConsumed }
  } = samplePoint;

  if (maxKb === undefined || kbConsumed === undefined) return null;

  const updatePlanDataAmount = (dataAdjusted: string) =>
    dataAdjusted &&
    dispatch(
      editSamplePoint(samplePoint, {
        config: {
          ...samplePoint.config,
          maxKb: maxKb + Number(dataAdjusted)
        }
      })
    );

  const dataPlanTotal = CAMERA_DATA_PLAN_BASE_KB;
  const dataPlanLeft = Math.max(0, dataPlanTotal - kbConsumed);
  const dataPlanPercentage = `${Math.round(
    (1 - dataPlanLeft / dataPlanTotal) * 100
  )}%`;
  const dataAddOnTotal = Math.max(0, maxKb - dataPlanTotal);
  const dataAddOnLeft = Math.max(
    0,
    dataAddOnTotal - Math.max(0, kbConsumed - dataPlanTotal)
  );
  const dataAddOnPercentage = `${Math.round(
    (1 - dataAddOnLeft / dataAddOnTotal) * 100
  )}%`;

  return (
    <Space direction="vertical" className="CameraDataUsage">
      <CameraDataUsageText
        title="Data plan"
        total={dataPlanTotal}
        amountLeft={dataPlanLeft}
      />
      <CameraDataIndicator percentage={dataPlanPercentage} />
      {!!dataAddOnTotal && (
        <>
          <CameraDataUsageText
            title="Data add-on"
            total={dataAddOnTotal}
            amountLeft={dataAddOnLeft}
          />
          <CameraDataIndicator percentage={dataAddOnPercentage} />
        </>
      )}
      {isBackOffice ? (
        <>
          <br />
          <DefaultFormItem
            label="Data adjustment (KB)"
            name="dataAdjusted"
            rules={[
              {
                message: 'Must be a whole number.',
                pattern: /^-?\d*$/
              }
            ]}
          >
            <div className="CameraDataUsage-dataAdjustment">
              <Input />
              <Button
                type="primary"
                onClick={() => updatePlanDataAmount(backOfficeDeviceForm.getFieldValue('dataAdjusted'))}
              >
                Submit
              </Button>
            </div>
          </DefaultFormItem>
        </>
      ) : (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={getMorePhotosEmailHref}
        >
          Get more photos
        </a>
      )}
    </Space>
  );
}

export default memo(CameraDataUsage);
