/**
 * The my user state selectors
 */

import { createSelector } from 'reselect';

import Enterprise from 'models/enterprise';
import Membership, { UserRole } from 'models/membership';
import { selectCurrentEnterpriseId } from 'redux/modules/enterprise/selectors';
import { ApplicationRootState } from 'redux/types';
import createDeepEqualSelector from 'utils/create-deep-equal-selector';

const selectMyUserState = (state: ApplicationRootState) => state.myUser;

const selectMyUserMembershipRole = createSelector(
  [selectMyUserState, selectCurrentEnterpriseId],
  (myUserState, enterpriseId) => {
    const currentMembership: Membership | null | undefined =
      myUserState?.memberships &&
      myUserState.memberships.find(
        (membership) => membership.enterpriseId === enterpriseId
      );
    return currentMembership && currentMembership.role;
  }
);

const selectMyUser = createDeepEqualSelector(
  [selectMyUserState, selectMyUserMembershipRole],
  (myUserState, membershipRole) => {
    if (!myUserState) {
      return null;
    }

    const { firstName, lastName } = myUserState;

    return {
      ...myUserState,
      fullName: `${firstName} ${lastName}`,
      role: membershipRole
    };
  }
);

const selectMyUserExists = createSelector(
  selectMyUser,
  // Check myUser !== undefined is not enough. When users signed in, we store
  // user id (from Auth0) alone into myUser, then init the requestMyUser. If
  // we manage to get the complete user info, there must be a username.
  (myUser) => myUser && !!myUser.username
);

const selectMyUserMemberships = createSelector(
  selectMyUser,
  (myUser) => myUser && myUser.memberships
);

const selectMyUserIsSuperAdmin = createSelector(
  selectMyUser,
  (myUser) => myUser && myUser.isSuperAdmin
);

const selectMyUserIsAdmin = createSelector(
  selectMyUser,
  (myUser) => myUser && myUser.role === UserRole.ADMIN
);

const selectMyUserIsEmailVerified = createSelector(
  selectMyUser,
  (myUser) => myUser && myUser.isEmailVerified
);

const makeSelectMyUserMembership = (enterpriseId: number) => {
  return createSelector(
    selectMyUserMemberships,
    (myUserMemberships) =>
      myUserMemberships &&
      myUserMemberships.find(
        (element: Membership) => element.enterpriseId === enterpriseId
      )
  );
};

const selectMyUserMembershipsEnterprises = createSelector(
  selectMyUserMemberships,
  (myUserMemberships) =>
    myUserMemberships
      ? myUserMemberships
          .map((membership) => membership.enterprise)
          // Filter falsy values.
          .filter<Enterprise>((e): e is Enterprise => !!e)
      : []
);

const makeSelectMyUserMembershipsEnterprisesByRoles = (roles: UserRole[]) =>
  createSelector(selectMyUserMemberships, (myUserMemberships) =>
    myUserMemberships
      ? myUserMemberships
          .filter((m) => roles.includes(m.role))
          .map((m) => m.enterprise)
          // Filter falsy values.
          .filter<Enterprise>((e): e is Enterprise => !!e)
      : []
  );

const selectMyUserMembershipsEnterpriseIds = createSelector(
  selectMyUserMembershipsEnterprises,
  (myUserMembershipsEnterprises) =>
    myUserMembershipsEnterprises.map(({ id }) => id)
);

const selectHasMultipleMemberships = createSelector(
  selectMyUserMembershipsEnterpriseIds,
  (myUserMembershipsEnterprises) => myUserMembershipsEnterprises.length > 1
);

const selectHasOnlyOneMembership = createSelector(
  selectMyUserMembershipsEnterpriseIds,
  (myUserMembershipsEnterprises) => myUserMembershipsEnterprises.length === 1
);

const makeSelectUserRoleGranted = (userRoles: UserRole[]) =>
  createSelector(
    selectMyUserIsSuperAdmin,
    selectMyUserMembershipRole,
    (isSuperAdmin, role) =>
      isSuperAdmin ? true : !!(role && userRoles.includes(role))
  );

const makeSelectIsMyUser = (id: number) =>
  createSelector(selectMyUser, (myUser) => !!myUser && myUser.id === id);

export {
  selectMyUser,
  selectMyUserExists,
  selectMyUserIsAdmin,
  selectMyUserIsSuperAdmin,
  selectMyUserMemberships,
  makeSelectMyUserMembership,
  selectMyUserMembershipsEnterprises,
  makeSelectMyUserMembershipsEnterprisesByRoles,
  selectMyUserMembershipsEnterpriseIds,
  selectHasMultipleMemberships,
  selectHasOnlyOneMembership,
  selectMyUserMembershipRole,
  makeSelectUserRoleGranted,
  selectMyUserIsEmailVerified,
  makeSelectIsMyUser
};
