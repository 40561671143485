import axios, { AxiosResponse } from 'axios';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import moment from 'moment-timezone';
import { all, call, put, select } from 'redux-saga/effects';

import { LEGACY_DAILY_RAINFALL_RESET_HOUR } from 'constants/samplePoint';
import { DEFAULT_TIMEZONE_CODE } from 'constants/time';
import { AssetTypeCode } from 'models/assetType';
import HttpStatusCode from 'models/httpStatusCode.enum';
import Sample from 'models/sample';
import SamplePoint from 'models/samplePoint';
import { selectCurrentEnterpriseCountry } from 'redux/modules/enterprise/selectors';
import { makeSelectSamplePointsBySids } from 'redux/modules/samplePoints/selectors';
import { loadComparativeSamples, loadComparativeSamplesFailure, loadComparativeSamplesSuccess, setSamplePointSamples } from 'redux/modules/samples/actions';
import convertMetricToImperial from 'utils/convert-metric-to-imperial';
import { getRequest } from 'utils/redux-saga-requests';
import { getRainfallSamplePeriodDates } from 'utils/Sample/get-rainfall-sample-dates';

import { getUnitTypeByAssetTypeId } from './utils';

export function* requestComparativeSamples(
  action: ReturnType<typeof loadComparativeSamples>
) {
  const { dateRanges, samplePointSids } = action.payload;

  /* Retrieve base data */
  const samplePoints: SamplePoint[] = yield select(makeSelectSamplePointsBySids(samplePointSids));
  const assetTypeId = samplePoints[0].assetTypeId; // assume sample points under comparison are of the same assetType
  const country: string = yield select(selectCurrentEnterpriseCountry);

  /* Construct requests */
  const requests: {
    path: string,
    params: { startDate: string, endDate: string, interval: string }
  }[] = [];

  dateRanges.forEach(({ start, end, interval, timezoneCode }) => {
    samplePoints.forEach((samplePoint) => {
      const startDateString = moment(start).tz(timezoneCode).format();
      const endDateString = moment(end).tz(timezoneCode).format();

      requests.push(
        {
          path: `samplepoint/${samplePoint.sid}/v2/hourly-samples`,
          params: { startDate: startDateString, endDate: endDateString, interval }
        });
    });
  });

  /* Fetch and transform samples data */
  try {
    const responses: AxiosResponse[] = yield all(requests.map(({ path, params }) => call(getRequest, path, { params })));

    const samples: Sample[] = [];

    responses.forEach(({ data: sampleData }, index) => {
      const isComparingSameSamplePoint = samplePoints.length === 1;
      const currentSamplePoint = isComparingSameSamplePoint
        ? samplePoints[0]
        : samplePoints[index];

      const rainfallResetTime = currentSamplePoint.site?.rainfallResetTime ?? LEGACY_DAILY_RAINFALL_RESET_HOUR;
      // Here we read from site.timezoneCode instead of sp.siteTimezoneCode because makeSelectSamplePointsBySids doesn't
      // assign time zone code to sample points like selectSamplePoints does.
      const timezoneCode = currentSamplePoint.site?.timezoneCode || DEFAULT_TIMEZONE_CODE;

      sampleData.forEach(sample => {
        const { prevDate, date } =
          assetTypeId === AssetTypeCode.RAIN_GAUGE
            ? getRainfallSamplePeriodDates(sample.date, sample.interval, rainfallResetTime, timezoneCode)
            /**
             * @unimplemented
             * If other assetTypes start to have comparison feature,
             * the following logic must be implemented accordingly.
             */
            : { prevDate: 0, date: sample.date };

        samples.push({
          ...sample,
          id: `${currentSamplePoint.id}_${sample.date}`,
          samplePointId: currentSamplePoint.id,
          rwValue: convertMetricToImperial(
            getUnitTypeByAssetTypeId(assetTypeId),
            country,
            sample.rwValue
          ) ?? 0,
          date,
          prevDate
        });
      });
    });

    yield all([
      put(loadComparativeSamplesSuccess(responses)),
      put(setSamplePointSamples(keyBy(samples, 'id')))
    ]);
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    let message;
    if (error.message === 'Network Error') {
      message = 'Network Error';
    } else {
      message = get(
        error,
        'response.data.message',
        'Sorry, something went wrong.'
      );
    }
    if (error.response?.status === HttpStatusCode.NOT_FOUND) {
      // Likely means the sample point has been removed
      const samplePointSid = error.response.config.url?.split('/')[1];
      const samplePointName = samplePoints.find(sp => sp.sid === samplePointSid)?.name;
      if (samplePointName) {
        message = `Could not compare with ${samplePointName} as it was removed`;
      } else {
        message = 'Could not compare with one of the sensors as it was removed';
      }
    }
    yield put(loadComparativeSamplesFailure(message, error));
  }
}