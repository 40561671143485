import { AxiosError, AxiosResponse } from 'axios';
import { action } from 'typesafe-actions';

import { EnterpriseId } from 'models/enterprise';
import Notification, { NotificationId } from 'models/notification';

import ActionTypes from './constants';

export const loadRecentNotifications = (
  enterpriseId: EnterpriseId,
  isInitialLoading?: boolean
) =>
  action(ActionTypes.LOAD_RECENT_NOTIFICATIONS_REQUEST, {
    enterpriseId,
    isInitialLoading
  });

export const loadRecentNotificationsSuccess = (response: AxiosResponse) =>
  action(ActionTypes.LOAD_RECENT_NOTIFICATIONS_SUCCESS, { response });

export const loadRecentNotificationsFailure = (
  message: string,
  error?: AxiosError
) =>
  action(
    ActionTypes.LOAD_RECENT_NOTIFICATIONS_FAILURE,
    { message, error },
    undefined,
    true
  );

export const acknowledgeRecentNotification = (notificationId: NotificationId) =>
  action(ActionTypes.SET_PENDING_ACKNOWLEDGEMENT, { notificationId });

export const removePendingAcknowledgement = (notificationId: NotificationId) =>
  action(ActionTypes.REMOVE_PENDING_ACKNOWLEDGEMENT, { notificationId });

export const acknowledgeRecentNotificationSuccess = (response: AxiosResponse) =>
  action(ActionTypes.ACKNOWLEDGE_RECENT_NOTIFICATION_SUCCESS, { response });

export const acknowledgeRecentNotificationFailure = (
  message: string,
  error?: AxiosError
) =>
  action(
    ActionTypes.ACKNOWLEDGE_RECENT_NOTIFICATION_FAILURE,
    { message, error },
    undefined,
    true
  );

export const setRecentNotifications = (
  enterpriseId: EnterpriseId,
  recentNotifications: Record<NotificationId, Notification>
) =>
  action(ActionTypes.SET_RECENT_NOTIFICATIONS, {
    enterpriseId,
    recentNotifications
  });

export const setRecentNotification = (
  notification: Omit<Notification, 'event'>
) => action(ActionTypes.SET_RECENT_NOTIFICATION, notification);
