import AuthActionTypes from 'redux/modules/auth/constants';
import { ApplicationActions } from 'redux/types';

import ActionTypes from './constants';
import { BackOfficeDeviceState } from './types';

export const initialState: BackOfficeDeviceState = null;

function backOfficeDeviceReducer(
  state: BackOfficeDeviceState = initialState,
  action: ApplicationActions
): BackOfficeDeviceState {
  switch (action.type) {
    case ActionTypes.SET_BACK_OFFICE_DEVICE: {
      const {
        payload: { device }
      } = action;

      return device;
    }
    case AuthActionTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export default backOfficeDeviceReducer;
