import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { loadCountries } from 'redux/modules/countries/actions';
import { useCountriesAsArray } from 'redux/modules/countries/hooks';
import { loadStates } from 'redux/modules/states/actions';
import { useStatesAsArray } from 'redux/modules/states/hooks';

function CountriesStatesRequest(): null {
  const dispatch = useDispatch();
  const countries = useCountriesAsArray();
  const states = useStatesAsArray();

  useEffect(() => {
    if (countries.length < 2) {
      dispatch(loadCountries());
    }
    if (!states.length) {
      dispatch(loadStates());
    }
  }, [dispatch, countries, states]);

  return null;
}

export default CountriesStatesRequest;
