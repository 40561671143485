import React, { memo } from 'react';
import { useDispatch } from 'react-redux';

import DefaultButton from 'components/atoms/DefaultButton';
import { appendValuesToQueryString } from 'redux/modules/routerUtils/actions';

function BackOfficeUserCreateButton(): JSX.Element {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(
      appendValuesToQueryString({
        selectedUser: undefined,
        invitationEmail: undefined,
        drawer: 'addUser'
      })
    );
  };

  return (
    <DefaultButton
      type="primary"
      size="small"
      onClick={handleClick}
      mobileChildren="INVITE"
    >
      INVITE USER
    </DefaultButton>
  );
}

export default memo(BackOfficeUserCreateButton);
