import antdMessage from 'antd/lib/message';
import axios, { AxiosError } from 'axios';
import get from 'lodash/get';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { EnterpriseId } from 'models/enterprise';
import HttpStatusCode from 'models/httpStatusCode.enum';
import Invitation from 'models/invitation';
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest
} from 'utils/redux-saga-requests';

import {
  acceptInvitation,
  acceptInvitationFailure,
  acceptInvitationSuccess,
  addInvitation,
  addInvitationFailure,
  addInvitationSuccess,
  deleteInvitation,
  deleteInvitationFailure,
  deleteInvitationSuccess,
  loadCurrentEnterpriseInvitationsFailure,
  loadCurrentEnterpriseInvitationsSuccess,
  loadInvitation,
  loadInvitationFailure,
  loadInvitationSuccess,
  loadMyInvitationsFailure,
  loadMyInvitationsSuccess,
  resendInvitation,
  resendInvitationFailure,
  resendInvitationSuccess,
  updateInvitation,
  updateInvitationSuccess
} from './actions';
import ActionTypes from './constants';
import { setEnterprise } from '../enterprise/actions';
import { selectCurrentEnterpriseId } from '../enterprise/selectors';
import { mergeMyUser } from '../myUser/actions';
import trackEvent from '../tracking/actions';
import { EventType } from '../tracking/types';

const BASE_URL = 'invitation';

function* requestLoadCurrentEnterpriseInvitations() {
  try {
    const enterpriseId: EnterpriseId = yield select(selectCurrentEnterpriseId);

    const { data } = yield call(getRequest, BASE_URL, {
      params: {
        enterpriseId
      }
    });

    yield put(loadCurrentEnterpriseInvitationsSuccess(data));
  } catch (error) {
    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );
    yield put(
      loadCurrentEnterpriseInvitationsFailure(message, error as AxiosError)
    );
  }
}

function* requestLoadMyInvitations() {
  try {
    const { data }: { data: Invitation[] } = yield call(
      getRequest,
      `${BASE_URL}/mine`
    );
    yield put(loadMyInvitationsSuccess());
    yield put(mergeMyUser({ invitations: data }));
  } catch (error) {
    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );
    yield put(loadMyInvitationsFailure(message, error as AxiosError));
  }
}

function* requestLoadInvitation({
  payload: { invitationId, onSuccess }
}: ReturnType<typeof loadInvitation>) {
  try {
    const { data } = yield call(getRequest, `${BASE_URL}/${invitationId}`);
    onSuccess(data);
    yield put(setEnterprise(data.enterprise));
    yield put(loadInvitationSuccess(data));
  } catch (error) {
    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );
    antdMessage.error(
      'Could not load invitation. Please make sure it\'s not expired'
    );
    yield put(loadInvitationFailure(message, error as AxiosError));
  }
}

function* requestAddInvitation({
  payload: { newInvitation, onSuccess }
}: ReturnType<typeof addInvitation>) {
  try {
    const { data } = yield call(postRequest, BASE_URL, { ...newInvitation });
    onSuccess();
    antdMessage.success('Email invitation sent');
    yield put(addInvitationSuccess(data));
    yield put(
      trackEvent({
        type: EventType.USER_INVITED,
        data: {
          inviteeId: data.sid,
          inviteeEmail: data.email,
          inviteeRole: data.role,
          inviteeIsEnterpriseOwner: data.isEnterpriseOwner,
          companyId: data.enterprise.sid,
          company: data.enterprise.name,
          entryPoint: 'Team Members Page'
        }
      })
    );
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );
    if ((error as AxiosError).response?.status === HttpStatusCode.CONFLICT) {
      antdMessage.error('Invitation already exists');
    } else {
      antdMessage.error('Could not send invitation, please try again later');
    }
    yield put(addInvitationFailure(message, error as AxiosError));
  }
}

function* requestAcceptInvitation({
  payload: { invitation, onSuccess }
}: ReturnType<typeof acceptInvitation>) {
  try {
    yield call(putRequest, `${BASE_URL}/${invitation.sid}/accept`, {});
    onSuccess();
    yield put(acceptInvitationSuccess(invitation.sid));
    yield put(
      trackEvent({
        type: EventType.USER_JOINED_ENTERPRISE,
        data: {
          email: invitation.email,
          isEnterpriseOwner: invitation.isEnterpriseOwner,
          companyId: invitation.enterprise.sid,
          company: invitation.enterprise.name
        }
      })
    );
  } catch (error) {
    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );
    antdMessage.error('Could not accept invitation, please try again later');
    yield put(acceptInvitationFailure(message, error as AxiosError));
  }
}

function* requestResendInvitation({
  payload: { invitationId }
}: ReturnType<typeof resendInvitation>) {
  try {
    yield call(putRequest, `${BASE_URL}/${invitationId}/resend`, {});

    antdMessage.success('Invitation resent successfully');
    yield put(resendInvitationSuccess(invitationId));
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );
    if (error?.response?.status === HttpStatusCode.TOO_MANY_REQUESTS) {
      antdMessage.warning(message);
    } else {
      antdMessage.error('Could not resend invitation, please try again later');
    }
    yield put(resendInvitationFailure(message, error));
  }
}

function* requestDeleteInvitation({
  payload: { invitationId, onSuccess }
}: ReturnType<typeof deleteInvitation>) {
  try {
    yield call(deleteRequest, `${BASE_URL}/${invitationId}`);
    onSuccess();
    yield put(deleteInvitationSuccess(invitationId));
  } catch (error) {
    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );
    antdMessage.error('Could not delete invitation, please try again later');
    yield put(deleteInvitationFailure(message, error as AxiosError));
  }
}

function* requestUpdateInvitation({
  payload: { id, updatedInvitation }
}: ReturnType<typeof updateInvitation>) {
  try {
    yield call(putRequest, `${BASE_URL}/${id}`, updatedInvitation);

    antdMessage.success('Invitation updated successfully');
    yield put(updateInvitationSuccess(id, updatedInvitation));
  } catch (error) {
    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );
    antdMessage.error('Could not update invitation, please try again later');
    yield put(deleteInvitationFailure(message, error as AxiosError));
  }
}

function* watchLoadCurrentEnterpriseInvitationsRequest() {
  yield takeLatest(
    ActionTypes.LOAD_CURRENT_ENTERPRISE_INVITATIONS_REQUEST,
    requestLoadCurrentEnterpriseInvitations
  );
}

function* watchLoadMyInvitationsRequest() {
  yield takeLatest(
    ActionTypes.LOAD_MY_INVITATIONS_REQUEST,
    requestLoadMyInvitations
  );
}

function* watchLoadInvitationRequest() {
  yield takeLatest(ActionTypes.LOAD_INVITATION_REQUEST, requestLoadInvitation);
}

function* watchAddInvitationRequest() {
  yield takeLatest(ActionTypes.ADD_INVITATION_REQUEST, requestAddInvitation);
}

function* watchAcceptInvitationRequest() {
  yield takeLatest(
    ActionTypes.ACCEPT_INVITATION_REQUEST,
    requestAcceptInvitation
  );
}

function* watchResendInvitationRequest() {
  yield takeLatest(
    ActionTypes.RESEND_INVITATION_REQUEST,
    requestResendInvitation
  );
}

function* watchDeleteInvitationRequest() {
  yield takeLatest(
    ActionTypes.DELETE_INVITATION_REQUEST,
    requestDeleteInvitation
  );
}

function* watchUpdateInvitationRequest() {
  yield takeLatest(
    ActionTypes.UPDATE_INVITATION_REQUEST,
    requestUpdateInvitation
  );
}

export default function* invitationSaga() {
  yield all([
    fork(watchLoadCurrentEnterpriseInvitationsRequest),
    fork(watchLoadMyInvitationsRequest),
    fork(watchLoadInvitationRequest),
    fork(watchAddInvitationRequest),
    fork(watchAcceptInvitationRequest),
    fork(watchResendInvitationRequest),
    fork(watchDeleteInvitationRequest),
    fork(watchUpdateInvitationRequest)
  ]);
}
