import { createSelector } from 'reselect';

import { ApplicationRootState } from 'redux/types';

export const selectCurrentEnterprise = (state: ApplicationRootState) =>
  state.enterprise;

export const selectCurrentEnterpriseId = createSelector(
  selectCurrentEnterprise,
  (enterprise) => enterprise?.id
);

export const selectCurrentEnterpriseName = createSelector(
  selectCurrentEnterprise,
  (enterprise) => enterprise && enterprise.name
);

export const selectCurrentEnterpriseBillingAddress = createSelector(
  selectCurrentEnterprise,
  (enterprise) => enterprise?.billingAddress
);

export const selectCurrentEnterpriseOwner = createSelector(
  selectCurrentEnterprise,
  (enterprise) => enterprise?.owner
);

export const selectCurrentEnterpriseCountry = createSelector(
  selectCurrentEnterprise,
  (enterprise) => enterprise?.billingAddress?.country || 'AUS'
);
