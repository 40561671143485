import { Typography } from 'antd';
import React from 'react';

import CountryNameValue from 'components/atoms/CountryNameValue';
import Address from 'models/address';

function AddressSummary({
  address: { line1, line2, city, postCode, state, country }
}: {
  address: Address;
}) {
  return (
    <Typography.Paragraph>
      {line1 && (
        <>
          {line1}
          <br />
        </>
      )}
      {line2 && (
        <>
          {line2}
          <br />
        </>
      )}
      {(city || state || postCode) && (
        <>
          {city}
          {state ? ` ${state.abbreviation},` : ''}
          {postCode ? ` ${postCode}` : ''}
          <br />
        </>
      )}
      <CountryNameValue countryAbbreviation={country} />
    </Typography.Paragraph>
  );
}

export default AddressSummary;
