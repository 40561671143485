import WhiteLabelConfig, { WhiteLabelTitle } from 'models/whiteLabel';
import { whiteLabelDomains } from 'utils/construct-enterprise-domain';

const {
  REACT_APP_AUTH0_CLIENT_FARMBOT,
  REACT_APP_AUTH0_CLIENT_RANCHBOT,
  REACT_APP_AUTH0_CLIENT_MINEBOT
} = process.env;

export const farmbotConfig: WhiteLabelConfig = {
  title: WhiteLabelTitle.FARMBOT,
  description: 'Farmbot Monitoring Solutions',
  faviconName: 'farmbotFavicon.ico',
  auth0ClientId: REACT_APP_AUTH0_CLIENT_FARMBOT
};

export const ranchbotConfig: WhiteLabelConfig = {
  title: WhiteLabelTitle.RANCHBOT,
  description: 'Ranchbot Monitoring Solutions',
  faviconName: 'ranchbotFavicon.ico',
  auth0ClientId: REACT_APP_AUTH0_CLIENT_RANCHBOT
};

export const minebotConfig: WhiteLabelConfig = {
  title: WhiteLabelTitle.MINEBOT,
  description: 'Minebot Monitoring Solutions',
  faviconName: 'minebotFavicon.ico',
  auth0ClientId: REACT_APP_AUTH0_CLIENT_MINEBOT
};

const configs: Record<string, WhiteLabelConfig> = {
  farmbot: farmbotConfig,
  'ranch-bot': ranchbotConfig,
  minebot: minebotConfig
};

/**
 * Detects the domain name and returns a configuration object
 * containing properties the title, description, logo and faviconName.
 */
const getWhiteLabelConfig = () => {
  const enterpriseDomain = window.location.hostname;
  const currentDomain = whiteLabelDomains.find((domain) =>
    enterpriseDomain.includes(domain)
  );
  const config = currentDomain && configs[currentDomain];
  return config || farmbotConfig;
};

export default getWhiteLabelConfig;
