import { createSelector } from 'reselect';

import { AssetTypeId } from 'models/assetType';
import { ApplicationRootState } from 'redux/types';

const selectAssetTypes = (state: ApplicationRootState) => state.assetTypes;

const selectAssetTypesAsArray = createSelector(selectAssetTypes, (assetTypes) =>
  Object.values(assetTypes)
);

const makeSelectAssetTypeById = (assetTypeId: AssetTypeId) =>
  createSelector(selectAssetTypes, (assetTypes) => assetTypes[assetTypeId]);

export { selectAssetTypes, selectAssetTypesAsArray, makeSelectAssetTypeById };
