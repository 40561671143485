import AuthActionTypes from 'redux/modules/auth/constants';
import { ApplicationActions } from 'redux/types';

import ActionTypes from './constants';
import { SamplePointsStatisticsState } from './types';

export const initialState: SamplePointsStatisticsState = {
  enterpriseId: null,
  data: {}
};

function samplePointsStatisticsReducer(
  state: SamplePointsStatisticsState = initialState,
  action: ApplicationActions
): SamplePointsStatisticsState {
  switch (action.type) {
    case ActionTypes.SET_SAMPLE_POINTS_STATISTICS: {
      const {
        payload: { enterpriseId, samplePointsStatisticsDictionary }
      } = action;
      return {
        enterpriseId,
        data: samplePointsStatisticsDictionary
      };
    }
    case ActionTypes.UPDATE_SAMPLE_POINTS_STATISTICS: {
      const {
        payload: { samplePointsStatisticsDictionary }
      } = action;
      return {
        ...state,
        data: {
          ...state.data,
          ...samplePointsStatisticsDictionary
        }
      };
    }
    case AuthActionTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export default samplePointsStatisticsReducer;
