import { AxiosError, AxiosResponse } from 'axios';
import { action } from 'typesafe-actions';

import Sample, { ControlPointSample, SampleId } from 'models/sample';
import SamplePoint, { SamplePointId } from 'models/samplePoint';

import ActionTypes from './constants';
import { ConnectedSamplePointIds, SampleInterval } from './types';

export const loadSamplePointSamples = (
  samplePointId: SamplePointId,
  startDateMs: number,
  endDateMs: number,
  withErrorSamples?: boolean
) =>
  action(ActionTypes.LOAD_SAMPLE_POINT_SAMPLES_REQUEST, {
    samplePointId,
    startDateMs,
    endDateMs,
    withErrorSamples
  });

export const loadSamplePointSamplesSuccess = (
  response: AxiosResponse | AxiosResponse[]
) => action(ActionTypes.LOAD_SAMPLE_POINT_SAMPLES_SUCCESS, { response });

export const loadSamplePointSamplesFailure = (
  message: string,
  error?: AxiosError
) =>
  action(
    ActionTypes.LOAD_SAMPLE_POINT_SAMPLES_FAILURE,
    { message, error },
    undefined,
    true
  );

export const loadRainfallSamples = (
  samplePointId: SamplePointId,
  startDate: number,
  endDate: number,
  interval: SampleInterval,
  siteTimezoneCode: string,
  dailyResetTime: number
) =>
  action(ActionTypes.LOAD_RAINFALL_SAMPLES_REQUEST, {
    samplePointId,
    startDate,
    endDate,
    interval,
    siteTimezoneCode,
    dailyResetTime
  });

export const loadRainfallSamplesSuccess = (
  response: AxiosResponse | AxiosResponse[]
) => action(ActionTypes.LOAD_RAINFALL_SAMPLES_SUCCESS, { response });

export const loadRainfallSamplesFailure = (
  message: string,
  error?: AxiosError
) =>
  action(
    ActionTypes.LOAD_RAINFALL_SAMPLES_FAILURE,
    { message, error },
    undefined,
    true
  );

export const loadMachineControlSamples = (
  machineControlId: SamplePointId,
  startDateMs: number,
  endDateMs: number,
  siteTimezoneCode: string,
  connectedSamplePointIds: ConnectedSamplePointIds
) =>
  action(ActionTypes.LOAD_MACHINE_CONTROL_SAMPLES_REQUEST, {
    machineControlId,
    startDateMs,
    endDateMs,
    siteTimezoneCode,
    connectedSamplePointIds
  });

export const loadMachineControlSamplesSuccess = (samples: Sample[]) =>
  action(ActionTypes.LOAD_MACHINE_CONTROL_SAMPLES_SUCCESS, { samples });

export const loadMachineControlSamplesFailure = (
  message: string,
  error?: AxiosError
) =>
  action(
    ActionTypes.LOAD_MACHINE_CONTROL_SAMPLES_FAILURE,
    { message, error },
    undefined,
    true
  );

export const loadInternalSamples = (
  samplePointId: SamplePointId,
  startDateMs: number,
  endDateMs: number,
  siteTimezoneCode: string
) =>
  action(ActionTypes.LOAD_INTERNAL_SAMPLES_REQUEST, {
    samplePointId,
    startDateMs,
    endDateMs,
    siteTimezoneCode
  });

export const loadInternalSamplesSuccess = (response: AxiosResponse) =>
  action(ActionTypes.LOAD_INTERNAL_SAMPLES_SUCCESS, { response });

export const loadInternalSamplesFailure = (
  message: string,
  error?: AxiosError
) =>
  action(
    ActionTypes.LOAD_INTERNAL_SAMPLES_FAILURE,
    { message, error },
    undefined,
    true
  );

export const loadComparativeSamples = ({ dateRanges, samplePointSids }: {
  dateRanges: { start: number, end: number, interval: SampleInterval, timezoneCode: string }[],
  samplePointSids: SamplePoint['sid'][]
}) =>
  action(ActionTypes.LOAD_COMPARATIVE_SAMPLES_REQUEST, { dateRanges, samplePointSids });

export const loadComparativeSamplesSuccess = (
  response: AxiosResponse | AxiosResponse[]
) => action(ActionTypes.LOAD_COMPARATIVE_SAMPLES_SUCCESS, { response });

export const loadComparativeSamplesFailure = (
  message: string,
  error?: AxiosError
) =>
  action(
    ActionTypes.LOAD_COMPARATIVE_SAMPLES_FAILURE,
    { message, error },
    undefined,
    true
  );

export const setSamplePointSamples = (samples: Record<SampleId, Sample>) =>
  action(ActionTypes.SET_SAMPLE_POINT_SAMPLES, samples);

export const setControlPointSamples = (
  samples: Record<SampleId, ControlPointSample>
) => action(ActionTypes.SET_CONTROL_POINT_SAMPLES, samples);
