import { AssetTypeCode } from 'models/assetType';
import SamplePoint, {
  LevelDisplayUnit,
  RawSamplePoint,
  VolumeDisplayUnit
} from 'models/samplePoint';
import getPhysicalPortNumberByLogicalPortNumber from 'utils/get-physical-port-number-by-logical-port-number';
import { rawToParsedAssetTypeCodes } from 'utils/parse-raw-asset-types';

/**
 * Takes a raw samplePoint, and returns a 'parsed' samplePoint supported by the
 * front-end, or null if it is of an unsupported asset type.
 */
const parseRawSamplePoint = (
  samplePoint: RawSamplePoint,
  country?: string
): SamplePoint | null => {
  const assetTypeId = rawToParsedAssetTypeCodes[samplePoint.assetTypeId];
  if (assetTypeId === undefined) {
    return null;
  }
  let parsedSamplePoint = {
    ...samplePoint,
    assetTypeId,
    deviceTags: {
      ...samplePoint.deviceTags,
      physicalPortNo: getPhysicalPortNumberByLogicalPortNumber(
        samplePoint.deviceTags.portNo
      )
    }
  };

  if (assetTypeId === AssetTypeCode.DAM) {
    const isUS = country === 'USA';
    const defaultLevelDisplayUnit = isUS
      ? LevelDisplayUnit.FT
      : LevelDisplayUnit.M;
    const defaultVolumeDisplayUnit = isUS
      ? VolumeDisplayUnit.CU_FT
      : VolumeDisplayUnit.ML;
    parsedSamplePoint = {
      ...parsedSamplePoint,
      config: {
        ...parsedSamplePoint.config,
        levelDisplayUnit:
          parsedSamplePoint.config?.levelDisplayUnit ?? defaultLevelDisplayUnit,
        volumeDisplayUnit:
          parsedSamplePoint.config?.volumeDisplayUnit ??
          defaultVolumeDisplayUnit,
        liquidLevelMeasurementDirection:
          parsedSamplePoint.config?.liquidLevelMeasurementDirection ??
          'bottomUp'
      }
    };
  }

  return parsedSamplePoint;
};

export default parseRawSamplePoint;
