import { createSelector } from 'reselect';

import { SamplePointId } from 'models/samplePoint';
import { ApplicationRootState } from 'redux/types';

const selectSamplePointsStatisticsState = (state: ApplicationRootState) =>
  state.samplePointsStatistics;

const selectSamplePointsStatisticsEnterpriseId = createSelector(
  selectSamplePointsStatisticsState,
  (samplePointsStatisticsState) => samplePointsStatisticsState.enterpriseId
);

const selectSamplePointsStatistics = createSelector(
  selectSamplePointsStatisticsState,
  (samplePointsStatisticsState) =>
    samplePointsStatisticsState.data
);

const selectSamplePointsStatisticsAsArray = createSelector(
  selectSamplePointsStatistics,
  (samplePoints) => Object.values(samplePoints)
);

const makeSelectSamplePointsStatisticById = (samplePointId: SamplePointId) =>
  createSelector(
    selectSamplePointsStatistics,
    (samplePoints) => samplePoints[samplePointId]
  );

export {
  selectSamplePointsStatisticsEnterpriseId,
  selectSamplePointsStatistics,
  selectSamplePointsStatisticsAsArray,
  makeSelectSamplePointsStatisticById
};
