import { AxiosError, AxiosResponse } from 'axios';
import { action } from 'typesafe-actions';

import NotificationPolicy from 'models/notificationPolicy';
import { SiteId } from 'models/site';

import ActionTypes from './constants';

export const loadNotificationPolicy = (siteId: SiteId) =>
  action(ActionTypes.LOAD_NOTIFICATION_POLICY_REQUEST, { siteId });

export const loadNotificationPolicySuccess = (response: AxiosResponse) =>
  action(ActionTypes.LOAD_NOTIFICATION_POLICY_SUCCESS, { response });

export const loadNotificationPolicyFailure = (
  message: string,
  error?: AxiosError
) =>
  action(
    ActionTypes.LOAD_NOTIFICATION_POLICY_FAILURE,
    { message, error },
    undefined,
    true
  );

export const editNotificationPolicy = (
  siteId: SiteId,
  notificationPolicy: NotificationPolicy
) =>
  action(ActionTypes.EDIT_NOTIFICATION_POLICY_REQUEST, {
    siteId,
    notificationPolicy
  });

export const editNotificationPolicySuccess = (response: AxiosResponse) =>
  action(ActionTypes.EDIT_NOTIFICATION_POLICY_SUCCESS, { response });

export const editNotificationPolicyFailure = (
  message: string,
  error?: AxiosError
) =>
  action(
    ActionTypes.EDIT_NOTIFICATION_POLICY_FAILURE,
    { message, error },
    undefined,
    true
  );

export const setNotificationPolicy = (notificationPolicy: NotificationPolicy) =>
  action(ActionTypes.SET_NOTIFICATION_POLICY, notificationPolicy);
