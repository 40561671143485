import { LatLong } from 'models/asset';
import { AssetTypeCode } from 'models/assetType';
import Device from 'models/device';
import SamplePoint from 'models/samplePoint';

function getLatLongData(gpsSamplePoint?: SamplePoint) {
  // TODO: Cleanup, this is bad optional chaining
  const latLngElevation =
    gpsSamplePoint?.lastSampleData?.multiDimValues?.sampleDim?.map(
      ({ rwValue }) => rwValue
    );

  // No longer need to use elevation.
  // Interpret a latitude or longitude value of 0 as an error.
  const latLong =
    latLngElevation && latLngElevation[0] && latLngElevation[1]
      ? ([latLngElevation[0], latLngElevation[1]] as LatLong)
      : undefined;

  return latLong;
}

// TODO: Handle no device properly
export function getDeviceLocation(device?: Device) {
  if (device && (device.positionLat || device.positionLong)) {
    return [device.positionLat, device.positionLong] as LatLong;
  }

  return getLatLongData(device?.gpsSamplePoint);
}

function getLoraDeviceLocation(samplePoint: SamplePoint, device?: Device) {
  const samplePointLoraSN = samplePoint.deviceTags?.serialNumber;
  const loraGPSSamplePoint = device?.loraGpsSamplePoints?.find(
    (sp) => sp.deviceTags?.serialNumber === samplePointLoraSN
  );

  const latLong = getLatLongData(loraGPSSamplePoint);

  // If lora latitude or longitude are 0/undefined, there is an issue with the
  // GPS data, so we should fallback to using the monitor's location.
  return latLong?.every((x) => !!x) ? latLong : undefined;
}

export function getSamplePointLocation(
  samplePoint?: SamplePoint,
  device?: Device
) {
  switch (samplePoint?.assetTypeId) {
    case AssetTypeCode.TROUGH:
      // fallback to device location if lora device location is not available.
      return (
        getLoraDeviceLocation(samplePoint, device) || getDeviceLocation(device)
      );
    default:
      return getDeviceLocation(device);
  }
}
