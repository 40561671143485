import AuthActionTypes from 'redux/modules/auth/constants';
import { ApplicationActions } from 'redux/types';

import ActionTypes from './constants';
import { NotificationPolicyState } from './types';

export const initialState: NotificationPolicyState = {
  siteId: null,
  data: []
};

function notificationPolicyReducer(
  state: NotificationPolicyState = initialState,
  action: ApplicationActions
): NotificationPolicyState {
  switch (action.type) {
    case ActionTypes.SET_NOTIFICATION_POLICY: {
      const {
        payload: { siteId, data }
      } = action;
      return {
        ...state,
        siteId,
        data
      };
    }
    case AuthActionTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export default notificationPolicyReducer;
