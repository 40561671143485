import { action } from 'typesafe-actions';

import {
  CameraDate,
  CameraImageStatus,
  CameraImageType,
  CameraSettings,
  RawCameraImageStatusCode
} from 'models/camera';
import { SamplePointId } from 'models/samplePoint';

import ActionTypes from './constants';
import { CameraScheduleRequest, CameraScheduleResponse, DateKey } from './types';
import { MachineControlActionRequestResponse } from '../controlPoints/types';

export const takePhoto = (
  samplePointId: SamplePointId,
  settings: CameraSettings
) => action(ActionTypes.TAKE_PHOTO_REQUEST, { samplePointId, settings });

export const takePhotoSuccess = (
  samplePointId: SamplePointId,
  { actionRequestId, controlPointId }: MachineControlActionRequestResponse,
  timezoneCode: string
) =>
  action(ActionTypes.TAKE_PHOTO_SUCCESS, {
    samplePointId,
    actionRequestId,
    controlPointId,
    timezoneCode
  });

export const takePhotoFailure = (
  samplePointId: SamplePointId,
  message: string,
  error?: any
) =>
  action(
    ActionTypes.TAKE_PHOTO_FAILURE,
    { samplePointId, message, error },
    undefined,
    true
  );

export const revokePage = (
  samplePointId: SamplePointId,
  dateKey: DateKey,
  page: number
) =>
  action(ActionTypes.REVOKE_CAMERA_IMAGES_PAGE, { samplePointId, dateKey, page });

export const loadCameraImages = (
  samplePointId: SamplePointId,
  params: {
    page: number;
    limit?: number;
    order?: 'ASC' | 'DESC';
    startDateMs?: number;
    endDateMs?: number;
  }
) => action(ActionTypes.LOAD_CAMERA_IMAGES_REQUEST, { samplePointId, params });

export const loadCameraImagesSuccess = (
  samplePointId: SamplePointId,
  images: { images: CameraImageType[]; count: number },
  page: number,
  dateKey: DateKey
) =>
  action(ActionTypes.LOAD_CAMERA_IMAGES_SUCCESS, {
    samplePointId,
    images,
    page,
    dateKey
  });

export const loadCameraImagesFailure = (
  samplePointId: SamplePointId,
  message: string,
  error?: any
) =>
  action(
    ActionTypes.LOAD_CAMERA_IMAGES_FAILURE,
    { samplePointId, message, error },
    undefined,
    true
  );

export const loadCameraStatus = (samplePointId: SamplePointId) =>
  action(ActionTypes.LOAD_CAMERA_STATUS_REQUEST, { samplePointId });

export const loadCameraStatusSuccess = (
  samplePointId: SamplePointId,
  status: CameraImageStatus,
  statusCode: RawCameraImageStatusCode
) =>
  action(ActionTypes.LOAD_CAMERA_STATUS_SUCCESS, {
    samplePointId,
    status,
    statusCode
  });

export const loadCameraStatusFailure = (
  samplePointId: number,
  message: string,
  error?: any
) =>
  action(
    ActionTypes.LOAD_CAMERA_STATUS_FAILURE,
    { samplePointId, message, error },
    undefined,
    true
  );

export const loadCameraImageDates = (samplePointId: SamplePointId) =>
  action(ActionTypes.LOAD_CAMERA_IMAGE_DATES_REQUEST, { samplePointId });

export const loadCameraImageDatesSuccess = (
  samplePointId: SamplePointId,
  dates: CameraDate[],
  timezoneCode: string
) =>
  action(ActionTypes.LOAD_CAMERA_IMAGE_DATES_SUCCESS, { samplePointId, dates, timezoneCode });

export const loadCameraImageDatesFailure = (
  samplePointId: SamplePointId,
  message: string,
  error?: any
) =>
  action(
    ActionTypes.LOAD_CAMERA_IMAGE_DATES_FAILURE,
    { samplePointId, message, error },
    undefined,
    true
  );

export const getCameraSchedules = (samplePointId: SamplePointId) =>
  action(ActionTypes.GET_CAMERA_SCHEDULES_REQUEST, { samplePointId });

export const getCameraSchedulesSuccess = (
  samplePointId: SamplePointId,
  responses: CameraScheduleResponse[]
) =>
  action(ActionTypes.GET_CAMERA_SCHEDULES_SUCCESS, {
    samplePointId,
    responses
  });

export const getCameraSchedulesFailure = (
  samplePointId: SamplePointId,
  message: string,
  error?: any
) =>
  action(
    ActionTypes.GET_CAMERA_SCHEDULES_FAILURE,
    { samplePointId, message, error },
    undefined,
    true
  );

export const addCameraSchedules = (
  samplePointId: SamplePointId,
  requests: CameraScheduleRequest[]
) =>
  action(ActionTypes.ADD_CAMERA_SCHEDULE_REQUEST, { samplePointId, requests });

export const addCameraScheduleSuccess = (
  samplePointId: SamplePointId,
  responses: CameraScheduleResponse[]
) =>
  action(ActionTypes.ADD_CAMERA_SCHEDULE_SUCCESS, { samplePointId, responses });

export const addCameraScheduleFailure = (
  samplePointId: SamplePointId,
  message: string,
  error?: any
) =>
  action(
    ActionTypes.ADD_CAMERA_SCHEDULE_FAILURE,
    { samplePointId, message, error },
    undefined,
    true
  );

export const deleteCameraSchedules = (
  samplePointId: SamplePointId,
  scheduleIds: number[]
) =>
  action(ActionTypes.DELETE_CAMERA_SCHEDULE_REQUEST, {
    samplePointId,
    scheduleIds
  });

export const deleteCameraScheduleSuccess = (
  samplePointId: SamplePointId,
  scheduleIds: number[]
) =>
  action(ActionTypes.DELETE_CAMERA_SCHEDULE_SUCCESS, {
    samplePointId,
    scheduleIds
  });

export const deleteCameraScheduleFailure = (
  samplePointId: SamplePointId,
  message: string,
  error?: any
) =>
  action(
    ActionTypes.DELETE_CAMERA_SCHEDULE_FAILURE,
    { samplePointId, message, error },
    undefined,
    true
  );
