import { Form } from 'antd';
import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BackOfficeDrawer from 'components/drawers/BackOfficeDrawer';
import BackOfficeEnterpriseForm from 'components/forms/BackOfficeEnterpriseForm';
import { EnterpriseStatus, Industry } from 'models/enterprise';
import { addBackOfficeEnterprise } from 'redux/modules/backOfficeEnterprises/actions';
import clearErrors from 'redux/modules/error/actions';
import { makeSelectIsLoading } from 'redux/modules/loading/selectors';
import { appendValuesToQueryString } from 'redux/modules/routerUtils/actions';
import { selectRouterQueryStringDrawer } from 'redux/modules/routerUtils/selectors';

const actions = ['ADD_BACK_OFFICE_ENTERPRISE'];

const selectIsLoading = makeSelectIsLoading(actions);

function BackOfficeEnterpriseCreateDrawer(): JSX.Element {
  const [form] = Form.useForm();
  const drawer = useSelector(selectRouterQueryStringDrawer);
  const isLoading = useSelector(selectIsLoading);

  const visible = drawer === 'addEnterprise';

  const dispatch = useDispatch();

  useEffect(() => {
    if (visible) {
      dispatch(clearErrors(actions));
      form.resetFields();
    }
  }, [visible, form, dispatch]);

  const handleClose = useCallback(() => {
    dispatch(
      appendValuesToQueryString({
        drawer: undefined
      })
    );
  }, [dispatch]);

  const handleClick = () => {
    form
      .validateFields()
      .then((values) => {
        dispatch(addBackOfficeEnterprise(values, handleClose));
      })
      .catch(() => {
        // Ignoring promise rejection
      });
  };

  const handleFinish = useCallback(
    (values) => {
      dispatch(addBackOfficeEnterprise(values, handleClose));
    },
    [dispatch, handleClose]
  );

  return (
    <BackOfficeDrawer
      visible={visible}
      backOfficeSection="Create Enterprise"
      actions={actions}
      handleClick={handleClick}
      handleClose={handleClose}
    >
      <BackOfficeEnterpriseForm
        form={form}
        onFinish={handleFinish}
        initialValues={{
          industry: Industry.AGRICULTURE,
          status: EnterpriseStatus.ACTIVE,
          useBillingAddressForShipping: true
        }}
        isLoading={isLoading}
      />
    </BackOfficeDrawer>
  );
}

export default memo(BackOfficeEnterpriseCreateDrawer);
