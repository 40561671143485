import { Alert } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';

import LoadingSpinner from 'components/composites/LoadingSpinner';
import { EnterpriseId } from 'models/enterprise';
import { useBackOfficeEnterprise } from 'redux/modules/backOfficeEnterprise/hooks';
import { makeSelectFirstErrorMessage } from 'redux/modules/error/selectors';

const actions = ['LOAD_BACK_OFFICE_ENTERPRISE'];

export const selectErrorMessage = makeSelectFirstErrorMessage(actions);

interface Props {
  children: JSX.Element;
  enterpriseId: EnterpriseId | undefined;
}

function BackOfficeEnterpriseLoadingGate({
  children,
  enterpriseId
}: Props): JSX.Element {
  const backOfficeEnterprise = useBackOfficeEnterprise();
  const errorMessage = useSelector(selectErrorMessage);

  if (backOfficeEnterprise && backOfficeEnterprise.id === enterpriseId) {
    return children;
  }

  if (errorMessage) {
    return <Alert message={errorMessage} type="error" />;
  }

  return <LoadingSpinner />;
}

export default BackOfficeEnterpriseLoadingGate;
