import AuthActionTypes from 'redux/modules/auth/constants';
import { ApplicationActions } from 'redux/types';

import ActionTypes from './constants';
import { RouterUtilsState } from './types';

export const initialState: RouterUtilsState = {
  storedSearches: {}
};

function routerUtilsReducer(
  state: RouterUtilsState = initialState,
  action: ApplicationActions
): RouterUtilsState {
  switch (action.type) {
    case ActionTypes.STORE_SEARCH: {
      const {
        payload: { pathname, search }
      } = action;
      return {
        ...state,
        storedSearches: {
          ...state.storedSearches,
          [pathname]: search
        }
      };
    }
    case AuthActionTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export default routerUtilsReducer;
