/**
 * Request all the sample points for the current enterprise.
 */

import { message } from 'antd';
import axios, { AxiosResponse } from 'axios';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import moment from 'moment-timezone';
import { REHYDRATE } from 'redux-persist';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import rollbarLogger from 'config/rollbar';
import { EventSamplePoint } from 'models/event';
import Notification from 'models/notification';
import { MergedSamplePoint } from 'models/samplePoint';
import NetworkActionTypes from 'redux/modules/network/constants';
import { selectNetworkOnline } from 'redux/modules/network/selectors';
import { getRequest, patchRequest } from 'utils/redux-saga-requests';

import {
  acknowledgeRecentNotificationFailure,
  acknowledgeRecentNotificationSuccess,
  loadRecentNotifications,
  loadRecentNotificationsFailure,
  loadRecentNotificationsSuccess,
  removePendingAcknowledgement,
  setRecentNotification,
  setRecentNotifications
} from './actions';
import ActionTypes from './constants';
import {
  selectRecentNotifications,
  selectRecentNotificationsPendingStatusChanges
} from './selectors';
import {
  selectSamplePointsAsArray,
  selectSamplePointsState
} from '../samplePoints/selectors';
import { SamplePointsState } from '../samplePoints/types';
import { selectSitesState } from '../sites/selectors';
import { SitesState } from '../sites/types';
import trackEvent from '../tracking/actions';
import { EventType } from '../tracking/types';

export function* requestRecentNotifications(
  action: ReturnType<typeof loadRecentNotifications>
) {
  const {
    payload: { enterpriseId, isInitialLoading }
  } = action;

  try {
    const response: AxiosResponse = yield call(getRequest, 'notification', {
      params: {
        filter: [
          `event.trigger.samplePoint.site.enterpriseId::eq::${enterpriseId}`,
          'event.trigger.samplePoint.statusActive::eq::1',
          'event.trigger.samplePoint.siteId::notnull',
          'notificationType::eq::1',
          `event.date::gt::${moment()
            .subtract(5, 'days')
            .startOf('day')
            .format()}`
        ],
        sort: 'event.date,DESC'
      }
    });
    const { data } = response;
    const notifications: Notification[] = data;

    // Sanity check
    if (isInitialLoading) {
      const samplePoints: MergedSamplePoint[] = yield select(
        selectSamplePointsAsArray
      );
      const sites: SitesState = yield select(selectSitesState);
      const buggyNotifications: (Notification & { error: string })[] = [];
      for (const notification of notifications) {
        const { event } = notification;
        if (event) {
          const samplePoint: EventSamplePoint | undefined = samplePoints.find(
            (sp) =>
              sp.sid === event.samplePoint.sid ||
              sp._hidden?.sid === event.samplePoint.sid
          );
          if (!samplePoint) {
            buggyNotifications.push({
              ...notification,
              error:
                'Enterprise doesn\'t have the sample points showed in notifications'
            });
          }
        }
      }
      const siteIdToIgnore = 62; // A site of QAEnterprise on Prod
      if (
        buggyNotifications.length &&
        !sites[siteIdToIgnore] &&
        process.env.REACT_APP_ENV === 'production'
      ) {
        rollbarLogger.error('Notification data error', buggyNotifications);
      }
    }

    yield all([
      put(setRecentNotifications(enterpriseId, keyBy(notifications, 'id'))),
      put(loadRecentNotificationsSuccess(response))
    ]);
  } catch (error) {
    const errorMessage = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );

    yield put(loadRecentNotificationsFailure(errorMessage));
  }
}

export function* watchLoadRecentNotificationsRequest() {
  yield takeLatest(
    ActionTypes.LOAD_RECENT_NOTIFICATIONS_REQUEST,
    requestRecentNotifications
  );
}

export function* acknowledgeRecentNotifications() {
  const networkOnline: ReturnType<typeof selectNetworkOnline> =
    yield select(selectNetworkOnline);

  const notifications: Record<string, Notification> = yield select(
    selectRecentNotifications
  );
  const samplePointState: SamplePointsState = yield select(
    selectSamplePointsState
  );

  const pendingStatusChanges: ReturnType<
    typeof selectRecentNotificationsPendingStatusChanges
  > = yield select(selectRecentNotificationsPendingStatusChanges);

  const notificationIds = Object.keys(pendingStatusChanges);

  if (networkOnline) {
    for (let i = 0; i < notificationIds.length; i += 1) {
      const notificationId = Number(notificationIds[i]);
      const notificationStatusChange = pendingStatusChanges[notificationId];
      const { eventTriggerSamplePointId, eventLevel, eventMessage } =
        notifications[notificationId];
      const samplePointId = eventTriggerSamplePointId as number;
      const { assetTypeId } = samplePointState[samplePointId] ?? {};

      try {
        const response: AxiosResponse = yield call(
          patchRequest,
          `notification/${notificationId}`,
          {
            notificationStatus: notificationStatusChange
          }
        );
        const { data } = response;

        yield all([
          put(removePendingAcknowledgement(notificationId)),
          put(setRecentNotification(data)),
          put(
            trackEvent({
              type: EventType.NOTIFICATION_ACKNOWLEDGED,
              data: {
                status: true,
                samplePointId,
                assetTypeId,
                eventLevel,
                eventMessage
              }
            })
          ),
          put(acknowledgeRecentNotificationSuccess(response))
        ]);
      } catch (error) {
        if (!axios.isAxiosError(error)) throw error;
        /**
         * Suppress error handling in the event of a Network Error.
         */
        if (error.message !== 'Network Error') {
          message.error(
            'Failed to acknowledge the notification. Please try again.'
          );

          const errorMessage = get(
            error,
            'response.data.message',
            'Sorry, something went wrong.'
          );
          yield all([
            put(removePendingAcknowledgement(notificationId)),
            put(
              trackEvent({
                type: EventType.NOTIFICATION_ACKNOWLEDGED,
                data: {
                  status: false,
                  samplePointId,
                  assetTypeId,
                  eventLevel,
                  eventMessage
                }
              })
            ),
            acknowledgeRecentNotificationFailure(errorMessage, error)
          ]);
        }
      }
    }
  }
}

export function* watchSetPendingAcknowledgement() {
  yield takeLatest(
    [
      REHYDRATE,
      NetworkActionTypes.SET_NETWORK_ONLINE,
      ActionTypes.SET_PENDING_ACKNOWLEDGEMENT
    ],
    acknowledgeRecentNotifications
  );
}

export default function* recentNotificationsSaga() {
  yield all([
    fork(watchLoadRecentNotificationsRequest),
    fork(watchSetPendingAcknowledgement)
  ]);
}
