import AuthActionTypes from 'redux/modules/auth/constants';
import { ApplicationActions } from 'redux/types';

import ActionTypes from './constants';
import { SitesState } from './types';

export const initialState: SitesState = {};

function sitesReducer(
  state: SitesState = initialState,
  action: ApplicationActions
): SitesState {
  switch (action.type) {
    case ActionTypes.SET_SITES:
      return action.payload;
    case ActionTypes.SET_SITE: {
      const { payload } = action;
      const { id } = payload;
      return {
        ...state,
        [id]: payload
      };
    }
    case ActionTypes.UNSET_SITE: {
      const newState = { ...state };
      delete newState[action.payload];
      return newState;
    }
    case AuthActionTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export default sitesReducer;
