import { createSelector } from 'reselect';

import { DeviceId } from 'models/device';
import { ApplicationRootState } from 'redux/types';

const selectDevices = (state: ApplicationRootState) => state.devices;

const selectDevicesAsArray = createSelector(selectDevices, (devices) =>
  Object.values(devices)
);

const makeSelectDevice = (deviceId: DeviceId) =>
  createSelector(selectDevices, (devices) => devices[deviceId]);

// TODO: kill optional param
const makeSelectDevicesBySiteId = (siteId?: number) =>
  createSelector(selectDevicesAsArray, (devices) => {
    if (!siteId) {
      return [];
    }
    return devices.filter((device) => device.siteId === siteId);
  });

export {
  selectDevices,
  selectDevicesAsArray,
  makeSelectDevice,
  makeSelectDevicesBySiteId
};
