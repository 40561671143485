import { Col, Input, Select } from 'antd';
import get from 'lodash/get';
import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';

import AddressSummary from 'components/composites/AddressSummary';
import DefaultFormItem from 'components/composites/DefaultFormItem';
import LoadingSpinner from 'components/composites/LoadingSpinner';
import AddressFormItemSelectState from 'components/forms/AddressFormItemSelectState';
import FieldsValue from 'components/forms/FieldsValue';
import FormItemCol from 'components/forms/FormItemCol';
import CountriesStatesRequest from 'components/requests/CountriesStatesRequest';
import ActionTypes from 'redux/modules/countries/constants';
import { useCountriesAsArray } from 'redux/modules/countries/hooks';
import { makeSelectIsLoading } from 'redux/modules/loading/selectors';

import { DEFAULT_COUNTRY_ABBR_FARMBOT } from '../../../constants';

interface Props {
  topLevelName: string;
  /** Use responsive column width */
  isFullSpanOnMobileHalfOnDesktop?: boolean;
  readOnly?: boolean;
  hasInitialValues?: boolean;
  showCountry?: boolean;
  countryAbbreviation?: string;
}

const selectIsLoading = makeSelectIsLoading([
  ActionTypes.LOAD_COUNTRIES_REQUEST
]);

/**
 * A reusable set of form items to be used within an antd Form element.
 * It assumes that the address being edited conforms to the standard address
 * model.
 */
function AddressFormItems({
  topLevelName,
  isFullSpanOnMobileHalfOnDesktop,
  readOnly,
  hasInitialValues,
  showCountry = true,
  countryAbbreviation = DEFAULT_COUNTRY_ABBR_FARMBOT
}: Props) {
  const isLoadingCountries = useSelector(selectIsLoading);

  const countries = useCountriesAsArray();
  const countryOptions = useMemo(
    () =>
      countries.map(({ abbreviation, name }) => ({
        label: name,
        value: abbreviation
      })),
    [countries]
  );

  if (readOnly) {
    return (
      <Col span={24}>
        <FieldsValue
          names={[
            [topLevelName, 'line1'],
            [topLevelName, 'line2'],
            [topLevelName, 'city'],
            [topLevelName, 'country'],
            [topLevelName, 'state'],
            [topLevelName, 'postCode']
          ]}
        >
          {(fieldsValue) => {
            const line1 = get(fieldsValue, [topLevelName, 'line1']);
            const line2 = get(fieldsValue, [topLevelName, 'line2']);
            const city = get(fieldsValue, [topLevelName, 'city']);
            const country = showCountry
              ? get(fieldsValue, [topLevelName, 'country'])
              : null;
            const state = get(fieldsValue, [topLevelName, 'state']);
            const postCode = get(fieldsValue, [topLevelName, 'postCode']);

            return (
              <AddressSummary
                address={{
                  id: undefined,
                  line1,
                  line2,
                  city,
                  country,
                  state,
                  postCode
                }}
              />
            );
          }}
        </FieldsValue>
      </Col>
    );
  }

  return (
    <>
      <CountriesStatesRequest />
      <FormItemCol
        isFullSpanOnMobileHalfOnDesktop={isFullSpanOnMobileHalfOnDesktop}
      >
        <DefaultFormItem
          name={[topLevelName, 'line1']}
          label="Line 1"
          rules={[
            {
              required: true,
              message: 'Please enter a line 1'
            }
          ]}
        >
          <Input />
        </DefaultFormItem>
      </FormItemCol>
      <FormItemCol
        isFullSpanOnMobileHalfOnDesktop={isFullSpanOnMobileHalfOnDesktop}
      >
        <DefaultFormItem name={[topLevelName, 'line2']} label="Line 2">
          <Input />
        </DefaultFormItem>
      </FormItemCol>
      <FormItemCol
        isFullSpanOnMobileHalfOnDesktop={isFullSpanOnMobileHalfOnDesktop}
      >
        <DefaultFormItem
          name={[topLevelName, 'city']}
          label="City/Suburb/Town"
          rules={[
            {
              required: true,
              message: 'Please enter a City/Suburb/Town'
            }
          ]}
        >
          <Input />
        </DefaultFormItem>
      </FormItemCol>
      {showCountry && (
        <FormItemCol
          isFullSpanOnMobileHalfOnDesktop={isFullSpanOnMobileHalfOnDesktop}
        >
          <DefaultFormItem
            name={[topLevelName, 'country']}
            label="Country"
            initialValue={hasInitialValues ? undefined : countryAbbreviation}
            rules={[
              {
                required: true,
                message: 'Please enter a country'
              }
            ]}
          >
            {isLoadingCountries ? (
              <LoadingSpinner />
            ) : (
              <Select
                showSearch
                optionFilterProp="label"
                options={countryOptions}
              />
            )}
          </DefaultFormItem>
        </FormItemCol>
      )}

      <FormItemCol
        isFullSpanOnMobileHalfOnDesktop={isFullSpanOnMobileHalfOnDesktop}
      >
        <AddressFormItemSelectState
          fieldName={[topLevelName, 'stateId']}
          countryAbbreviation={countryAbbreviation}
        />
      </FormItemCol>
      <FormItemCol
        isFullSpanOnMobileHalfOnDesktop={isFullSpanOnMobileHalfOnDesktop}
      >
        <DefaultFormItem
          name={[topLevelName, 'postCode']}
          label="Post/Zip code"
          rules={[
            {
              required: true,
              message: 'Please enter a Post/Zip code'
            }
          ]}
        >
          <Input />
        </DefaultFormItem>
      </FormItemCol>
    </>
  );
}

export default memo(AddressFormItems);
