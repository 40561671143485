import rollbarLogger from 'config/rollbar';
import { AssetTypeCode } from 'models/assetType';
import SamplePoint from 'models/samplePoint';

/**
 * Get sample point id(s) from a merged sample point
 * @summary Most of the sample point only has one id, while like soil sample
 * point has two ids.
 * @param samplePoint - Sample point
 * @returns sample point id array
 */
export default function getOriginalSamplePointIdsFromMergedSamplePoint(
  samplePoint: SamplePoint
) {
  switch (samplePoint.assetTypeId) {
    case AssetTypeCode.SOIL:
      return [samplePoint.id, (samplePoint as any)?._hidden.id as number];
    case AssetTypeCode.SAFETY_CHECK_IN:
      if ((samplePoint as any)?._hidden) {
        return [samplePoint.id, (samplePoint as any)?._hidden.id as number];
      }
      rollbarLogger.warning(
        `Paired SOS sensor not found. Check-in sensor id: ${samplePoint.id}`,
        samplePoint
      );
      return [samplePoint.id];
    default:
      return [samplePoint.id];
  }
}
