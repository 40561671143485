import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import Helmet from 'react-helmet';
import { useEffectOnce } from 'react-use';

import GoogleAnalyticsSetUserId from 'components/atoms/GoogleAnalyticsSetUserId';
import NetworkToRedux from 'components/atoms/NetworkToRedux';
import Auth0LoadingGate from 'components/loadingGates/Auth0LoadingGate';
import Auth0TokenHandler from 'components/miscellaneous/Auth0TokenHandler';
import { WhiteLabelTitle } from 'models/whiteLabel';
import Routes from 'routes';
import getWhiteLabelConfig from 'utils/get-white-label-config';

const { REACT_APP_AUTH0_DOMAIN, REACT_APP_AUTH0_AUDIENCE, PUBLIC_URL } =
  process.env;

const { title, description, faviconName, auth0ClientId } =
  getWhiteLabelConfig();

function App(): JSX.Element {
  // App initialisation logic.
  useEffectOnce(() => {
    // If this is a Minebot instance, add 'Minebot' class to the root element.
    // This will cause the correct styles to be used.
    if (title === WhiteLabelTitle.MINEBOT) {
      const rootNode = document.getElementById('farmbot');
      if (rootNode) {
        rootNode.className += `${rootNode.className ? ' ' : ''}Minebot`;
      }
    }
  });

  return (
    <Auth0Provider
      domain={REACT_APP_AUTH0_DOMAIN}
      clientId={auth0ClientId}
      redirectUri={window.location.origin}
      useRefreshTokens
      cacheLocation="localstorage"
      audience={REACT_APP_AUTH0_AUDIENCE}
    >
      <Helmet>
        <title>{`${title}`}</title>
        <meta name={`${title}`} content={`${description}`} />
        <link
          rel="icon"
          type="image/png"
          href={`${PUBLIC_URL}/${faviconName}`}
          sizes="16x16"
        />
      </Helmet>
      <NetworkToRedux />
      <Auth0LoadingGate>
        <Auth0TokenHandler>
          <Routes />
        </Auth0TokenHandler>
      </Auth0LoadingGate>
      <GoogleAnalyticsSetUserId />
    </Auth0Provider>
  );
}

export default App;
