import { Typography, message as antdMessage } from 'antd';
import React, { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useSelector } from 'react-redux';

import ErrorBoundaryFallback from 'components/atoms/ErrorBoundaryFallback';
import HelmetWrapper from 'components/atoms/HelmetWrapper';
import PageHeaderPusher from 'components/atoms/PageHeaderPusher';
import DefaultPageHeader from 'components/composites/DefaultPageHeader';
import LoadingSpinner from 'components/composites/LoadingSpinner';
import FarmbotAgriWebbIntegrationTitle from 'components/features/integrations/FarmbotAgriWebbIntegrationTitle';
import AgriWebbSiteFarmMappingForm from 'components/forms/AgriWebbSiteFarmMappingForm';
import AgriWebbFarmsRequest from 'components/requests/AgriWebbFarmsRequest';
import AgriWebbActionType from 'redux/modules/agriWebb/constants';
import { AgriWebbFarm } from 'redux/modules/agriWebb/types';
import { makeSelectFirstErrorMessage } from 'redux/modules/error/selectors';
import { makeSelectIsLoading } from 'redux/modules/loading/selectors';
import getAgriWebbMarketplaceCardUrl from 'utils/agriwebb/get-agriwebb-marketplace-card-url';

import './styles.less';

const actions = [AgriWebbActionType.LOAD_FARMS_REQUEST];
const selectIsLoading = makeSelectIsLoading(actions);
const selectError = makeSelectFirstErrorMessage(actions);

export default function SiteFarmMappingAgriWebb() {
  const searchParams = new URLSearchParams(document.location.search);
  const enterpriseId = searchParams.get('enterpriseId') as string;
  const [agriWebbFarms, setAgriWebbFarms] = useState<AgriWebbFarm[] | null>(
    null
  );
  const isLoading = useSelector(selectIsLoading);
  const error = useSelector(selectError);

  const handleSuccess = () => {
    antdMessage.success(
      'Your enterprise is integrated. Will redirect back to AgriWebb in 3s',
      3,
      () => {
        window.location.href = getAgriWebbMarketplaceCardUrl();
      }
    );
  };
  const handleError = () => { };

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <HelmetWrapper section="Connections" />
      <DefaultPageHeader title="Connections" />
      <AgriWebbFarmsRequest
        enterpriseId={+enterpriseId}
        onSuccess={setAgriWebbFarms}
      />
      {isLoading && <LoadingSpinner />}
      <PageHeaderPusher>
        <>
          {!!agriWebbFarms?.length && (
            <div className="SiteFarmMappingAgriWebb">
              <FarmbotAgriWebbIntegrationTitle>
                Map each your site(s) to AgriWebb Farm(s):
              </FarmbotAgriWebbIntegrationTitle>
              <AgriWebbSiteFarmMappingForm
                enterpriseId={+enterpriseId}
                agriWebbFarms={agriWebbFarms}
                onSuccess={handleSuccess}
                onError={handleError}
              />
            </div>
          )}
          {!agriWebbFarms?.length && !isLoading && (
            <Typography.Title level={5}>
              Sorry, no farms found.
            </Typography.Title>
          )}
          {error && !isLoading && (
            <Typography.Title level={5}>
              Sorry, something went wrong.
            </Typography.Title>
          )}
        </>
      </PageHeaderPusher>
    </ErrorBoundary>
  );
}
