import omit from 'lodash/omit';

import Trigger from 'models/trigger';
import AuthActionTypes from 'redux/modules/auth/constants';
import { ApplicationActions } from 'redux/types';

import ActionTypes from './constants';
import { TriggersState } from './types';

export const initialState: TriggersState = {
  data: {},
  pendingIsEnabledChanges: {}
};

function triggersReducer(
  state: TriggersState = initialState,
  action: ApplicationActions
): TriggersState {
  switch (action.type) {
    case ActionTypes.SET_TRIGGERS:
      return {
        ...state,
        data: action.payload
      };
    case ActionTypes.SET_TRIGGER: {
      const { payload } = action;
      const { trigger, specialKey } = payload;
      const { id } = payload.trigger;
      return {
        ...state,
        pendingIsEnabledChanges: omit(
          state.pendingIsEnabledChanges,
          specialKey
        ),
        data: {
          ...state.data,
          [id]: trigger
        }
      };
    }
    case ActionTypes.REMOVE_TRIGGER: {
      const { payload } = action;
      const { triggerId, specialKey } = payload;

      return {
        ...state,
        pendingIsEnabledChanges: omit(
          state.pendingIsEnabledChanges,
          specialKey
        ),
        data: omit(state.data, triggerId)
      };
    }
    case ActionTypes.REMOVE_TRIGGERS: {
      const { payload } = action;
      const { triggerIds, specialKeys } = payload;

      return {
        ...state,
        pendingIsEnabledChanges: omit(
          state.pendingIsEnabledChanges,
          specialKeys
        ),
        data: omit(state.data, triggerIds) as Record<string, Trigger>
      };
    }
    case ActionTypes.SET_PENDING_IS_ENABLE_CHANGE: {
      const {
        payload: { specialKey, values }
      } = action;

      const pendingIsEnabledChanges = {
        ...state.pendingIsEnabledChanges,
        [specialKey]: values
      };

      return {
        ...state,
        pendingIsEnabledChanges
      };
    }
    case ActionTypes.SET_PENDING_IS_ENABLE_CHANGE_BULK: {
      const {
        payload: { specialKeys, values }
      } = action;
      return {
        ...state,
        pendingIsEnabledChanges: {
          ...state.pendingIsEnabledChanges,
          ...specialKeys.reduce(
            (acc, specialKey, index) => ({
              ...acc,
              [specialKey]: values[index]
            }),
            {}
          )
        }
      };
    }
    case ActionTypes.REMOVE_PENDING_IS_ENABLE_CHANGE: {
      const {
        payload: { specialKey }
      } = action;
      return {
        ...state,
        pendingIsEnabledChanges: omit(
          state.pendingIsEnabledChanges,
          specialKey
        )
      };
    }
    case AuthActionTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export default triggersReducer;
