import { AssetTypeCode } from 'models/assetType';
import Sample from 'models/sample';
import SamplePoint from 'models/samplePoint';
import convertMetricToImperial from 'utils/convert-metric-to-imperial';
import { UnitType } from 'utils/get-unit-by-country';

export function getUnitTypeByAssetTypeId(assetTypeId: number): UnitType {
  switch (assetTypeId) {
    case AssetTypeCode.PIPE:
      return UnitType.VOLUME_SM;
    case AssetTypeCode.RAIN_GAUGE:
      return UnitType.RAIN;
    case AssetTypeCode.LINE_PRESSURE:
      return UnitType.PRESSURE;
    case AssetTypeCode.BATTERY:
      return UnitType.TREND_SM;
    case AssetTypeCode.BORE:
      return UnitType.LEVEL_MD;
    case AssetTypeCode.WATER_TANK:
    case AssetTypeCode.FUEL_TANK:
    case AssetTypeCode.LIQUID_FERTILISER_TANK:
    case AssetTypeCode.DAM:
    case AssetTypeCode.TROUGH:
    default:
      return UnitType.LEVEL_SM;
  }
}

interface IParseSamples {
  samples: Sample[];
  samplePoint: SamplePoint;
  country: string;
}

export function parseSamples({
  samples, samplePoint, country
}: IParseSamples): Sample[] {
  return samples.map((sample: Sample) => {
    if (samplePoint.assetTypeId === AssetTypeCode.BORE) {
      return {
        ...sample,
        samplePointId: samplePoint.id,
        rwValue: sample.rwValue
      };
    }
    // TODO: Need to gradually move unit conversion closer to UI layer, because
    // some calculation later needs metric values. Bore is the first step.
    // @uom-refactor
    const rwValue = convertMetricToImperial(
      getUnitTypeByAssetTypeId(samplePoint.assetTypeId),
      country,
      sample.rwValue
    ) || 0;

    return {
      ...sample,
      samplePointId: samplePoint.id,
      rwValue
    };
  });
}
