import Event, { EventId, Level } from './event';
import { SamplePointId } from './samplePoint';
import { UserId } from './user';

export type NotificationId = number;

export enum NotificationStatus {
  RAISED = 1,
  ACKNOWLEDGED = 2,
  SENT = 3,
  DELIVERY_FAILED = 400
}

/** TODO: Which of these fields do we ACTUALLY use? */
export default interface Notification {
  id: NotificationId;
  notificationStatus: NotificationStatus;
  notificationType: number;
  message: string;
  deliveryAddress: string | null;
  eventId: EventId;
  userId: UserId;
  event?: Event;

  // Fields computed on the front end
  eventDate?: number;
  eventLevel?: Level;
  eventMessage?: string;
  /** The up-to-date sample point id by looking up in enterprise.samplePoints */
  eventTriggerSamplePointId?: SamplePointId;
  eventTriggerSamplePointName?: string | undefined;
  eventTriggerSamplePointSiteId?: number | undefined;
  eventTriggerSamplePointSiteTimezoneCode?: string | undefined;
  eventFormattedDate?: string | undefined;
  eventTriggerValue?: number | undefined;
  eventTriggerId?: number | undefined;
}
