import { RawAssetTypeCode } from 'models/assetType';
import SamplePoint, { RawSamplePoint } from 'models/samplePoint';
import { safetyCheckInSamplePointReducer } from 'utils/associated-sample-points/merge-safety-check-in-sos';
import { soilSamplePointReducer } from 'utils/associated-sample-points/merge-soil-moisture-temperature';
import parseRawSamplePoint from 'utils/associated-sample-points/parse-raw-samplepoint';

import { machineControlSamplePointReducer } from '../merge-machine-controls';

/**
 * Takes an array of raw samplePoints and returns an array of parsed
 * samplePoints. SamplePoints of unsupported asset types are removed.
 */
const parseRawSamplePoints = (
  rawSamplePoints: RawSamplePoint[],
  country: string
) =>
  rawSamplePoints
    .reduce<Array<SamplePoint | null>>((acc, cur) => {
      switch (cur.assetTypeId) {
        case RawAssetTypeCode.SOIL_MOISTURE:
        case RawAssetTypeCode.SOIL_TEMP:
          return soilSamplePointReducer(acc, cur);
        case RawAssetTypeCode.SAFETY_CHECK_IN_SOS:
        case RawAssetTypeCode.SAFETY_CHECK_IN_VISIT:
          return safetyCheckInSamplePointReducer(acc, cur);
        case RawAssetTypeCode.MACHINE_CONTROL:
          return machineControlSamplePointReducer(acc, cur);
        default:
          return [...acc, parseRawSamplePoint(cur, country)];
      }
    }, [])
    .filter<SamplePoint>((x): x is SamplePoint => !!x);

export default parseRawSamplePoints;
