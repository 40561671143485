import React, { memo } from 'react';

import { EnterpriseStatus } from 'models/enterprise';

interface Props {
  enterpriseStatus?: EnterpriseStatus | undefined | null;
}

function EnterpriseStatusText({ enterpriseStatus }: Props): JSX.Element | null {
  switch (enterpriseStatus) {
    case EnterpriseStatus.ACTIVE:
      return <>Active</>;
    case EnterpriseStatus.DISABLED:
      return <>Disabled</>;
    case EnterpriseStatus.PAST_DUE:
      return <>Past due</>;
    case EnterpriseStatus.TRIAL:
      return <>Trial</>;
    case EnterpriseStatus.TRIAL_EXPIRED:
      return <>Trial expired</>;
    case EnterpriseStatus.DEMO:
      return <>Demo</>;
    case EnterpriseStatus.TEST:
      return <>Test</>;
    case EnterpriseStatus.DUPLICATE:
      return <>Duplicate</>;
    default:
      return null;
  }
}

export default memo(EnterpriseStatusText);
